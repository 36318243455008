import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';

export const driversByClientSlice = createSlice({
    name: 'driversByClient',
    initialState: {},
    reducers: {
        setDriversByClient: (state, action) => {
            return action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return {};
        });
    }
});

export const { setDriversByClient } = driversByClientSlice.actions;

export const selectDriversByClient = (state) => state.driversByClient;

export default driversByClientSlice.reducer;
