import React from 'react';
import Icon from '~/ui/components/Icon';
import classNames from 'classnames';

import './detail.scss';

function Detail({
    iconName,
    iconColor,
    iconSize,
    mainContent,
    secondaryContent,
    iconClassName,
    className
}) {
    function _getClassName() {
        const defaultClassName = 'detail';
        return classNames(defaultClassName, className);
    }
    return (
        <div className={_getClassName()}>
            <Icon
                icon={iconName}
                color={iconColor}
                size={iconSize}
                className={iconClassName}
            />
            <div className="detail-content">
                <div className="detail-main">{mainContent}</div>
                <div className="detail-secondary">{secondaryContent}</div>
            </div>
        </div>
    );
}

export default Detail;
