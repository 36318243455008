import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    selectShowDriverPopup,
    selectShowDriverLabel,
    setShowDriverPopup,
    setShowDriverLabel
} from '~/reducers/mapSettingsSlice';

import { MapSettingMenuButton } from '~/ui';

import './map-settings-menu-sections.scss';

export default function MapSettingMenuDriversSection({
    mapRouteMode,
    openSubMenu
}) {
    const { t } = useTranslation(['translation']);
    const showDriverPopupState = useSelector(
        selectShowDriverPopup(mapRouteMode)
    );
    const showDriverLabelState = useSelector(
        selectShowDriverLabel(mapRouteMode)
    );
    const driverMarkerClick = useCallback(() => {
        openSubMenu(t('driverMarker'), [
            {
                iconName: 'stopDetails',
                text: t('driverPopup'),
                checkedState: showDriverPopupState,
                onChangeHandler: setShowDriverPopup
            },
            {
                iconName: 'stopLabel',
                text: t('driverLabel'),
                checkedState: showDriverLabelState,
                onChangeHandler: setShowDriverLabel
            }
        ]);
    }, [showDriverPopupState, showDriverLabelState]);

    return (
        <>
            <span className="mapsettingssection_title">{t('driver')}</span>
            <MapSettingMenuButton
                iconName="routeMarker"
                text={t('driverMarker')}
                onClickHandler={driverMarkerClick}
            />
        </>
    );
}
