import PT from 'prop-types';
import React from 'react';
import { Button as ThemeButton } from 'theme-ui';

import { theme, styles } from '~/ui';

const nakedBtnStyle = {
    backgroundColor: 'transparent',
    outline: 'none',
    border: 'none',
    fontWeight: 'normal',
    fontSize: '1.4rem',
    padding: '0',

    '&:hover': {
        backgroundColor: 'transparent'
    },

    '&[disabled]': {
        cursor: 'default',
        color: 'var(--color-galaxy-400)',
        backgroundColor: 'transparent',
        borderColor: 'transparent'
    }
};

const typeVariants = {
    standard: {
        px: '3.2rem',
        py: '1.2rem'
    },
    compact: {
        fontSize: '1.4rem',
        px: '1.6rem',
        py: '0.7rem'
    },
    naked: {
        ...nakedBtnStyle,

        '&:hover': {
            background: 'var(--color-neptune-100)'
        },
        '&:active': {
            background: 'var(--color-neptune-200)'
        }
    },
    link: {
        ...nakedBtnStyle,
        color: '#3282FF',
        fill: '#3282FF',

        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    light: {
        outline: 'none',
        border: 'none',
        backgroundColor: 'var(--color-neptune-100)',

        '&:hover, &:active': {
            backgroundColor: 'var(--color-neptune-100)'
        }
    }
};

const Button = React.forwardRef(function Button(
    {
        children,
        variant = 'primary',
        type = 'standard',
        sx,
        className,
        ...extra
    },
    ref
) {
    const typeStyle = typeVariants[type];
    return (
        <ThemeButton
            ref={ref}
            className={className}
            sx={{
                ...styles.interactions.clickable,
                borderRadius: '0.8rem',
                borderStyle: 'solid',
                borderWidth: '0.1rem',
                fontWeight: 'medium',
                fontSize: '1.6rem',
                '&:focus': {
                    outline: 'none'
                },
                ...typeStyle,
                ...sx
            }}
            variant={variant}
            {...extra}
        >
            {children}
        </ThemeButton>
    );
});

Button.propTypes = {
    sx: PT.oneOfType([PT.object]),
    variant: PT.oneOf(Object.keys(theme.buttons)),
    type: PT.oneOf(Object.keys(typeVariants))
};

export default Button;
