import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';

export const clientsSlice = createSlice({
    name: 'clients',
    initialState: {},
    reducers: {
        setClients: (state, action) => {
            state = action.payload;
            return state;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return {};
        });
    }
});

export const { setClients } = clientsSlice.actions;

export const selectClients = (state) => state.clients;

export default clientsSlice.reducer;
