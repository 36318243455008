import { store } from '~/store';

import LiveDriver from '~/data-classes/dispatched/LiveDriver';

import {
    setMapMarkerMode,
    setShouldFitPlanMapToBounds
} from '~/reducers/mapSettingsSlice';
import { resetPlanStopsLevelData } from '~/reducers/planStopsLevelDataSlice';
import {
    resetSelectedDrawerCardData,
    setSelectedDrawerCardData
} from '~/reducers/selectedDrawerCardDataSlice';
import {
    resetSelectedDrawerCardId,
    setSelectedDrawerCardId
} from '~/reducers/selectedDrawerCardIdSlice';
import {
    resetSelectedMapRoutes,
    addSelectedMapRoute,
    removeSelectedMapRoute,
    setSelectedMapRoute
} from '~/reducers/selectedMapRoutesSlice';
import { resetSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import { resetSelectedTaskRowId } from '~/reducers/selectedTaskRowIdSlice';

import constants from '~/utils/constants';

/**
 * Live Routes Utilities
 *
 * @category Utils
 * @module utils/liveRoutesUtils
 *
 * @example
 * import { liveRoutes } from '~/utils/live-routes-utils';
 */

/**
 * Retrieves the current state of the `mapSettings` redux store
 * or the current value assigned to a given property
 *
 * @private
 * @param {String} [key] a property of the mapSettings redux store
 * @returns {*} the current state of the `mapSettings` redux store or the current value assigned to a given property
 */
function _getStoreMapSettings(key) {
    const { mapSettings } = store.getState();
    return key ? mapSettings[key] : mapSettings;
}

/**
 * Tests whether the user is selecting multiple cards in the routes drawer
 *
 * @returns {Boolean}
 */
function isSelectingMultipleCards() {
    const viewCardDetails = _getStoreMapSettings('viewCardDetails');
    const isMultipleCardSelectEnabled = _getStoreMapSettings(
        'isMultipleCardSelectEnabled'
    );

    return isMultipleCardSelectEnabled && !viewCardDetails;
}

/**
 * Sets several redux stores related to the target driver
 *
 * @param {Object} driverData the driver data
 */
function selectDriver(driverData) {
    const { clientDriverId } = driverData;
    const viewCardDetails = _getStoreMapSettings('viewCardDetails');
    // make object serializable for redux
    const serialLiveDriverData =
        driverData instanceof LiveDriver ? driverData.toJSON() : driverData;
    store.dispatch(setSelectedDrawerCardData(serialLiveDriverData));
    store.dispatch(setShouldFitPlanMapToBounds(true));
    store.dispatch(setSelectedDrawerCardId(clientDriverId));

    if (!viewCardDetails) {
        store.dispatch(setSelectedMapRoute(clientDriverId));
    }
}

/**
 * Resets several redux stores related to the target driver
 */
function deselectDriver() {
    const isMultipleCardSelectEnabled = _getStoreMapSettings(
        'isMultipleCardSelectEnabled'
    );

    store.dispatch(resetPlanStopsLevelData());
    store.dispatch(resetSelectedDrawerCardData());
    store.dispatch(resetSelectedDrawerCardId());
    store.dispatch(resetSelectedMapStops());
    store.dispatch(resetSelectedTaskRowId());
    store.dispatch(setMapMarkerMode(constants.mapMarkerModes.ROUTES));
    if (!isMultipleCardSelectEnabled) {
        store.dispatch(resetSelectedMapRoutes());
    }
}

/**
 * Handles selecting a driver using a marker or a card
 *
 * By default, the function will display the selected driver into a map drawer
 *
 * When multi-select is enabled, the function adds/removes the selected driver from the list
 *
 * @param {Boolean} isSelected indicates whether the driver is selected
 * @param {Object} driverData the driver data
 */
function handleDriverSelect(isSelected, driverData) {
    const { clientDriverId } = driverData;

    // selecting multiple
    if (isSelectingMultipleCards()) {
        store.dispatch(
            isSelected
                ? addSelectedMapRoute(clientDriverId)
                : removeSelectedMapRoute(clientDriverId)
        );
        return;
    }

    // selecting singles
    if (isSelected) {
        selectDriver(driverData);
    } else {
        deselectDriver();
    }
}

export const liveRoutesUtils = {
    isSelectingMultipleCards,
    selectDriver,
    deselectDriver,
    handleDriverSelect
};
