import React, { useEffect, useState } from 'react';
import Icon from '../Icon/Icon';
import './ProcessIndicator.scss';
import { Spinner } from '~/ui';

function ProcessIndicator({
    message = '',
    promiseInProgress = false,
    promiseRejected = false,
    position = 'right'
}) {
    const [visible, setVisible] = useState(false);
    const [isCancelClicked, setIsCancelClicked] = useState(false);
    const [transition, setTransition] = useState({
        opacity: 0
    });
    let processIndicatorClassName = 'processindicator';

    switch (position) {
        case 'right':
            processIndicatorClassName += ' processindicator-position-right';
            break;
        case 'center':
            processIndicatorClassName += ' processindicator-position-center';
            break;
        default:
            break;
    }

    useEffect(() => {
        if (promiseInProgress) setVisible(true);
        else {
            setVisible(false);
            setIsCancelClicked(false);
        }
    }, [promiseInProgress]);

    useEffect(() => {
        let fadeIn;
        let fadeOut;
        if (visible) {
            fadeIn = setTimeout(
                () =>
                    setTransition({
                        opacity: 0.8,
                        transition: 'all 0.1s ease'
                    }),
                15
            );
        } else {
            if (isCancelClicked) {
                fadeOut = setTimeout(() => {
                    setTransition({
                        opacity: 0,
                        transition: 'all 0.5s ease',
                        pointerEvents: 'none',
                        cursor: 'none'
                    });
                }, 10);
            }
            if (!isCancelClicked) {
                fadeOut = setTimeout(() => {
                    setTransition({
                        opacity: 0,
                        transition: 'all 0.5s ease',
                        pointerEvents: 'none',
                        cursor: 'none'
                    });
                }, 3000);
            }
        }
        return () => {
            [fadeIn, fadeOut].forEach(clearTimeout);
        };
    }, [visible, isCancelClicked]);

    function displayIcon() {
        if (!promiseRejected && promiseInProgress) {
            return <Spinner sx={{ width: '1.6rem', height: '1.6rem' }} />;
        }
        if (promiseRejected && !promiseInProgress)
            return <Icon icon="warning" color="venus" size="m" />;
        return <Icon icon="checkedOutline" size="m" />;
    }
    return (
        <div className={processIndicatorClassName} style={transition}>
            <div className="processindicator-item">{displayIcon()}</div>
            <div className="processindicator-item processindicator-text">
                {message}
            </div>
            {!promiseInProgress && !promiseRejected && (
                <div className="processindicator-item">
                    <Icon
                        icon="cancel"
                        color="comet"
                        size="m"
                        onClick={() => {
                            setVisible(false);
                            setIsCancelClicked(true);
                        }}
                    />
                </div>
            )}
        </div>
    );
}

export default ProcessIndicator;
