import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';

export const selectedMapStopsSlice = createSlice({
    name: 'selectedMapStops',
    initialState: [],
    reducers: {
        addSelectedMapStop: (state, action) => {
            const hasStop = state.includes(action.payload);
            if (!hasStop) {
                state.push(action.payload);
            }
            return state;
        },
        setSelectedMapStop: (state, action) => {
            return [action.payload];
        },
        replaceSelectedMapStop: (state, action) => {
            return action.payload;
        },
        removeSelectedMapStop: (state, action) => {
            const index = state.indexOf(action.payload);
            if (index !== -1) {
                state.splice(index, 1);
            }
            return state;
        },
        resetSelectedMapStops: () => {
            return [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return [];
        });
    }
});

export const {
    replaceSelectedMapStop,
    addSelectedMapStop,
    removeSelectedMapStop,
    setSelectedMapStop,
    resetSelectedMapStops
} = selectedMapStopsSlice.actions;

export const selectSelectedMapStops = (state) => state.selectedMapStops;

export const selectAreTaskStopsSelected = (taskId) => (state) =>
    state.selectedMapStops.some((combinedId) => combinedId.includes(taskId));

export default selectedMapStopsSlice.reducer;
