import constants from '~/utils/constants';
import { idUtils } from '~/utils/id-utils';

export default class PlanStop {
    constructor(stopLevelData) {
        this._stopLevelData = stopLevelData;
        this._colorCSS = {
            color: 'white',
            backgroundColor: 'gray'
        };
    }

    get clientId() {
        return this._stopLevelData.clientId;
    }

    get routeId() {
        return this._stopLevelData.routeId;
    }

    get clientRouteId() {
        return idUtils.getCombinedId(this.clientId, this.routeId);
    }

    get stopId() {
        return this._stopLevelData.stopId;
    }

    get taskId() {
        return this._stopLevelData.task.taskId;
    }

    /* @alias taskId */
    get id() {
        return this.taskId;
    }

    get clientRouteTaskId() {
        return idUtils.getCombinedId(this.clientRouteId, this.taskId);
    }

    get task() {
        return {
            ...this._stopLevelData.task,
            id: this._stopLevelData.task.taskId,
            stopName: this._stopLevelData.task.location.name,
            arrivalTime: this._stopLevelData.task.eta,
            clientRouteTaskId: this.clientRouteTaskId
        };
    }

    get address() {
        return _.pick(this._stopLevelData.task.location, [
            'addressLine1',
            'addressLine2',
            'city',
            'state',
            'zipcode'
        ]);
    }

    get location() {
        return this._stopLevelData.task.location;
    }

    /* @alias taskName */
    /**
     * the plan task stop name
     * @type {String}
     */
    get stopName() {
        return this._stopLevelData.task.location.name;
    }

    get arrivalTime() {
        return this._stopLevelData.task.eta;
    }

    get routeDate() {
        return this._stopLevelData.task.routeDate;
    }

    get delayFlag() {
        return this._stopLevelData.task.delayFlag;
    }

    get stopNumber() {
        return this._stopLevelData.task.stopNumber;
    }

    get labels() {
        return this._stopLevelData.task.labels || [];
    }

    get isPlanned() {
        return this._stopLevelData.task.isPlanned;
    }

    get isTwoPart() {
        return this._stopLevelData.task.isTwoPart;
    }

    get isDepot() {
        return this._stopLevelData.task.type === constants.taskTypes.DEPOT;
    }

    get type() {
        return this._stopLevelData.task.type;
    }

    get isPickup() {
        return this.type === constants.taskTypes.PICKUP;
    }

    get isDelivery() {
        return this.type === constants.taskTypes.DELIVERY;
    }

    get isHighPriority() {
        return (
            this._stopLevelData.task.priority === constants.priorityCodes.HIGH
        );
    }

    get markerCoordinates() {
        return this._stopLevelData.task.markerCoordinates;
    }

    get volumeCapacityUsed() {
        return this._stopLevelData.task.stats.volumeCapacityUsed;
    }

    get weightUsed() {
        return this._stopLevelData.task.stats.weightUsed;
    }

    get numInventoryItems() {
        return this._stopLevelData.task.stats.numInventoryItems;
    }

    get colorCSS() {
        return this._colorCSS;
    }

    set colorCSS(colorCSS) {
        this._colorCSS = colorCSS;
    }

    checkIsSame(planStop) {
        const isAPlanned = this.isPlanned;
        const isBPlanned = planStop.isPlanned;
        const taskIdA = this.taskId;
        const taskIdB = planStop.taskId;
        const hasMatchingIds = taskIdA === taskIdB;
        if (isAPlanned && isBPlanned) {
            return hasMatchingIds;
        }
        if (!isAPlanned && !isBPlanned) {
            const isATwoPart = this.isTwoPart;
            const isBTwoPart = planStop.isTwoPart;
            if (isATwoPart && isBTwoPart) {
                const aType = this.type;
                const bType = planStop.type;
                const hasSameType = aType === bType;
                return hasSameType && hasMatchingIds;
            }
            return hasMatchingIds;
        }
        return false;
    }

    toJSON() {
        const { task } = this;

        return {
            ...this._stopLevelData,
            task
        };
    }
}
