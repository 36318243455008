import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton } from '~/ui';

import './ZoomPhotoModalContent.scss';

function ZoomPhotoModalContent({ name, photoUrl, onHide }) {
    const { t } = useTranslation('taskManagement');
    return (
        <div className="zoomphotomodal">
            <div className="modal-header">
                <span>{name}</span>
                <IconButton
                    className="icon-wrapper"
                    icon="cancel"
                    iconColor="galaxy-800"
                    onClick={onHide}
                />
            </div>
            <div className="_d-flex _ai-center _jc-center zoomphotomodal-content">
                <img
                    className="zoomphotomodal-photo"
                    src={photoUrl}
                    alt={t('proof.image')}
                />
            </div>
        </div>
    );
}

export default ZoomPhotoModalContent;
