import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    selectShowStopPopup,
    selectShowStopNumber,
    selectShowStopLabel,
    selectIsClusteringStops,
    selectIsClusteringToggleEnabled,
    setShowStopPopup,
    setShowStopNumber,
    setShowStopLabel,
    setIsClusteringStops
} from '~/reducers/mapSettingsSlice';

import { Tooltip } from '~/ui';
import MapSettingMenuItem from '../MapSettingMenuItem/MapSettingMenuItem';
import MapSettingMenuButton from '../MapSettingMenuButton/MapSettingMenuButton';

import './map-settings-menu-sections.scss';

export default function MapSettingMenuStopsSection({
    mapRouteMode,
    openSubMenu,
    displayClustering
}) {
    const { t } = useTranslation(['translation']);
    const dispatch = useDispatch();
    const showStopPopupState = useSelector(selectShowStopPopup(mapRouteMode));
    const showStopNumberState = useSelector(selectShowStopNumber(mapRouteMode));
    const showStopLabelState = useSelector(selectShowStopLabel(mapRouteMode));
    const isClusteringStops = useSelector(selectIsClusteringStops);
    const isClusteringToggleEnabled = useSelector(
        selectIsClusteringToggleEnabled
    );
    const clusterToggleTooltip = isClusteringToggleEnabled
        ? t('clusterToggleEnabledTooltip')
        : t('clusterToggleDisabledTooltip');

    const stopMarkerClick = useCallback(() => {
        openSubMenu(t('stopMarker'), [
            {
                iconName: 'stopDetails',
                text: t('stopPopup'),
                checkedState: showStopPopupState,
                onChangeHandler: setShowStopPopup
            },
            {
                iconName: 'stopNumber',
                text: t('stopNumber'),
                checkedState: showStopNumberState,
                onChangeHandler: setShowStopNumber
            },
            {
                iconName: 'stopLabel',
                text: t('stopLabel'),
                checkedState: showStopLabelState,
                onChangeHandler: setShowStopLabel
            }
        ]);
    }, [showStopPopupState, showStopNumberState, showStopLabelState]);

    const onStopClusteringToggleStateChange = useCallback((checked) => {
        dispatch(setIsClusteringStops(checked));
    }, []);

    return (
        <>
            <span className="mapsettingssection_title">{t('stop')}</span>
            <MapSettingMenuButton
                iconName="stopMarker"
                text={t('stopMarker')}
                onClickHandler={stopMarkerClick}
            />
            {displayClustering && (
                <MapSettingMenuItem
                    iconName="stopClustering"
                    checkedState={isClusteringStops}
                    disabled={!isClusteringToggleEnabled}
                    onChangeState={onStopClusteringToggleStateChange}
                >
                    <Tooltip placement="bottom" content={clusterToggleTooltip}>
                        <span
                            className="mapsettingssection_item"
                            data-enabled={isClusteringToggleEnabled || null}
                        >
                            {t('stopClustering')}
                        </span>
                    </Tooltip>
                </MapSettingMenuItem>
            )}
        </>
    );
}
