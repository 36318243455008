import React, { Suspense } from 'react';
import {
    Route,
    BrowserRouter as Router,
    Switch,
    Redirect,
    useHistory
} from 'react-router-dom';
import { Loading } from '~/ui';
import generalUtils from '~/utils/general-utils';
import constants from '~/utils/constants';

const DriverBooking = React.lazy(() => import('~/components/DriverBooking'));
const DriverManagement = React.lazy(() =>
    import('~/components/DriverManagementPage')
);
const HomePage = React.lazy(() => import('~/components/HomePage'));
const LoginPage = React.lazy(() => import('~/components/LoginPage'));
const MainLayout = React.lazy(() => import('~/components/MainLayout'));
const MapPage = React.lazy(() => import('~/components/MapPage'));
const NotificationManagementPage = React.lazy(() =>
    import('~/components/NotificationManagementPage')
);
const Settings = React.lazy(() => import('~/components/SettingsPage'));
const TaskManagementPage = React.lazy(() =>
    import('~/components/TaskManagementPage')
);
const Unauthorized = React.lazy(() =>
    import('~/components/ErrorPage/Unauthorized')
);
const UsersAndPermissionsPage = React.lazy(() =>
    import('~/components/UsersAndPermissionsPage')
);
const CostConfigurationPage = React.lazy(() =>
    import('~/components/CostConfigurationPage')
);
const Workflow = React.lazy(() => import('~/components/WorkflowPage'));

// set `REACT_APP_ENABLE_DEBUGROUTER=true` in your `.env.local` file
// to debug routes
function DebugRouter({ children, enable }) {
    if (enable) {
        const { location } = useHistory();
        console.group('DebugRouter');
        console.info('Route', `${location.pathname}${location.search}`);
        console.info('State', location.state);
        console.groupEnd();
    }

    return children;
}

function App() {
    const { url } = constants;
    return (
        <Router basename={url.ROOT}>
            <Suspense fallback={<Loading fillViewport />}>
                <DebugRouter
                    enable={generalUtils.isFlaggedFeatureDisplayed(
                        'REACT_APP_ENABLE_DEBUGROUTER'
                    )}
                >
                    <Switch>
                        <Route exact path="/">
                            <Redirect to={url.LOGIN} />
                        </Route>
                        <Route path={url.LOGIN}>
                            <LoginPage />
                        </Route>
                        <Route path={url.LOGOUT}>
                            <div />
                        </Route>
                        <Route path={url.UNAUTHORIZED}>
                            <Unauthorized />
                        </Route>
                        <Route>
                            <MainLayout>
                                <Switch>
                                    <Route path={url.HOME}>
                                        <HomePage />
                                    </Route>
                                    <Route path={url.DRIVER_BOOKING}>
                                        <DriverBooking />
                                    </Route>
                                    <Route path={url.DRIVER_MANAGEMENT}>
                                        <DriverManagement />
                                    </Route>
                                    <Route path={url.WORKFLOW}>
                                        <Workflow />
                                    </Route>
                                    <Route path={url.MAP}>
                                        <MapPage />
                                    </Route>
                                    <Route path={url.COST_CONFIGURATION}>
                                        <CostConfigurationPage />
                                    </Route>
                                    <Route
                                        exact
                                        path={url.USERS_AND_PERMISSIONS}
                                    >
                                        <UsersAndPermissionsPage />
                                    </Route>
                                    <Route path={url.TASK_MANAGEMENT}>
                                        <TaskManagementPage />
                                    </Route>
                                    <Route
                                        exact
                                        path={url.NOTIFICATION_MANAGEMENT}
                                    >
                                        <NotificationManagementPage />
                                    </Route>
                                    <Route path={url.SETTINGS}>
                                        <Settings />
                                    </Route>
                                </Switch>
                            </MainLayout>
                        </Route>
                        <Route path={[url.UNAUTHORIZED, '*']}>
                            <Unauthorized />
                        </Route>
                    </Switch>
                </DebugRouter>
            </Suspense>
        </Router>
    );
}

export default App;
