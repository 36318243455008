import constants from '~/utils/constants';

function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
}

function getAssignmentCodeLabel(code) {
    const { assignmentCodes } = constants;
    return getKeyByValue(assignmentCodes, code);
}

function getDelayCodeLabel(code) {
    const { delayCodes } = constants;
    return getKeyByValue(delayCodes, code);
}

export { getAssignmentCodeLabel, getDelayCodeLabel };
