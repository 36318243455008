import React from 'react';
import PT from 'prop-types';
import { useTranslation } from 'react-i18next';

import './LabelInput.css';

function LabelInput({
    id = 'labelInput',
    label,
    placeholder,
    isErrorState = false,
    value,
    onChange,
    initialInputField = true,
    viewPassword = true,
    passwordField = false,
    togglePassword,
    onFocus,
    onBlur,
    className,
    disabled,
    buttonStyle = {}
}) {
    const inputType = viewPassword ? 'text' : 'password';
    const { t } = useTranslation(['usersAndPermissions', 'common', 'error']);
    return (
        <label htmlFor={id} className={`_fd-column _w-100 ${className}`}>
            {label && (
                <span
                    className={`labelinput-title ${
                        initialInputField ? '' : 'secondary-labelinput-title'
                    }`}
                >
                    {label}
                </span>
            )}

            <input
                id={id}
                type={inputType}
                className={`${
                    isErrorState ? 'label-input--error' : ''
                } label-input ${passwordField ? 'label-input_password' : ''}`}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onFocus={passwordField ? onFocus : () => {}}
                onBlur={passwordField ? onBlur : () => {}}
                autoComplete="new-password"
                disabled={disabled}
            />
            {passwordField ? (
                <button
                    type="button"
                    onClick={togglePassword}
                    className={buttonStyle}
                >
                    {viewPassword
                        ? t('common:hidePassword')
                        : t('common:showPassword')}
                </button>
            ) : (
                ''
            )}
        </label>
    );
}

export default LabelInput;

LabelInput.propTypes = {
    /** Label id */
    id: PT.string,
    /** Label title */
    label: PT.string,
    /** Input placeholder */
    placeholder: PT.string,
    /** Input value */
    value: PT.string,
    /** Input onChange function */
    onChange: PT.func,
    /** Error state of input */
    isErrorState: PT.bool,
    /** Input is the top one in the form */
    initialInputField: PT.bool,
    /** Input is of type password */
    passwordField: PT.bool,
    /** Show password input */
    viewPassword: PT.bool,
    /** Show/hide password input */
    togglePassword: PT.func,
    /** Input onFocus function */
    onFocus: PT.func,
    /** Input onBlur function */
    onBlur: PT.func,
    /** Classname of label component */
    className: PT.string,
    /** Input disabled */
    disabled: PT.bool
};
