import React, { useState, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { DateTime } from 'luxon';

import { routePlansAPI } from '~/api/route-plans';

import { useToastContext, useFetchMainClientAddress } from '~/hooks';

import { setRoutePlansByDate } from '~/reducers/routePlansByDateSlice';
import { selectDateOnly } from '~/reducers/selectedDateSlice';

import { useModal, ActionBarItem } from '~/ui';

import DateSelectModalContent from './DateSelectModalContent';

const DateSelectModal = React.forwardRef(function DateSelectModal(
    { onClickDone },
    ref
) {
    const {
        hideModal: hideDateModal,
        showModal: showDateModal,
        modal: DateModal
    } = useModal();
    const { t } = useTranslation('translation');
    const { addToast } = useToastContext();

    const [uniqueDates, setUniqueDates] = useState([]);
    const selectedDate = useSelector(selectDateOnly);
    const dispatch = useDispatch();
    const { data: { data: mainClientAddress = {} } = {} } =
        useFetchMainClientAddress();
    const { name: addressName } = mainClientAddress;

    function initDateModalContent() {
        (async () => {
            const routePlansByDate = {};
            const uniqueRouteDates = [];
            try {
                const response = await routePlansAPI.get(null, {
                    start_from: DateTime.local().toISODate(),
                    active: true,
                    include: 'type'
                });
                for (const routePlan of response.data.data) {
                    if (!routePlansByDate[routePlan.route_date]) {
                        routePlansByDate[routePlan.route_date] = [];
                        uniqueRouteDates.push(routePlan.route_date);
                    }
                    routePlansByDate[routePlan.route_date].push(routePlan);
                }
                dispatch(setRoutePlansByDate(routePlansByDate));
                setUniqueDates(_.sortBy(uniqueRouteDates));
            } catch (e) {
                console.error(e);
                addToast({
                    message: t('error:routesFetchError'),
                    variant: 'error'
                });
            }
            showDateModal();
        })();
    }

    function dateStationFormatted() {
        if (!selectedDate) return null;

        const dateTime = DateTime.fromISO(selectedDate);
        const dateString = dateTime
            .setLocale(navigator.language)
            .toLocaleString({
                weekday: 'short',
                month: 'short',
                day: 'numeric'
            });

        if (!addressName) return dateString;

        return [dateString, addressName].join(', ');
    }

    // expose methods to `ref`
    useImperativeHandle(ref, () => ({
        initDateModalContent
    }));

    return (
        <>
            <ActionBarItem
                details={dateStationFormatted() || t('DatesAndStations')}
                onClick={initDateModalContent}
                type="noIcon"
            />
            <DateModal hasCustomHeader variant="date">
                <DateSelectModalContent
                    dates={uniqueDates}
                    hideModal={hideDateModal}
                    addressName={addressName}
                    onClickDone={onClickDone}
                />
            </DateModal>
        </>
    );
});

export default DateSelectModal;
