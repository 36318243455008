import { useCallback, useEffect } from 'react';

export const useEscKey = (callback) => {
    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            callback(event);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);

        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, []);
};
