import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Text, TextInput } from '~/ui';

export const ReassignModalSearch = ({
    names,
    selectedName,
    setSelectedName
}) => {
    const { t } = useTranslation('translation');
    const inputPlaceholder = t('ReassignModal.Search.input_placeholder');
    const [inputValue, setInputValue] = useState('');

    const filteredNames = useMemo(
        () =>
            names.filter(
                (name) =>
                    name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            ),
        [inputValue, names]
    );

    const isShowDropdown = useMemo(
        () =>
            Boolean(inputValue) &&
            filteredNames.length > 0 &&
            !filteredNames.includes(selectedName),
        [filteredNames, inputValue, selectedName]
    );

    const handleInputChange = useCallback(
        (value) => {
            const foundNameByInputValue = filteredNames.find(
                (name) => name.toLowerCase() === value.toLowerCase()
            );

            if (selectedName && selectedName !== foundNameByInputValue)
                setSelectedName(null);

            setInputValue(value);
        },
        [filteredNames, selectedName]
    );

    const handleItemClick = useCallback(
        (name) => {
            setInputValue(name);
            setSelectedName(name);
        },
        [setSelectedName]
    );

    return (
        <div className="reassignmodal-search">
            <TextInput
                onChange={handleInputChange}
                placeholder={inputPlaceholder}
                value={inputValue}
                type="text"
                variant="basic"
            />
            {isShowDropdown && (
                <div className="reassignmodal-search-dropwdown">
                    {filteredNames.map((name) => {
                        return (
                            <Button
                                key={name}
                                className="_ai-center _jc-flexst"
                                onClick={() => handleItemClick(name)}
                            >
                                <Text className="_text-3" variant="12-normal">
                                    {name}
                                </Text>
                            </Button>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
