import { useQuery } from 'react-query';
import constants from '~/utils/constants';
import tasksApi from '~/api/tasks';

const optimalPair = async (selectedTaskIds) => {
    const { data } = await tasksApi.generatePairings({
        taskIds: selectedTaskIds
    });

    return data;
};

export const useTaskPairingOptimal = (
    selectedTaskIds,
    reactQueryOptions = {}
) => {
    const defaultReactQueryOptions = {
        enabled: false,
        retry: false
    };

    // get API response
    return useQuery(
        [constants.reactQueryKeys.TASKS_PAIR_OPTIMAL, selectedTaskIds],
        () => optimalPair(selectedTaskIds),
        { ...defaultReactQueryOptions, ...reactQueryOptions }
    );
};
