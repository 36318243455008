function filterCepResults(payload) {
    const { clientId, data } = payload;
    const { body, channel } = data;
    const {
        progress,
        routeDate,
        schedulerTaskId,
        preschedulerSolutionInfo: solutionInfo
    } = body;

    return {
        clientId,
        channel,
        progress,
        routeDate,
        schedulerTaskId,
        solutionInfo
    };
}

export default {
    filterCepResults
};
