import axios from 'axios';
import dateUtils from '~/utils/date-utils';
import constants from '~/utils/constants';

export const routePlansAPI = {
    path: '/routeplans',
    defaultLimit: 1000,
    get(id, params = {}) {
        params.limit = params.limit || this.defaultLimit;
        const path = id ? `${this.path}/${id}` : this.path;
        return axios.get(path, {
            params
        });
    },
    generateRoutesReport(
        params = {
            route_date: undefined,
            template: undefined,
            filename: undefined,
            to_export: undefined
        }
    ) {
        if (!params.route_date) {
            return Promise.reject('missing route_date querystring parameter');
        }

        params.route_date = dateUtils.convertToISODateOnly(params.route_date);

        return axios.post(`${this.path}/report`, undefined, {
            params,
            headers: {
                [constants.requestHeaders.WISE_CLIENT_ID]: ''
            }
        });
    }
};
