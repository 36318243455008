import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import LiveDriver from '~/data-classes/dispatched/LiveDriver';
import PlanRoute from '~/data-classes/plan/PlanRoute';
import {
    selectCompletedDrivers,
    selectDispatchedDrivers
} from '~/reducers/liveDriversSlice';
import {
    selectHasIsolatedRoutes,
    selectViewCardDetails
} from '~/reducers/mapSettingsSlice';
import { selectPlanRoutesLevelData } from '~/reducers/planRoutesLevelDataSlice';
import { selectSelectedMapRoutes } from '~/reducers/selectedMapRoutesSlice';

/**
 *
 * @param {PlanRoute[] | LiveDriver[]} routes - Routes to filter on
 */
export function useRoutesWithTasks(routes) {
    const selectedMapRouteIds = useSelector(selectSelectedMapRoutes);
    const hasIsolatedRoutes = useSelector(selectHasIsolatedRoutes);
    const viewCardDetails = useSelector(selectViewCardDetails);

    return useMemo(
        () =>
            routes.filter((route) => {
                const { hasTasks, clientRouteId, clientDriverId } = route;
                const isSelectedRoutesOnly =
                    Boolean(selectedMapRouteIds.length) &&
                    hasIsolatedRoutes &&
                    viewCardDetails;
                if (hasTasks && isSelectedRoutesOnly) {
                    return clientRouteId
                        ? selectedMapRouteIds.includes(clientRouteId)
                        : selectedMapRouteIds.includes(clientDriverId);
                }
                return hasTasks;
            }),
        [routes, selectedMapRouteIds, hasIsolatedRoutes, viewCardDetails]
    );
}

export function usePlanRoutes() {
    const planRoutesLevelData = useSelector(selectPlanRoutesLevelData);
    return useMemo(
        () => planRoutesLevelData.map((data) => new PlanRoute(data)),
        [planRoutesLevelData]
    );
}

export function usePlanRoutesWithTasks() {
    const planRoutes = usePlanRoutes();
    return useRoutesWithTasks(planRoutes);
}

export function useDispatchedLiveDrivers() {
    const dispatchedDrivers = useSelector(selectDispatchedDrivers);
    return useMemo(
        () => dispatchedDrivers.map((driver) => new LiveDriver(driver)),
        [dispatchedDrivers]
    );
}

export function useDispatchedDriversWithTasks() {
    const dispatchedLiveDrivers = useDispatchedLiveDrivers();
    return useRoutesWithTasks(dispatchedLiveDrivers);
}

export function useCompletedLiveDrivers() {
    const completedDrivers = useSelector(selectCompletedDrivers);
    return useMemo(
        () => completedDrivers.map((driver) => new LiveDriver(driver)),
        [completedDrivers]
    );
}

export function useCompletedDriversWithTasks() {
    const completedLiveDrivers = useCompletedLiveDrivers();
    return useRoutesWithTasks(completedLiveDrivers);
}
