import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';
import tasksApi from '~/api/tasks';

export const updateTaskMetrics = createAsyncThunk(
    'taskMetrics/updateTaskMetrics',
    async (routeDate, { fulfillWithValue, rejectWithValue }) => {
        try {
            const taskMetricsResponse = await tasksApi.getMetrics({
                date: routeDate
            });
            const taskMetrics = taskMetricsResponse.data.data;
            return fulfillWithValue(taskMetrics);
        } catch (error) {
            return rejectWithValue({});
        }
    }
);

const emptyMetrics = {
    unassigned: 0,
    planned: 0,
    dispatched: 0,
    completed: 0,
    canceled: 0,
    total: 0
};

export const taskMetricsSlice = createSlice({
    name: 'taskMetrics',
    initialState: emptyMetrics,
    reducers: {
        setTaskMetrics: (state, action) => {
            return action.payload;
        },
        resetTaskMetrics: () => {
            return emptyMetrics;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return emptyMetrics;
        });
        builder.addCase(updateTaskMetrics.fulfilled, (state, action) => {
            state = action.payload;
            return state;
        });
        builder.addCase(updateTaskMetrics.rejected, (state, action) => {
            state = action.payload;
            return state;
        });
    }
});

export const { setTaskMetrics, resetTaskMetrics } = taskMetricsSlice.actions;

export const selectTaskMetrics = (state) => state.taskMetrics;

export default taskMetricsSlice.reducer;
