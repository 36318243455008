import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { resetToInitialState } from '~/reducers/common-actions';

/**
 * Redux slice for unassigned plan task count
 * @category Reducers
 * @module unassignedPlanTaskCountSlice
 */

export const unassignedPlanTaskCountSlice = createSlice({
    name: 'unassignedPlanTaskCount',
    initialState: 0,
    reducers: {
        /**
         * set the count of unassigned plan task
         * @method setUnassignedPlanTaskCount
         * @param {Number} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {Number} action.payload - the reducer's payload
         * @returns {Number} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setUnassignedPlanTaskCount } from '~/reducers/unassignedPlanTaskCountSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // set unassigned plan task count
         * const dispatch = useDispatch();
         * dispatch(setUnassignedPlanTaskCount(integer));
         */
        setUnassignedPlanTaskCount: (state, action) => {
            const isInteger = _.isInteger(action.payload);
            if (!isInteger) {
                return state;
            }
            return action.payload;
        },

        /**
         * reset unassigned plan task count slice to initial state
         * @returns {Number} the initial state
         * @example <caption>Usage</caption>
         * // import statement
         * import { resetUnassignedPlanTaskCount } from '~/reducers/unassignedPlanTaskCountSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // reset back to initial state
         * const dispatch = useDispatch();
         * dispatch(resetUnassignedPlanTaskCount());
         */
        resetUnassignedPlanTaskCount: () => {
            return 0;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return 0;
        });
    }
});

export const { setUnassignedPlanTaskCount, resetUnassignedPlanTaskCount } =
    unassignedPlanTaskCountSlice.actions;

/**
 * @method
 * @returns {Number} the current unassigned task count
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectUnassignedPlanTaskCount } from '~/reducers/unassignedPlanTaskCountSlice';
 * import { useSelector } from 'react-redux';
 *
 * // get the unassigned task count
 * const unassignedPlanTaskCount = useSelector(selectUnassignedPlanTaskCount);
 */
export const selectUnassignedPlanTaskCount = (state) =>
    state.unassignedPlanTaskCount;

export default unassignedPlanTaskCountSlice.reducer;
