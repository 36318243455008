import axios from 'axios';
import constants from '~/utils/constants';

export default {
    path: '/address',
    get(addressId) {
        if (!addressId) {
            return Promise.reject('address id not provided');
        }
        return axios.get(`${this.path}/${addressId}`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }
};
