import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAllTasks, selectTasks } from '~/reducers/tasksSlice';
import { selectDate } from '~/reducers/selectedDateSlice';
import { Task } from '~/data-classes';

export const useGetAllTasks = () => {
    const selectedTasks = useSelector(selectTasks);
    const selectedDate = useSelector(selectDate);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllTasks({ routeDate: selectedDate }));
    }, [selectedDate]);

    const allTasks = useMemo(
        () =>
            Object.fromEntries(
                Object.entries(selectedTasks).map(([key, task]) => [
                    key,
                    new Task(task)
                ])
            ),
        [selectedTasks]
    );
    const tasks = Object.values(allTasks);
    const numberOfTasks = tasks.length;
    const numberOfUnassignedTasks = tasks.filter(
        ({ isUnassigned }) => isUnassigned
    ).length;

    return {
        allTasks,
        numberOfTasks,
        numberOfUnassignedTasks
    };
};
