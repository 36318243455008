import React from 'react';
import PT from 'prop-types';
import './drawer.scss';

function Drawer({
    className,
    isClosed = false,
    children,
    onMouseEnter,
    onMouseLeave
}) {
    function _getClassName() {
        let boxClassName = 'drawer _d-flex _fd-column';
        boxClassName =
            (isClosed && `${boxClassName} drawer_closed`) || boxClassName;
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }

    return (
        <div
            className={_getClassName()}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {children}
        </div>
    );
}

function DrawerHeader({ children, className }) {
    function _getClassName() {
        let boxClassName = 'drawer-header';
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }
    return <div className={_getClassName()}>{children}</div>;
}

function DrawerBody({ children, className }) {
    function _getClassName() {
        let boxClassName = 'drawer-body';
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }
    return <div className={_getClassName()}>{children}</div>;
}

function DrawerFooter({ children, className }) {
    function _getClassName() {
        let boxClassName = 'drawer-footer';
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }
    return <div className={_getClassName()}>{children}</div>;
}

function DrawerButtonGroup({ children, className }) {
    function _getClassName() {
        let boxClassName = 'drawer-buttongroup _d-flex _ai-center';
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }
    return <div className={_getClassName()}>{children}</div>;
}

Drawer.propTypes = {
    /**
     * User-defined CSS class name
     */
    className: PT.string,
    /**
     * Identify the drawer as closed or open
     */
    isClosed: PT.bool,
    /**
     * onMouseEnter Handler
     */
    onMouseEnter: PT.func,
    /**
     * onMouseLeave Handler
     */
    onMouseLeave: PT.func,
    /**
     * A React child element or elements wrapped by the **Drawer** element to render into the map.
     */
    children: PT.oneOfType([PT.node, PT.arrayOf(PT.node)])
};

Drawer.defaultProps = {
    className: '',
    isClosed: false
};

export { Drawer, DrawerHeader, DrawerBody, DrawerFooter, DrawerButtonGroup };
