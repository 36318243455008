import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';

import addressAPI from '~/api/address';
import constants from '~/utils/constants';
import { selectMainClient } from '~/reducers/mainClientSlice';

export const useFetchMainClientAddress = () => {
    const mainClient = useSelector(selectMainClient);
    const mainClientAddressId = mainClient?.address;

    return useQuery(
        [constants.reactQueryKeys.ADDRESS_BY_ID, mainClientAddressId],
        async () => {
            if (!mainClientAddressId) {
                console.warn('no main client');

                return;
            }

            const { data } = await addressAPI.get(mainClientAddressId);

            return data;
        }
    );
};
