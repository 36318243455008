import React from 'react';
import PT from 'prop-types';

import { RouteCardMetric, TextOverflowTooltip } from '~/ui';
import './routecard-title.scss';

function RouteCardTitle({
    className,
    children,
    title,
    titleIcons = [],
    tooltip = '',
    tooltipPlacement = 'top'
}) {
    function _getClassName() {
        let boxClassName = 'routecard-top _d-flex _jc-space-between _ai-center';
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }

    function _getIcons() {
        if (!titleIcons || !titleIcons.length) return null;

        return titleIcons.map((item) => {
            const {
                metric,
                value,
                maxValue,
                icon,
                iconSize,
                iconColor,
                icons
            } = item;

            return (
                <RouteCardMetric
                    key={metric}
                    className={item.className}
                    metric={metric}
                    value={value}
                    maxValue={maxValue}
                    icon={icon}
                    iconSize={iconSize}
                    iconColor={iconColor}
                    icons={icons}
                />
            );
        });
    }

    return (
        <div className={_getClassName()}>
            {title && (
                <div className="routecard-top_group _d-flex">
                    <TextOverflowTooltip
                        placement={tooltipPlacement}
                        content={tooltip}
                    >
                        <span className="routecard-title _tt-capitalize _ws-nowrap">
                            {title}
                        </span>
                    </TextOverflowTooltip>
                    {_getIcons()}
                </div>
            )}
            {children}
        </div>
    );
}

RouteCardTitle.propTypes = {
    /** additional css classes to attach to this component */
    className: PT.string
};

export default RouteCardTitle;
