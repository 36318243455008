import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
    ManageStopControlListItem,
    ManageStopControlMenu,
    ManageStopControlMultiple,
    StopModalMenuButton,
    theme
} from '~/ui';

import constants from '~/utils/constants';

function MultiTasksControl({
    selectedTasks,
    onClickDispatchButton = () => {},
    onClickPairButton = () => {},
    footerButtonTitle,
    footerButtonIcon,
    onClickFooterButton = () => {}
}) {
    const [selectedStops, setSelectedStops] = useState([]);
    const { t } = useTranslation('translation');

    useEffect(() => {
        setSelectedStops(selectedTasks);
    }, [selectedTasks]);

    function _handleClickDispatch() {
        return onClickDispatchButton && onClickDispatchButton();
    }

    function _handleClickPair() {
        return onClickPairButton && onClickPairButton();
    }

    function _getStopList() {
        return selectedStops.map((selectedStop) => (
            <ManageStopControlListItem
                key={selectedStop.twoPartId}
                className="multi-tasks-control_list-item"
                text={selectedStop.name}
                iconButtonText="?"
                iconButtonTextColor={theme.colors.comet}
            />
        ));
    }
    return (
        <ManageStopControlMultiple
            className="multi-tasks-control"
            controlTitle={t(
                'UnassignedTasksControl.title.no_of_tasks_selected',
                {
                    count: selectedStops.length
                }
            )}
            controlHelp={t('UnassignedTasksControl.title.selection_info')}
            stopList={_getStopList()}
            footerButtonTitle={footerButtonTitle}
            footerButtonIcon={footerButtonIcon}
            onClickFooterButton={onClickFooterButton}
        >
            <ManageStopControlMenu>
                <StopModalMenuButton
                    titleIcon="vehicleEmpty"
                    title={t('UnassignedTasksControl.button.dispatch')}
                    onClickHandler={_handleClickDispatch}
                    stopText={t('UnassignedTasksControl.button.tasksSelected', {
                        count: selectedStops.length,
                        total: constants.onDemandDispatch.CONTROL_DISPATCH_LIMIT
                    })}
                    shouldRender
                />
                <StopModalMenuButton
                    titleIcon="link"
                    title={t('UnassignedTasksControl.button.pair')}
                    onClickHandler={_handleClickPair}
                    shouldRender
                />
            </ManageStopControlMenu>
        </ManageStopControlMultiple>
    );
}

export default MultiTasksControl;
