import { createSlice } from '@reduxjs/toolkit';
import { idUtils } from '~/utils/id-utils';
import { resetToInitialState } from '~/reducers/common-actions';

export const hiddenRoutesSlice = createSlice({
    name: 'hiddenRoutes',
    initialState: {},
    reducers: {
        setHiddenRoutes: (state, action) => {
            state = action.payload;
            return state;
        },
        addHiddenRoute: (state, action) => {
            state[action.payload] = true;
            return state;
        },
        removeHiddenRoute: (state, action) => {
            delete state[action.payload];
            return state;
        },
        showClientRoutes: (state, action) => {
            const clientId = action.payload;
            for (const key in state) {
                if (idUtils.splitCombinedId(key)[0] === clientId) {
                    delete state[key];
                }
            }
            return state;
        },
        hideRoutes: (state, action) => {
            for (const clientRouteId of action.payload) {
                state[clientRouteId] = true;
            }
            return state;
        },
        resetHiddenRoutes: () => {
            return {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return {};
        });
    }
});

export const {
    setHiddenRoutes,
    addHiddenRoute,
    removeHiddenRoute,
    showClientRoutes,
    hideRoutes,
    resetHiddenRoutes
} = hiddenRoutesSlice.actions;
export const selectHiddenRoutes = (state) => state.hiddenRoutes;
export default hiddenRoutesSlice.reducer;
