/**
 * Location Utilities
 *
 * @category Utils
 * @module utils/locationUtils
 *
 * @example
 * import { locationUtils } from '~/utils/location-utils';
 */

/**
 * @typedef Coordinates
 * @type {Object}
 * @property {Number} lat the latitude value
 * @property {Number} lng the longtitude value
 */

/**
 * @typedef GeoJSONPoint
 * @type {Array}
 * @property {Number} 0 the longtitude value
 * @property {Number} 1 the latitude value
 */

/**
 * Roughly approximates the geographical center of a list of lat lng tuples.
 * Better used for set of points that are geographically close to one another
 * (eg.: stop locations for a route).
 *
 * @param {Coordinates[]} allCoordinates
 * @returns {Coordinates}
 */
function getAverageCoordinates(allCoordinates) {
    const averageCoordinates = {
        lat: 0,
        lng: 0
    };
    for (const coordinates of allCoordinates) {
        averageCoordinates.lat += coordinates.lat;
        averageCoordinates.lng += coordinates.lng;
    }
    averageCoordinates.lat /= allCoordinates.length;
    averageCoordinates.lng /= allCoordinates.length;
    return averageCoordinates;
}

/**
 * Converts a lat/lng object to a GeoJSON point.
 *
 * @param {Coordinates} coordinatesObject
 * @returns {GeoJSONPoint} GeoJSON point ([lng, lat])
 */
function convertLatLngToGeoJsonPoint(coordinatesObject) {
    return [coordinatesObject.lng, coordinatesObject.lat];
}

/**
 * Converts a GeoJSON point to a lat/lng object.
 *
 * @param {GeoJSONPoint} point GeoJSON point ([lng, lat])
 * @returns {Coordinates}
 */
function convertGeoJsonPointToLatLng(point) {
    return {
        lat: point[1],
        lng: point[0]
    };
}

export const locationUtils = {
    getAverageCoordinates,
    convertGeoJsonPointToLatLng,
    convertLatLngToGeoJsonPoint
};
