import React, { useCallback, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import Tooltip from '~/ui/components/Tooltip';

const TextOverflowTooltip = (props) => {
    const [element, setElement] = useState();
    const [hasEllipsis, setHasEllipsis] = useState(false);
    const wrapperRef = useCallback(setElement);

    useResizeObserver(element, ({ target }) =>
        setHasEllipsis(target.scrollWidth > target.clientWidth)
    );

    return <Tooltip disabled={!hasEllipsis} {...props} ref={wrapperRef} />;
};

TextOverflowTooltip.propTypes = Tooltip.propTypes;

export default TextOverflowTooltip;
