import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';

export const cepResultsSlice = createSlice({
    name: 'cepResults',
    initialState: {},
    reducers: {
        setCepResults: (state, action) => {
            return action.payload;
        },
        setCepResultsByClient: (state, action) => {
            if (!state) {
                state = {};
            }
            const { clientId } = action.payload;
            state[clientId] = action.payload;
            return state;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return {};
        });
    }
});

export const { setCepResults, setCepResultsByClient } = cepResultsSlice.actions;

export const selectCepResults = (state) => state.cepResults;

export default cepResultsSlice.reducer;
