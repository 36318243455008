import axios from 'axios';
import constants from '~/utils/constants';

export default {
    path: '/inventoryitems',
    get(params = { task_id, signed: false }) {
        return axios.get(this.path, {
            params,
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    },
    updateInventoryItem(id, payload) {
        return axios.patch(
            `${this.path}/${id}`,
            { ...payload },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    },
    deleteInventoryItem(id) {
        return axios.delete(`${this.path}/${id}`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }
};
