import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, TooltipButton } from '~/ui';

import './PhotoModalContent.scss';

function PhotoModalContent({ inventoryItems, onPhotoClick }) {
    const { t } = useTranslation('taskManagement');

    function onClick(name, photoUrl) {
        return onPhotoClick && onPhotoClick(name, photoUrl);
    }

    return (
        <div className="_d-flex _fw-wrap photomodal">
            {inventoryItems.map((item) => {
                return item.photo_urls.map((photoUrl) => {
                    return (
                        <div
                            key={photoUrl}
                            className="_p-relative _text-2 _m-2 photomodal-inventoryitem"
                        >
                            <TooltipButton
                                className="_mb-1 photomodal-inventoryphoto"
                                onClick={() =>
                                    onClick(item.item_name, photoUrl)
                                }
                            >
                                <img
                                    className="photomodal-image"
                                    src={photoUrl}
                                    alt={t('proof.image')}
                                />
                            </TooltipButton>
                            <span>{item.item_name}</span>
                            <IconButton
                                className="photomodal-button"
                                icon="download"
                                iconColor="meteor"
                                onClick={() => window.open(photoUrl)}
                            />
                        </div>
                    );
                });
            })}
        </div>
    );
}

export default PhotoModalContent;
