import React from 'react';
import classNames from 'classnames';

import './routecard-marker-no-button.scss';

/**
 * A variant of RouteCardMarker that doesn't use a button element.
 * Useful for when trying to nest a route card marker in another button.
 */
export default function RouteCardMarkerNoButton({
    className,
    initials,
    colorCSS
}) {
    function _getClassName() {
        return classNames('routecard-marker_no-button', className);
    }
    return (
        <div style={{ ...colorCSS }} className={_getClassName()}>
            {initials}
        </div>
    );
}
