import React from 'react';
import { useTranslation } from 'react-i18next';

import './SignatureModalContent.scss';

function SignatureModalContent({ signatureUrl }) {
    const { t } = useTranslation('taskManagement');
    return (
        <div className="_d-flex _ai-center _jc-center signaturemodal">
            <img
                className="signaturemodal-image"
                src={signatureUrl}
                alt={t('proof.signature')}
            />
        </div>
    );
}

export default SignatureModalContent;
