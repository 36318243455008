import { useCallback } from 'react';

// inspired by https://www.davedrinks.coffee/how-do-i-use-two-react-refs/

export const useMergedRefs = (...refs) => {
    return useCallback(
        (element) =>
            refs.forEach((ref) => {
                if (typeof ref === 'function') {
                    ref(element);
                } else if (ref) {
                    ref.current = element;
                }
            }),
        refs
    );
};
