import React from 'react';
import PT from 'prop-types';

import { Icon } from '~/ui';

import '~/ui/components/FormErrorMessage/FormErrorMessage.css';

function FormErrorMessage({ icon = 'errorIconFill', message, className = '' }) {
    return (
        <div className={`${className} _d-flex _ai-center errormessage`}>
            <Icon icon={icon} />
            <span className="_ml-4 _fs-0">{message}</span>
        </div>
    );
}

export default FormErrorMessage;

FormErrorMessage.propTypes = {
    /** Icon name */
    icon: PT.string,
    /** Error message */
    message: PT.string
};
