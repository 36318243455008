import i18n from 'i18next';

/**
 * General Utilities
 *
 * @category Utils
 * @module utils/generalUtils
 *
 * @example
 * import generalUtils from '~/utils/general-utils';
 */

/**
 * Get the capitalized first letter of a string
 *
 * @param {String} str
 * @returns {string}
 */
function getCapitalizedFirstChar(str) {
    return str.charAt(0).toUpperCase();
}

/**
 * Return user's localized full name, if unavailable, returns alternative
 *
 * @param {String} firstName
 * @param {String} lastName
 * @param {String} alternative
 * @returns {String} full name or alternative
 */
function getUserName(firstName, lastName, alternative = '') {
    if (!firstName && !lastName) {
        return alternative;
    }
    return i18n.t('common:name.full', { firstName, lastName });
}

/**
 * Return user's localized initials, if unavailable, returns alternative
 *
 * @param {String} firstName
 * @param {String} lastName
 * @param {String} alternative
 * @returns {String} initials or alternative
 */
function getUserInitials(firstName, lastName, alternative = '') {
    if (!firstName && !lastName) {
        return alternative;
    }
    const firstInitial = getCapitalizedFirstChar(firstName);
    const lastInitial = getCapitalizedFirstChar(lastName);
    return i18n.t('common:name.initials', { firstInitial, lastInitial });
}

/**
 * Displays a flagged feature based on provided environment variable
 * or detected hostname (local, stage or sandbox)
 *
 * @param {String} [env=null] - The target env variable
 * @example
 * // default, tests the user's hostname against a preset list
 * isFlaggedFeatureDisplayed()
 * @example
 * // tests against an env variable `REACT_APP_ENABLE_LIVE_ROUTES`
 * isFlaggedFeatureDisplayed('REACT_APP_ENABLE_LIVE_ROUTES')
 * @returns {Boolean}
 */
function isFlaggedFeatureDisplayed(env = null) {
    // test for env variable if provided
    // refer to: https://create-react-app.dev/docs/adding-custom-environment-variables
    if (env) {
        // dotenv typecasts values as strings
        // compare against the string `true`
        const isFlagEnabled =
            process.env[`${env.toUpperCase()}`] &&
            process.env[`${env.toUpperCase()}`].toLowerCase() === 'true';

        return isFlagEnabled;
    }

    // fallback to detecting hostname from a preset list
    const { hostname } = window.location;
    const isLocal = /localhost/.test(hostname) || /127\.0\.0\.1/.test(hostname);
    const isStage = /admin\.stage\.wisesys\.info/.test(hostname);
    const isSandbox =
        /admin\.sandbox\.wisesys\.info/.test(hostname) ||
        /wise-web-multi-client-mrs\.wisesys\.info/.test(hostname);

    return isLocal || isStage || isSandbox;
}

/**
 * Tests if the provided string is a valid uuid string
 *
 * @param {String} uuidValue
 * @example
 * // tests against a valid UUID string `54b8f1d7-c50a-4ab6-b2d5-c36ee6bb9053`
 * isValidUUID('54b8f1d7-c50a-4ab6-b2d5-c36ee6bb9053')
 * @returns {Boolean} true if uuidValue is valid
 */
function isValidUUID(uuidValue) {
    return /^[a-z0-9]{8}(-[a-z0-9]{4}){3}-[a-z0-9]{12}$/.test(uuidValue);
}

export default {
    getCapitalizedFirstChar,
    getUserName,
    getUserInitials,
    isFlaggedFeatureDisplayed,
    isValidUUID
};
