import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    replaceSelectedMapStop,
    selectSelectedMapStops
} from '~/reducers/selectedMapStopsSlice';

import ResequenceModalStopList from '~/components/MapPage/ResequenceModal/ResequenceModalStopList';
import { useUnassignStops } from '~/hooks/useUnassignStops';

import { mapPlanStopUtils } from '~/utils/map/map-planstop-utils';
import {
    ManageStopControlMultiple,
    ManageStopControlMenu,
    StopModalMenuButton
} from '~/ui';
import constants from '~/utils/constants';

function MultiStopsControl({
    selectedPlanStops,
    stopIdPropertyName,
    disableResequencing = false,
    footerButtonTitle,
    footerButtonIcon,
    isLiveStopControl,
    onSwitchView,
    onClickFooterButton = () => {}
}) {
    const dispatch = useDispatch();

    const selectedMapStopIds = useSelector(selectSelectedMapStops);
    const [dragStartIndex, setDragStartIndex] = useState(null);

    const { t } = useTranslation('translation');
    const { unassignStops, isUnassignStopsAllowed } = useUnassignStops();

    const _handleDrop = (e, index) => {
        const newPlanStopsArray = mapPlanStopUtils.processStopsReorder(
            e,
            selectedPlanStops,
            stopIdPropertyName,
            dragStartIndex,
            index
        );
        dispatch(replaceSelectedMapStop(newPlanStopsArray));
    };

    function _getStopList() {
        return selectedPlanStops.map((selectedStop, index) => (
            <ResequenceModalStopList
                key={selectedStop[stopIdPropertyName]}
                stopIdPropertyName={stopIdPropertyName}
                selectedStop={selectedStop}
                handleDragStart={() => setDragStartIndex(index)}
                handleDragOver={(e) => e.preventDefault()}
                handleDrop={(e) => _handleDrop(e, index)}
            />
        ));
    }

    return (
        <ManageStopControlMultiple
            className="multistopcontrol"
            controlTitle={t('PlanStopsControl.title.no_of_stops_selected', {
                length: selectedMapStopIds.length
            })}
            controlHelp={t('PlanStopsControl.title.selection_info')}
            stopList={_getStopList()}
            footerButtonTitle={footerButtonTitle}
            footerButtonIcon={footerButtonIcon}
            onClickFooterButton={onClickFooterButton}
        >
            <ManageStopControlMenu>
                <StopModalMenuButton
                    disabled={disableResequencing}
                    titleIcon="iconReorder"
                    title={t('PlanStopsControl.button.resequence')}
                    onClickHandler={() =>
                        onSwitchView(constants.stopsControl.views.RESEQUENCE)
                    }
                    shouldRender
                />
            </ManageStopControlMenu>
            <div className="_d-flex _ai-center">
                <span className="_text-3-alt">
                    {t('PlanStopsControl.title.selected_stops_info')}
                </span>
            </div>
            <ManageStopControlMenu>
                <StopModalMenuButton
                    titleIcon="iconRouteFill"
                    title={t('PlanStopsControl.button.reassign')}
                    shouldRender
                    borderBottom
                    disabled={isLiveStopControl}
                    onClickHandler={() =>
                        onSwitchView(constants.stopsControl.views.REASSIGN)
                    }
                />
                {isLiveStopControl && (
                    <StopModalMenuButton
                        disabled
                        titleIcon="iconRouteless"
                        title={t('PlanStopsControl.button.cancel')}
                    />
                )}
                {!isLiveStopControl && (
                    <StopModalMenuButton
                        disabled={!isUnassignStopsAllowed}
                        onClickHandler={unassignStops}
                        titleIcon="iconRouteless"
                        title={t('PlanStopsControl.button.unassign')}
                    />
                )}
            </ManageStopControlMenu>
        </ManageStopControlMultiple>
    );
}

export default MultiStopsControl;
