import _ from 'lodash';
import constants from '~/utils/constants';
import dateUtils from '~/utils/date-utils';

/**
 * LiveStop data class
 *
 * @category Data Classes
 *
 * @example
 * import LiveStop from '~/data-classes/dispatched/LiveStop';
 *
 * const srcData = {};
 * const liveStop = new LiveStop(srcData);
 */
class LiveStop {
    /**
     * The constructor description
     * @param {Object} liveStop - the source live stop data
     */
    constructor(liveStop) {
        this._liveStop = liveStop;
    }

    get id() {
        return this._liveStop.id;
    }

    get name() {
        return this._liveStop.name;
    }

    /* alias name */
    get stopName() {
        return this._liveStop.name;
    }

    get status() {
        return this._liveStop.status;
    }

    get delay() {
        return this._liveStop.delay;
    }

    get hasInventoryException() {
        return this._liveStop.hasInventoryException;
    }

    get type() {
        return this._liveStop.type;
    }

    get isDepot() {
        return this._liveStop.isDepot;
    }

    get isPickup() {
        return this.type === constants.taskTypes.PICKUP;
    }

    get isDelivery() {
        return this.type === constants.taskTypes.DELIVERY;
    }

    get isHighPriority() {
        return this._liveStop.priority === constants.priorityCodes.HIGH;
    }

    get arrivalTime() {
        return this._liveStop.arrivalTime;
    }

    get serviceTime() {
        return this._liveStop.serviceTime;
    }

    get isMLServiceTime() {
        return this._liveStop.serviceTimeML;
    }

    get completedAt() {
        return this._liveStop.completedAt;
    }

    get address() {
        return _.pick(this._liveStop.location, [
            'addressLine1',
            'addressLine2',
            'city',
            'state',
            'zipcode'
        ]);
    }

    get location() {
        return this._liveStop.location.location;
    }

    get labels() {
        return this._liveStop.labels;
    }

    get serviceWindow() {
        return dateUtils.getShiftTime(this.arrivalTime, this.serviceTime);
    }

    get isEarly() {
        return this.delay === constants.delayCodes.EARLY;
    }

    get isOnTime() {
        return this.delay === constants.delayCodes.ON_TIME;
    }

    get isAtRisk() {
        return this.delay === constants.delayCodes.AT_RISK;
    }

    get isLate() {
        return this.delay === constants.delayCodes.LATE;
    }

    get isCompleted() {
        return this._liveStop.isCompleted;
    }

    get isAssigned() {
        return this.status === constants.assignmentCodes.ASSIGNED;
    }

    get isInProgress() {
        return this.status === constants.assignmentCodes.IN_PROGRESS;
    }

    get isCanceled() {
        return this.status === constants.assignmentCodes.CANCELED;
    }

    get taskId() {
        return this._liveStop.task;
    }

    toJSON() {
        return this._liveStop;
    }
}

export default LiveStop;
