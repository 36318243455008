import axios from 'axios';
import constants from '~/utils/constants';

export default {
    path: '/workflow_events',
    /**
     @param {{name: string, route_date: string}} data
     */
    post(data) {
        return axios.post(this.path, data, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    },
    /**
     @param {{route_date: string[], page: number, limit: number}} params
     */
    get(params) {
        const defaultPagination = { page: 0, limit: 2500 };

        params = { ...defaultPagination, ...params };

        if (!params.route_date) {
            return Promise.reject('missing route_date querystring parameters');
        }

        return axios.get(this.path, {
            params,
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }
};
