import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import PT from 'prop-types';

import { Icon } from '~/ui';

import './TimeInput.scss';

function TimeInput({
    value,
    placeholder,
    onChange,
    onFocus,
    disabled,
    variant = 'table',
    isEmptyValid = false,
    className
}) {
    const [isValidTime, setValidTime] = useState(true);

    useEffect(() => {
        if (!value) {
            if (isEmptyValid) {
                setValidTime(true);
            }
            return;
        }
        const datetime = DateTime.fromFormat(value, 'T');
        if (!datetime.isValid) {
            setValidTime(false);
        } else {
            setValidTime(true);
        }
    }, [value]);

    function getClassNames() {
        const errorClassName = isValidTime ? '' : 'timeinput_error';
        let variantClassName = '';
        switch (variant) {
            case 'form':
                variantClassName = 'timeinput_form';
                break;
            case 'table':
            default:
                variantClassName = 'timeinput_table';
        }
        return `timeinput ${variantClassName} ${errorClassName}`;
    }

    function onInputChange(e) {
        return onChange && onChange(e.target.value);
    }

    return (
        <div className={`_d-flex _ai-center _w-100 ${className}`}>
            <input
                type="time"
                className={getClassNames()}
                placeholder={placeholder}
                value={value}
                onChange={onInputChange}
                onFocus={onFocus}
                disabled={disabled}
            />
            {variant === 'table' && (
                <Icon
                    className="timeinput-icon"
                    icon="calendar"
                    color={isValidTime ? 'galaxy-600' : 'mars-500'}
                    disabled={disabled}
                />
            )}
        </div>
    );
}

export default TimeInput;

TimeInput.propTypes = {
    value: PT.string,
    placeholder: PT.string,
    onChange: PT.func,
    onFocus: PT.func,
    disabled: PT.bool,
    variant: PT.oneOf(['table', 'form']),
    isEmptyValid: PT.bool,
    className: PT.string
};
