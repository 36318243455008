import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';

/**
 * Redux slice for various map drawer-related settings
 * @category Reducers
 * @module mapSettingsSlice
 *
 * @todo move some drawer-related settings in `mapSettingsSlice` to this one
 */

/** @typedef {import('@reduxjs/toolkit').PayloadAction} PayloadAction */

/**
 * default slice state
 */
export const defaultDrawerSettings = {
    showDrawerOnDemandDispatch: false,
    showIsOpenSelectedCardsDrawer: false,
    showIsOpenSuggestCardsDrawer: false
};

export const mapDrawerSettingsSlice = createSlice({
    name: 'mapDrawerSettings',
    initialState: defaultDrawerSettings,
    reducers: {
        /**
         * set the whether the on-demand dispatch drawer is displayed
         * @method setShowDrawerOnDemandDispatch
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {Boolean} action.payload - the reducer's payload
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowDrawerOnDemandDispatch } from '~/reducers/mapDrawerSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // set markers to fit to bounds
         * const dispatch = useDispatch();
         * dispatch(setShowDrawerOnDemandDispatch(true));
         */
        setShowDrawerOnDemandDispatch: (state, action) => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            return { ...state, showDrawerOnDemandDispatch: action.payload };
        },

        setIsOpenSelectedCardsDrawer: (state, action) => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            return { ...state, showIsOpenSelectedCardsDrawer: action.payload };
        },
        setIsSuggestDriversDrawer: (state, action) => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            return { ...state, showIsOpenSuggestCardsDrawer: action.payload };
        },

        /**
         * reset map drawers slice to initial state
         * @returns {Object} the initial state
         * @example <caption>Usage</caption>
         * // import statement
         * import { resetMapDrawerSettings } from '~/reducers/mapDrawerSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // reset back to initial state
         * const dispatch = useDispatch();
         * dispatch(resetMapDrawerSettings());
         */
        resetMapDrawerSettings: () => {
            return defaultDrawerSettings;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return defaultDrawerSettings;
        });
    }
});

export const {
    setShowDrawerOnDemandDispatch,
    setIsOpenSelectedCardsDrawer,
    setIsSuggestDriversDrawer,
    resetMapDrawerSettings
} = mapDrawerSettingsSlice.actions;

/**
 * selects the current global map setting whether the map should fit markers to map bounds
 * @method
 * @returns {Boolean} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowDrawerOnDemandDispatch } from '~/reducers/mapDrawerSettingsSlice';
 * import { useSelector } from 'react-redux';
 *
 * // get the drawer setting
 * const showDrawer = useSelector(selectShowDrawerOnDemandDispatch);
 */

export const selectShowDrawerOnDemandDispatch = (state) =>
    state.mapDrawerSettings.showDrawerOnDemandDispatch;

export const selectIsOpenSuggestDrawer = (state) =>
    state.mapDrawerSettings.showIsOpenSuggestCardsDrawer;

export const selectIsOpenSelectedCardsDrawer = (state) =>
    state.mapDrawerSettings.showIsOpenSelectedCardsDrawer;

export default mapDrawerSettingsSlice.reducer;
