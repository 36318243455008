function makePolygon(mapsAPI, hull, color) {
    return new mapsAPI.Polygon({
        paths: hull,
        strokeColor: color,
        strokeOpacity: 1,
        strokeWeight: 1,
        fillColor: color,
        fillOpacity: 0.3
    });
}
function drawMapObject(mapObject, mapInstance) {
    mapObject.setMap(mapInstance);
}
function removeMapObject(mapObject) {
    mapObject.setMap(null);
}
function getMapObjectFromAll(allMapObjects, id) {
    return allMapObjects[id];
}

function drawAllMapObjects(mapInstance, allObjects) {
    for (const id in allObjects) {
        const mapObject = getMapObjectFromAll(allObjects, id);
        drawMapObject(mapObject, mapInstance);
    }
}

function removeAllMapObjects(allMapObjects) {
    for (const id in allMapObjects) {
        const mapObject = getMapObjectFromAll(allMapObjects, id);
        removeMapObject(mapObject);
    }
}

function highlightPolygon(polygon) {
    polygon.setOptions({
        fillOpacity: 0.6
    });
}
function unHighlightPolygon(polygon) {
    polygon.setOptions({
        fillOpacity: 0.3
    });
}
function emphasizePolygon(isShowingRoutePolygons, polygon, mapInstance) {
    if (isShowingRoutePolygons) {
        highlightPolygon(polygon);
    } else {
        drawMapObject(polygon, mapInstance);
    }
}
function unEmphasizePolygon(isShowingRoutePolygons, polygon) {
    if (isShowingRoutePolygons) {
        unHighlightPolygon(polygon);
    } else {
        removeMapObject(polygon);
    }
}

export const mapDrawingUtils = {
    makePolygon,
    drawMapObject,
    removeMapObject,
    getMapObjectFromAll,
    drawAllMapObjects,
    removeAllMapObjects,
    highlightPolygon,
    unHighlightPolygon,
    emphasizePolygon,
    unEmphasizePolygon
};
