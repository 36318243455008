import { useSelector } from 'react-redux';
import {
    selectMapMarkerMode,
    selectMapRouteMode
} from '~/reducers/mapSettingsSlice';
import constants from '~/utils/constants';

export const useMapUtils = () => {
    const mapMarkerMode = useSelector(selectMapMarkerMode);
    const mapRouteMode = useSelector(selectMapRouteMode);

    const isStopsClustersMode =
        mapMarkerMode === constants.mapMarkerModes.STOPS_CLUSTERS;

    const isRoutesMode = mapMarkerMode === constants.mapMarkerModes.ROUTES;

    const isOnDemandDispatchMode = [
        constants.mapRouteModes.PLAN,
        constants.mapRouteModes.DISPATCHED
    ].includes(mapRouteMode);

    const isLiveRouteMode = [
        constants.mapRouteModes.COMPLETED,
        constants.mapRouteModes.DISPATCHED
    ].includes(mapRouteMode);

    const isEmptyRouteMode = mapRouteMode === constants.mapRouteModes.EMPTY;
    const isPlanRouteMode = mapRouteMode === constants.mapRouteModes.PLAN;
    const isDispatchedRouteMode =
        mapRouteMode === constants.mapRouteModes.DISPATCHED;

    const isCompletedRouteMode =
        mapRouteMode === constants.mapRouteModes.COMPLETED;

    return {
        isStopsClustersMode,
        isPlanRouteMode,
        isOnDemandDispatchMode,
        isLiveRouteMode,
        isDispatchedRouteMode,
        isCompletedRouteMode,
        mapMarkerMode,
        mapRouteMode,
        isRoutesMode,
        isEmptyRouteMode
    };
};
