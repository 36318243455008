import React, { useCallback, useState } from 'react';
import { Modal } from '../components';

export function useModal() {
    const [isVisible, setIsVisible] = useState(false);

    const showModal = useCallback(() => setIsVisible(true), [setIsVisible]);
    const hideModal = useCallback(() => setIsVisible(false), [setIsVisible]);

    const modal = useCallback(
        ({
            children,
            hasCustomHeader,
            hasAutoHide,
            hasCloseButton,
            title,
            variant,
            className,
            overlayClassName
        }) => (
            <>
                {isVisible && (
                    <Modal
                        hasCustomHeader={hasCustomHeader}
                        hasAutoHide={hasAutoHide}
                        hasCloseButton={hasCloseButton}
                        title={title}
                        onHide={hideModal}
                        variant={variant}
                        className={className}
                        overlayClassName={overlayClassName}
                    >
                        {children}
                    </Modal>
                )}
            </>
        ),
        [isVisible]
    );

    return {
        showModal,
        hideModal,
        modal,
        isVisible
    };
}
