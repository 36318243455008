export function validatePermissions(
    userGroups,
    feature,
    role,
    extraPermissions = false
) {
    return userGroups.some((userGroup) => {
        return userGroup.client_access.some((curClient) => {
            return curClient.permission.some(
                (permission) =>
                    permission.feature === feature &&
                    (permission.role === role || extraPermissions)
            );
        });
    });
}
