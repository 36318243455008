import React from 'react';
import classNames from 'classnames';

import './manage-stop-control-modal.scss';
import { Icon, Text, TooltipButton } from '~/ui';

function ManageStopControlModal({
    modalTitle,
    onClickTitle = () => {},
    children,
    className
}) {
    function _getClassName() {
        const defaultClassName = `managestopcontrol_modal _fd-column`;
        return classNames(defaultClassName, className);
    }

    return (
        <div className={_getClassName()}>
            {modalTitle && (
                <div className="managestopcontrol_modal-header">
                    <TooltipButton
                        onClick={onClickTitle}
                        className="button_clear"
                    >
                        <Icon
                            icon="chevronDown"
                            color="galaxy-800"
                            className="_t-rotate-90"
                        />
                        <Text
                            className="_text-2"
                            variant="14-normal"
                            color="galaxy-800"
                        >
                            {modalTitle}
                        </Text>
                    </TooltipButton>
                </div>
            )}
            <div className="managestopcontrol_modal-content _d-flex _fd-column">
                {children}
            </div>
        </div>
    );
}

export { ManageStopControlModal };
