import { setCepResultsByClient } from '~/reducers/cepResultsSlice';
import { getAllTasks } from '~/reducers/tasksSlice';
import { store } from '~/store';
import { removePendingJob } from '~/reducers/pendingJobsSlice';
import { planEditMetricsService } from '~/services/planEditMetricsService';
import { notifyReceivedWebSolution } from '~/reducers/detectWebSolutionSlice';
import throttleRequestsHandler from '~/utils/socket/throttle-requests';
import dateUtils from '~/utils/date-utils';
import i18n from '~/i18n';
import { addToast } from '~/reducers/toastsSlice';
import { resetSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';
import {
    addOnDemandDispatchTask,
    removeOnDemandDispatchTask
} from '~/reducers/onDemandDispatchTaskCountSlice';
import { webMulticlientAPI } from '~/api/web-multiclient';

function _getRouteDateFromWebSolution(solution) {
    return solution.data.wiseRoutes.routeDate;
}

function handleWebSolution(payload) {
    const storeState = store.getState();
    const jobId = payload.data.job;
    if (storeState.pendingJobs && storeState.pendingJobs[jobId]) {
        store.dispatch(removePendingJob(jobId));
        store.dispatch(notifyReceivedWebSolution());
    } else {
        // TODO: https://wisesys.atlassian.net/browse/MCW-115 [DanCross 2021-11-10]
        const taskRouteDate = _getRouteDateFromWebSolution(payload.data);
        const routeDate = dateUtils.convertToISODateOnly(
            storeState.selectedDate
        );
        if (routeDate === taskRouteDate) {
            store.dispatch(notifyReceivedWebSolution());
            throttleRequestsHandler.clearRequestThrottle();
        }
    }
}

function handleCepResults(payload) {
    store.dispatch(setCepResultsByClient(payload));
}

async function handleDirectRouteImpact(payload) {
    const storeState = store.getState();
    const { selectedDate } = storeState;
    if (!selectedDate) {
        return;
    }
    const routeDate = dateUtils.convertToISODateOnly(selectedDate);
    // check if clientId_schedulerTaskId matches up
    try {
        const clientData = storeState.planClientsLevelData;
        const clientTaskCount = clientData.reduce((result, client) => {
            if (client.clientId === payload.clientId) {
                result = client.stats.numTasks;
            }
            return result;
        }, 0);
        throttleRequestsHandler.setRequest(
            payload.clientId,
            routeDate,
            clientTaskCount
        );
        await planEditMetricsService.addMetricsToStore(
            payload.clientId,
            payload.data.schedulerTaskId
        );
    } catch (e) {
        console.warn(e);
    }
}

function handlePairingExecuted(payload) {
    const { pairing, unPairedDeliveryIds, unPairedPickupIds } =
        payload.data.pairing;

    const counts = {
        pairsCreatedCount: pairing.length,
        deliveryTasksRemainedCount: unPairedDeliveryIds?.length,
        pickupTasksRemainedCount: unPairedPickupIds?.length
    };

    store.dispatch(
        addToast({
            message: i18n.t('taskManagement:pairedUnassignedTasks', counts),
            variant: 'info'
        })
    );
    store.dispatch(getAllTasks({ routeDate: payload.date }));
    store.dispatch(resetSelectedTaskIds());
}

function _handleSingleOnDemandTaskChange(action, routeDate) {
    if (routeDate && !dateUtils.isToday(routeDate)) {
        return;
    }
    store.dispatch(action());
}

function handleOnDemandTaskAdded({ routeDate }) {
    _handleSingleOnDemandTaskChange(addOnDemandDispatchTask, routeDate);
}

function handleOnDemandTaskRemoved({ routeDate }) {
    _handleSingleOnDemandTaskChange(removeOnDemandDispatchTask, routeDate);
}

function handleOnDemandTaskAddedBulk() {
    const storeState = store.getState();
    const { activeClients } = storeState;
    // Results are expected via socket, no onfulfilled needed.
    webMulticlientAPI
        .get(activeClients)
        .then(null, (err) =>
            console.error('Unexpected error while getting web', err)
        );
}

export default {
    handleWebSolution,
    handleCepResults,
    handleDirectRouteImpact,
    handlePairingExecuted,
    handleOnDemandTaskAdded,
    handleOnDemandTaskAddedBulk,
    handleOnDemandTaskRemoved
};
