import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';
import dateUtils from '~/utils/date-utils';

export const selectedDateSlice = createSlice({
    name: 'selectedDate',
    initialState: null,
    reducers: {
        setSelectedDate: (state, action) => {
            const date = dateUtils.convertISODateToJsDate(action.payload);
            return dateUtils.convertJsDateToISODate(date);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return null;
        });
    }
});

// available selected date actions
export const { setSelectedDate } = selectedDateSlice.actions;

// selector of selectedState state
export const selectDate = (state) => state.selectedDate;
export const selectDateOnly = (state) =>
    dateUtils.convertToISODateOnly(state.selectedDate);

// selectedDate reducer
export default selectedDateSlice.reducer;
