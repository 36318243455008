import axios from 'axios';
import constants from '~/utils/constants';

export default {
    path: '/drivers',
    get() {
        return axios.get(this.path, {
            params: {
                extent: 'vehicle',
                limit: 1000
            },
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    },
    getLocationUpdates(driverIds, after) {
        if (!driverIds || driverIds.length === 0) {
            return Promise.reject('driver ids not provided');
        }

        return axios.get(`${this.path}/locations`, {
            params: {
                driver_ids: driverIds,
                after
            },
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }
};
