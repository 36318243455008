import React from 'react';
import { Icon } from '~/ui/';

import './driver-location-marker.scss';

function DriverLocationMarker({ color, direction = 0 }) {
    return (
        <button type="button" className="driverlocationmarker">
            <span
                className="driverlocationmarker-circle"
                style={{
                    backgroundColor: color
                }}
            >
                <Icon
                    icon="directionArrow"
                    className="driverlocationmarker-icon"
                    sx={{ transform: `rotate(${-90 + direction}deg)` }}
                />
                <span
                    className="driverlocationmarker-aura"
                    style={{
                        backgroundColor: color
                    }}
                />
            </span>
        </button>
    );
}

export default DriverLocationMarker;
