import { Task, OnDemandDispatchStop } from '~/data-classes';

export const taskDataFactory = {
    makeTask(taskData) {
        return new Task(taskData);
    },
    makeOnDemandDispatchStop(taskData) {
        return new OnDemandDispatchStop(taskData);
    },
    makeOnDemandDispatchSuggestion(
        driverData,
        tasksData,
        suggestionsData = []
    ) {
        // TODO: convert this into a data class
        const suggestion = {
            driver: { ...driverData },
            tasks: tasksData.reduce((tasks, task) => {
                tasks[task.id] = task;
                return tasks;
            }, {}),
            suggestions: [...suggestionsData]
        };

        return suggestion;
    }
};
