/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PT from 'prop-types';
import { usePopperTooltip } from 'react-popper-tooltip';
import { Grid } from 'theme-ui';
import { Box, FlexLayout, Icon, theme } from '~/ui';
import { useMergedRefs } from '~/ui/hooks';

const arrowSideLength = '0.6rem';

const tooltipStyles = {
    zIndex: theme.zIndex.tooltip,
    padding: '0.8rem 1.2rem',
    borderRadius: '0.4rem',
    backgroundColor: 'galaxy-700',
    color: 'galaxy-100',
    fontSize: '1.4rem',
    lineHeight: '133%',
    maxWidth: '24rem',
    '[data-popper-arrow*="true"]': {
        height: `calc(2 * ${arrowSideLength})`,
        width: `calc(2 * ${arrowSideLength})`,
        '&::after': {
            borderStyle: 'solid',
            content: '""',
            position: 'absolute',
            borderColor: 'transparent',
            borderWidth: `${arrowSideLength}`
        }
    },
    '&[data-popper-placement*="bottom"] [data-popper-arrow*="true"]': {
        height: arrowSideLength,
        marginTop: `calc(-${arrowSideLength} + 1px)`,
        top: 0,
        '&::after': {
            borderBottomColor: 'galaxy-700',
            borderTopWidth: 0
        }
    },
    '&[data-popper-placement*="top"] [data-popper-arrow*="true"]': {
        height: arrowSideLength,
        marginBottom: `calc(-${arrowSideLength} + 1px)`,
        bottom: 0,
        '&::after': {
            borderTopColor: 'galaxy-700',
            borderBottomWidth: 0
        }
    },
    '&[data-popper-placement*="left"] [data-popper-arrow*="true"]': {
        width: arrowSideLength,
        marginRight: `calc(-${arrowSideLength} + 1px)`,
        right: 0,
        '&::after': {
            borderLeftColor: 'galaxy-700',
            borderRightWidth: 0
        }
    },
    '&[data-popper-placement*="right"] [data-popper-arrow*="true"]': {
        width: arrowSideLength,
        marginLeft: `calc(-${arrowSideLength} + 1px)`,
        left: 0,
        '&::after': {
            borderRightColor: 'galaxy-700',
            borderLeftWidth: 0
        }
    },

    animation: theme.transitions.getStandardEasingTransition({
        name: 'entry-animation'
    }),
    '@keyframes entry-animation': {
        from: { opacity: 0 },
        to: { opacity: 1 }
    }
};

const gaps = { md: 8, sm: 4, xs: 0 };

const titleColors = {
    default: 'white',
    info: 'white',
    warning: 'mars-300'
};

const defaultIcons = {
    info: 'warning',
    warning: 'warningFill'
};

const Tooltip = React.forwardRef(
    (
        {
            children,
            leadingContent = null,
            title,
            placement,
            content,
            controls,
            variant = 'default',
            gapSize = 'md',
            trigger = 'hover',
            isVisible,
            disabled,
            sx,
            arrowProps,
            setRef
        },
        ref
    ) => {
        // prevent tooltip display when content is empty
        if (!content) isVisible = false;

        const {
            getArrowProps,
            getTooltipProps,
            setTooltipRef: setUsePopperTooltipRef,
            setTriggerRef,
            visible
        } = usePopperTooltip({
            placement,
            visible: disabled ? false : isVisible,
            offset: [0, 6 + gaps[gapSize]],
            trigger
        });

        const setTooltipRef = (newRef) => {
            setUsePopperTooltipRef(newRef);
            setRef?.(newRef);
        };

        const mergedRefs = useMergedRefs(setTriggerRef, ref);

        const titleColor = titleColors[variant];

        const reffedChild = children
            ? React.cloneElement(children, {
                  ref: mergedRefs
              })
            : null;

        return (
            <>
                {reffedChild}

                {visible && (
                    <FlexLayout
                        flexDirection="column"
                        sx={{
                            ...tooltipStyles,
                            ...sx
                        }}
                        ref={setTooltipRef}
                        {...getTooltipProps()}
                    >
                        <Grid columns={['auto 1fr']} gap="0">
                            {(leadingContent || defaultIcons[variant]) && (
                                <Grid
                                    item
                                    justify="center"
                                    sx={{
                                        maxWidth: '2rem',
                                        gridArea: '1/1',
                                        marginRight: '0.8rem',
                                        color: titleColor
                                    }}
                                >
                                    {leadingContent || (
                                        <Icon icon={defaultIcons[variant]} />
                                    )}
                                </Grid>
                            )}
                            {title && (
                                <Grid
                                    item
                                    sx={{
                                        marginBottom: '0.4rem',
                                        gridArea: '1/2',
                                        alignItems: 'center',
                                        fontWeight: 500,
                                        lineHeight: '2rem',
                                        color: titleColor
                                    }}
                                >
                                    {title}
                                </Grid>
                            )}
                            <Grid
                                item
                                sx={{
                                    gridArea: title ? '2/2' : '1/2/2/2',
                                    color: 'galaxy-200',
                                    lineHeight: '1.6rem'
                                }}
                            >
                                {content}
                            </Grid>
                        </Grid>

                        {controls}

                        <Box {...getArrowProps({ ...arrowProps })} />
                    </FlexLayout>
                )}
            </>
        );
    }
);

Tooltip.propTypes = {
    /** Determine placement position of the tooltip */
    placement: PT.oneOf(['top', 'bottom', 'left', 'right']),
    /** Determine trigger of the tooltip (default is `hover`) */
    trigger: PT.oneOf(['click', 'right-click', 'hover', 'focus']),
    /** Variant of the tooltip (default is `default`) */
    variant: PT.oneOf(['default', 'info', 'warning']),
    /** Gap between arrow and target (default is `md`) */
    gapSize: PT.oneOf(['md', 'sm', 'xs']),
    /** Content of the controls found at the bottom of the tooltip */
    controls: PT.node,
    /** Content of the main part of the tooltip */
    content: PT.node.isRequired,
    /** Content of the title part of the tooltip */
    title: PT.node,
    /** Content of the left part of the tooltip (typically an icon) */
    leadingContent: PT.node,
    /** A render function for the target on which the tooltip opens.
     *  Must set the provided ref on the element to work! */
    children: PT.element,
    /** Used to make controlled component */
    isVisible: PT.bool,
    /** Used to disable tooltip */
    disabled: PT.bool
};

export default Tooltip;
