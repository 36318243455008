import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';

const initialFilters = {};

export const selectedTaskFiltersSlice = createSlice({
    name: 'selectedTaskFilters',
    initialState: initialFilters,
    reducers: {
        setSelectedTaskFilters: (state, action) => {
            return action.payload;
        },
        resetSelectedTaskFilters: () => {
            return initialFilters;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return initialFilters;
        });
    }
});
export const { setSelectedTaskFilters, resetSelectedTaskFilters } =
    selectedTaskFiltersSlice.actions;
export const selectSelectedTaskFilters = (state) => state.selectedTaskFilters;
export default selectedTaskFiltersSlice.reducer;
