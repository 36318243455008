import i18n from 'i18next';
import unitsUtils from './unit-converter';
import dateUtilsConverters from './date-utils-converters';

function getTotalDistanceText(totalMeters) {
    const distance = unitsUtils.getLocalDistance(totalMeters);
    const units = unitsUtils.getLocalDistanceUnits();
    return i18n.t(units, { distance });
}

function formatDuration(duration) {
    const timeRemaining =
        dateUtilsConverters.convertMillisecondsToHoursAndMinutes(duration);

    return i18n.t('common:time.duration_hm', timeRemaining);
}

function formatUnitsAndWeight(unitsAndWeight) {
    const { numUnits, totalWeight } = unitsAndWeight;
    return i18n.t('unitsAndWeight', { numUnits, totalWeight });
}

function formatNumInventory(count) {
    return i18n.t('numInventoryItems', { count });
}

function formatNumStops(numStops) {
    return i18n.t('stopWithCount', { count: numStops });
}

function formatAddress(address) {
    if (!address) return;

    const { addressLine2 } = address;
    return addressLine2
        ? i18n.t('common:address.hasLine2', address)
        : i18n.t('common:address.default', address);
}

export default {
    getTotalDistanceText,
    formatDuration,
    formatUnitsAndWeight,
    formatNumInventory,
    formatNumStops,
    formatAddress
};
