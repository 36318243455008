import axios from 'axios';
import constants from '~/utils/constants';
import {
    AxiosApiResponse,
    IApiResponse,
    IPostDriverAssignmentsResponse
} from './types';

/**
 * POST /driver/<driverId>/assignments payload type
 * @category API
 */
interface TaskToDispatch {
    /**
     * Id of the task to dispatch
     */
    id: string;
    /**
     * Pickup time of the task to dispatch
     */
    pickupTime?: string;
    /**
     * Delivery time of the task to dispatch
     */
    deliveryTime?: string;
}

/**
 * Implementations of API methods under the /driver path
 *
 * @category API
 */
export class DriverApi {
    /**
     * Path of the API endpoint
     */
    private static readonly PATH = '/driver';

    /**
     * Sends a PATCH /drvier/<id> request with the given data for the given client.
     *
     * @param {string} driverId the driver to patch
     * @param {Record<string, unknown>} data the patch data
     * @param {string} clientId the target client
     * @returns {Promise<AxiosApiResponse>}
     * @todo write driver TS definition for response
     */
    static patch(
        driverId: string,
        data: Record<string, unknown>,
        clientId = ''
    ): Promise<AxiosApiResponse> {
        if (!driverId) {
            return Promise.reject('driver id not provided');
        }
        return axios.patch<IApiResponse>(`${this.PATH}/${driverId}`, data, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: clientId }
        });
    }

    /**
     * Sends a POST /driver/<driverId>/assignments requests to assign the given
     * tasks to the given driver.
     *
     * @param {string} driverId driver to assign tasks to
     * @param {Array<TaskToDispatch>} tasks ids of tasks to assign to driver
     * @returns {IPostDriverAssignmentsResponse}
     */
    static dispatchTasks(
        driverId: string,
        tasks: Array<TaskToDispatch>
    ): Promise<AxiosApiResponse<IPostDriverAssignmentsResponse>> {
        if (!driverId) {
            return Promise.reject('driver id not provided');
        }
        return axios.post<IApiResponse<IPostDriverAssignmentsResponse>>(
            `${this.PATH}/${driverId}/assignments`,
            { tasks },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    }

    /**
     * Sends a PATCH /driver/<driverId>/manual_sequence request to resequence the given
     * taskIds for the given driver.
     *
     * @param {string} driverId driver whose tasks should be resequenced
     * @param {Array<string>} taskIds ids of driver tasks to be resequenced
     * @returns {Promise<AxiosApiResponse>}
     */
    static resequenceTasks(
        driverId: string,
        taskIds: string[]
    ): Promise<AxiosApiResponse> {
        if (!driverId) {
            return Promise.reject('driver id not provided');
        }

        if (!taskIds || !Array.isArray(taskIds) || !taskIds.length) {
            return Promise.reject('taskIds not provided');
        }

        return axios.patch(
            `${this.PATH}/${driverId}/manual_sequence`,
            {
                order: taskIds
            },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    }
}
