import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SuggestApi } from '~/api/SuggestApi';

import { Icon, TextOverflowTooltip } from '~/ui';
import dateUtilsConverters from '~/utils/date-utils-converters';
import unitConverter from '~/utils/unit-converter';

export const suggestMetricTypes = {
    routeTime: {
        totalSuggestMetricName: 'newTotalRouteTime',
        inducedSuggestMetricName: 'inducedRouteTime',
        icon: 'clock2',
        transformTotal(value, t) {
            const hoursMinutes =
                dateUtilsConverters.convertMillisecondsToHoursAndMinutes(
                    value * 1000
                );
            if (!hoursMinutes.hours) {
                return t('common:time.minNoSpace', {
                    time: hoursMinutes.minutes
                });
            }
            return t('common:time.duration_hm', hoursMinutes);
        }
    },
    customCost: {
        totalSuggestMetricName: 'newTotalCost',
        inducedSuggestMetricName: 'inducedCost',
        icon: 'cost',
        transformTotal(value) {
            // Todo: use i18Next v21's currency formatting. Not available in i18Next 19.
            return `$${value.toFixed(2)}`;
        },
        transformInduced(value) {
            return `$${value.toFixed()}`;
        }
    },
    // The following are not currently supported by the API
    routeDistance: {
        totalSuggestMetricName: 'newTotalRouteDistance',
        inducedSuggestMetricName: 'inducedRouteDistance',
        icon: 'distance',
        transformTotal(value, t) {
            const translationKey = unitConverter.getLocalDistanceUnits();
            return t(translationKey, {
                distance: unitConverter.getLocalDistance(value)
            });
        }
    }
};

export default function SuggestedDriverMetric({
    metricType,
    orderedBy,
    totalValue,
    inducedValue
}) {
    if (!suggestMetricTypes[metricType]) {
        return null;
    }
    const { t } = useTranslation(['translation', 'common']);
    const {
        icon,
        transformTotal,
        transformInduced,
        totalSuggestMetricName,
        inducedSuggestMetricName
    } = suggestMetricTypes[metricType];

    const totalMetric = useMemo(
        () => transformTotal(totalValue, t),
        [totalValue, t]
    );
    const inducedMetric = useMemo(
        () =>
            transformInduced
                ? transformInduced(inducedValue, t)
                : transformTotal(inducedValue, t),
        [inducedValue, t]
    );

    return (
        <div className="suggesteddrivermetric">
            <div className="_d-flex _ai-center">
                <Icon icon={icon} size="s" />
                <span className="suggesteddrivermetric-label">
                    {t(`SuggestMetrics.${metricType}`)}
                </span>
            </div>
            <TextOverflowTooltip content={totalMetric} placement="bottom">
                <div
                    className="suggestdrivermetric-value _to-ellipsis"
                    data-highlight={
                        SuggestApi.getExplainMetricInResponseForOrderBy(
                            orderedBy
                        ) === totalSuggestMetricName
                    }
                >
                    {totalMetric}
                </div>
            </TextOverflowTooltip>
            <TextOverflowTooltip content={inducedMetric} placement="bottom">
                <div
                    className="suggestdrivermetric-value _to-ellipsis"
                    data-highlight={
                        SuggestApi.getExplainMetricInResponseForOrderBy(
                            orderedBy
                        ) === inducedSuggestMetricName
                    }
                >
                    + {inducedMetric}
                </div>
            </TextOverflowTooltip>
        </div>
    );
}
