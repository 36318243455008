import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    ManageStopControlMenu,
    ManageStopControlSingle,
    StopModalMenuButton
} from '~/ui';

import { useUnassignStops } from '~/hooks/useUnassignStops';
import constants from '~/utils/constants';

function SingleStopControl({
    stopData,
    onSwitchView,
    disableResequencing = false,
    footerButtonTitle,
    footerButtonIcon,
    isLiveStopControl,
    onClickFooterButton = () => {}
}) {
    const { t } = useTranslation('translation');
    const { unassignStops, isUnassignStopsAllowed } = useUnassignStops();

    return (
        <ManageStopControlSingle
            className="singlestopcontrol"
            stopData={stopData}
            footerButtonTitle={footerButtonTitle}
            footerButtonIcon={footerButtonIcon}
            onClickFooterButton={onClickFooterButton}
        >
            <ManageStopControlMenu>
                <StopModalMenuButton
                    titleIcon="iconReorder"
                    title={t('PlanStopsControl.button.resequence')}
                    onClickHandler={() =>
                        onSwitchView(constants.stopsControl.views.RESEQUENCE)
                    }
                    stopText={t('PlanStopsControl.button.stopText', {
                        stopIndex: stopData.stopNumber
                    })}
                    disabled={disableResequencing}
                    shouldRender
                    borderBottom
                />
                <StopModalMenuButton
                    titleIcon="iconRouteFill"
                    title={t('PlanStopsControl.button.reassign')}
                    shouldRender
                    borderBottom
                    disabled={isLiveStopControl}
                    onClickHandler={() =>
                        onSwitchView(constants.stopsControl.views.REASSIGN)
                    }
                />
                {isLiveStopControl && (
                    <StopModalMenuButton
                        disabled
                        titleIcon="iconRouteless"
                        title={t('PlanStopsControl.button.cancel')}
                    />
                )}
                {!isLiveStopControl && (
                    <StopModalMenuButton
                        disabled={!isUnassignStopsAllowed}
                        onClickHandler={unassignStops}
                        titleIcon="iconRouteless"
                        title={t('PlanStopsControl.button.unassign')}
                    />
                )}
            </ManageStopControlMenu>
        </ManageStopControlSingle>
    );
}

export default SingleStopControl;
