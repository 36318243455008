import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Tab, TabList, Tabs } from 'react-tabs';
import classNames from 'classnames';
import {
    Drawer,
    DrawerBody,
    DrawerButtonGroup,
    DrawerHeader,
    IconButton,
    Detail,
    Button,
    useModal
} from '~/ui';
import TaskDetailDrawerItem from '~/components/TaskManagementPage/TaskDetailDrawer/TaskDetailDrawerItem';
import TaskStatus from '~/components/TaskManagementPage/TaskStatus';
import TaskMapMarker from '~/components/TaskManagementPage/TaskDetailDrawer/TaskMap/TaskMapMarker/TaskMapMarker';
import EditableText from '~/ui/components/EditableText/EditableText';
import InventoryItem from '~/components/TaskManagementPage/TaskDetailDrawer/InventoryItem/InventoryItem';
import DeleteTasksModalContent from '~/components/TaskManagementPage/DeleteTasksModalContent/DeleteTasksModalContent';
import TaskMap from '~/components/TaskManagementPage/TaskDetailDrawer/TaskMap/TaskMap';
import EditTimeWindowModal from '~/components/TaskManagementPage/EditTimeWindowModal';
import EditTaskAddressModal from '~/components/TaskManagementPage/EditTaskAddressModal';
import ProofOfDelivery from '~/components/TaskManagementPage/TaskDetailDrawer/ProofOfDelivery';

import { selectTaskById, updateTaskProperty } from '~/reducers/tasksSlice';
import { addToast } from '~/reducers/toastsSlice';
import inventoryItemsApi from '~/api/inventory-items';
import tasksApi from '~/api/tasks';
import constants from '~/utils/constants';
import taskUtils from '~/utils/task-utils';

import './TaskDetailDrawer.scss';
import TaskHistory from '~/components/TaskManagementPage/TaskDetailDrawer/TaskHistory/TaskHistory';
import { validatePermissions } from '~/utils/validatePermissions';
import { selectUserGroupsForCurrentUser } from '~/reducers/userGroupsForCurrentUserSlice';

function TaskDetailDrawer({
    id,
    handleTaskDetailDrawerClose,
    shouldRenderMapPageStyle,
    isViewOnly
}) {
    if (!id) {
        return null;
    }
    const { t } = useTranslation(['taskManagement', 'common', 'error']);
    const editTimeWindowModalRef = useRef();
    const editAddressModalRef = useRef();
    const task = useSelector((state) => selectTaskById(state, id));
    const isTwoPartTask = taskUtils.checkIsTwoPartTask(task);
    const isDelivery = taskUtils.checkIsDeliveryTask(task);
    const isPickup = taskUtils.checkIsPickupTask(task);
    const [tabIndex, setTabIndex] = useState(0);
    const [inventoryItems, setInventoryItems] = useState([]);
    const [loadingInventory, setLoadingInventory] = useState(false);
    const [isEditTimeWindowOpen, setEditTimeWindowOpen] = useState(false);
    const [isEditAddressModalOpen, setEditAddressModalOpen] = useState(false);
    const associatedUserGroups = useSelector(selectUserGroupsForCurrentUser);
    const dispatch = useDispatch();

    const hasEditPermissions = isViewOnly
        ? false
        : validatePermissions(
              associatedUserGroups,
              constants.userPermissionFeatures.TASK_MANAGEMENT,
              constants.userPermissionRoles.EDITOR
          );
    let filteredTask;
    const {
        showModal: showDeleteTasksModal,
        hideModal: hideDeleteTasksModal,
        modal: DeleteTasksModal
    } = useModal();

    if (!isTwoPartTask) {
        if (isDelivery) {
            filteredTask = taskUtils.filterDeliveryTask(task, inventoryItems);
        }

        if (isPickup) {
            filteredTask = taskUtils.filterPickupTask(task, inventoryItems);
        }
    }

    if (isTwoPartTask) {
        if (tabIndex === 0) {
            filteredTask = taskUtils.filterPickupTask(task, inventoryItems);
        }

        if (tabIndex === 1) {
            filteredTask = taskUtils.filterDeliveryTask(task, inventoryItems);
        }
    }

    useEffect(() => {
        setLoadingInventory(true);
        (async () => {
            try {
                const result = await inventoryItemsApi.get({
                    task_id: id,
                    signed: true
                });
                setInventoryItems(result.data.data);
                setLoadingInventory(false);
            } catch (error) {
                console.error(error);
                dispatch(
                    addToast({
                        message: t('error:inventoryItemsFetchError'),
                        variant: 'error'
                    })
                );
            }
        })();
    }, [id]);

    function getTaskMapMarkers() {
        const taskMapMarkers = [];
        const isSelected = tabIndex === 0;
        if (isTwoPartTask) {
            taskMapMarkers.push(
                <TaskMapMarker
                    key={task.pickupLocation.location.lat}
                    lat={task.pickupLocation.location.lat}
                    lng={task.pickupLocation.location.lng}
                    isSelected={isSelected}
                />
            );
            taskMapMarkers.push(
                <TaskMapMarker
                    key={task.deliveryLocation.location.lat}
                    lat={task.deliveryLocation.location.lat}
                    lng={task.deliveryLocation.location.lng}
                    isSelected={!isSelected}
                />
            );
        } else {
            taskMapMarkers.push(
                <TaskMapMarker
                    key={filteredTask.locationLat}
                    lat={filteredTask.locationLat}
                    lng={filteredTask.locationLng}
                />
            );
        }
        return taskMapMarkers;
    }
    async function apiRequestToUpdate({ taskId, property, value }) {
        try {
            await tasksApi.update(taskId, {
                [property]: value
            });
            dispatch(updateTaskProperty({ taskId, property, value }));
        } catch (error) {
            console.error(error);
            dispatch(
                addToast({
                    message: t('error:taskEditError'),
                    variant: 'error'
                })
            );
        }
    }

    function updateCustomerName({ value }) {
        function getUpdatedPickupLocation() {
            const { pickupLocation } = task;
            const updatedLocation = {
                taskId: id,
                property: 'pickupLocation',
                value: { ...pickupLocation, name: value }
            };
            return updatedLocation;
        }

        function getUpdatedDeliveryLocation() {
            const { deliveryLocation } = task;
            const updatedLocation = {
                taskId: id,
                property: 'deliveryLocation',
                value: { ...deliveryLocation, name: value }
            };
            return updatedLocation;
        }

        let updatedLocation;
        if (
            (!isTwoPartTask && isDelivery) ||
            (isTwoPartTask && tabIndex === 1)
        ) {
            updatedLocation = getUpdatedDeliveryLocation();
        }

        if ((!isTwoPartTask && isPickup) || (isTwoPartTask && tabIndex === 0)) {
            updatedLocation = getUpdatedPickupLocation();
        }
        apiRequestToUpdate(updatedLocation);
    }

    function updateContactPhone({ value }) {
        function getUpdatedPickupContact() {
            const { pickupContact } = task;
            const updatedContact = {
                taskId: id,
                property: 'pickupContact',
                value: { ...pickupContact, phone: value }
            };
            return updatedContact;
        }

        function getUpdatedDeliveryContact() {
            const { deliveryContact } = task;
            const updatedContact = {
                taskId: id,
                property: 'deliveryContact',
                value: { ...deliveryContact, phone: value }
            };
            return updatedContact;
        }

        let updatedContact;
        if (
            (!isTwoPartTask && isDelivery) ||
            (isTwoPartTask && tabIndex === 1)
        ) {
            updatedContact = getUpdatedDeliveryContact();
        }

        if ((!isTwoPartTask && isPickup) || (isTwoPartTask && tabIndex === 0)) {
            updatedContact = getUpdatedPickupContact();
        }
        apiRequestToUpdate(updatedContact);
    }

    function updateContactEmail({ value }) {
        function getUpdatedPickupContact() {
            const { pickupContact } = task;
            const updatedContact = {
                taskId: id,
                property: 'pickupContact',
                value: { ...pickupContact, email: value }
            };
            return updatedContact;
        }

        function getUpdatedDeliveryContact() {
            const { deliveryContact } = task;
            const updatedContact = {
                taskId: id,
                property: 'deliveryContact',
                value: { ...deliveryContact, email: value }
            };
            return updatedContact;
        }
        let updatedContact;

        if (
            (!isTwoPartTask && isDelivery) ||
            (isTwoPartTask && tabIndex === 1)
        ) {
            updatedContact = getUpdatedDeliveryContact();
        }

        if ((!isTwoPartTask && isPickup) || (isTwoPartTask && tabIndex === 0)) {
            updatedContact = getUpdatedPickupContact();
        }
        apiRequestToUpdate(updatedContact);
    }

    function updateNotes({ index, value }) {
        const { props } = task;
        const newNote = { text: value };
        const notes = props.notes ? [...props.notes] : [];
        notes.splice(index, 1, newNote);
        const updatedNotes = {
            taskId: id,
            property: 'props',
            value: { ...props, notes }
        };
        apiRequestToUpdate(updatedNotes);
    }

    function deleteInventoryItem(itemIndex) {
        const updatedInventoryItems = [...inventoryItems];
        updatedInventoryItems.splice(itemIndex, 1);
        setInventoryItems(updatedInventoryItems);
    }

    function _closeDrawer() {
        handleTaskDetailDrawerClose();
    }

    function displayModalEditDetail(content, onClick) {
        let className = '';
        let isButtonDisabled = false;
        if (
            (task.taskStatus !== constants.taskStatus.UNASSIGNED &&
                task.taskStatus !== constants.taskStatus.PLANNED) ||
            !hasEditPermissions
        ) {
            className = 'taskdetaildrawer-item-button_disabled';
            isButtonDisabled = true;
        }

        return (
            <button
                type="button"
                className={`taskdetaildrawer-item-button ${className}`}
                onClick={isButtonDisabled ? null : onClick}
            >
                {content}
            </button>
        );
    }

    function displayTimeWindows(timeWindows) {
        const timeWindowsContent = timeWindows.map((tw, i) => {
            const key = `tw_${id}_${i}`;
            return <div key={key}>{tw}</div>;
        });

        return displayModalEditDetail(timeWindowsContent, () =>
            hasEditPermissions ? setEditTimeWindowOpen(true) : null
        );
    }

    function displayLabels(labels) {
        if (labels.length === 0) return t('common:N/A');
        return labels.map((label, i) => {
            const key = `label_${id}_${i}`;
            return (
                <div key={key} className="label">
                    {label}
                </div>
            );
        });
    }

    function displayNotes(notes) {
        return notes.map((note, i) => {
            const key = `note_${id}_${i}`;
            return (
                <div key={key} id={key}>
                    <EditableText
                        mainContent={note.text}
                        handleSubmit={updateNotes}
                        index={i}
                        isEditable={hasEditPermissions}
                    />
                </div>
            );
        });
    }

    function displayInventoryItems() {
        if (!inventoryItems.length) return null;
        return filteredTask.inventoryItems.map((item, i) => {
            const key = `item_${item.id}`;
            return (
                <InventoryItem
                    key={key}
                    item={item}
                    index={i}
                    onDelete={deleteInventoryItem}
                />
            );
        });
    }

    function _getClassName() {
        const defaultClassName = 'taskdetaildrawer';
        const conditionalClasses = {
            'taskdetaildrawer-mappage': shouldRenderMapPageStyle
        };
        return classNames(defaultClassName, conditionalClasses);
    }

    return (
        <Drawer className={_getClassName()}>
            <DrawerHeader className="taskdetaildrawer-header">
                <DrawerButtonGroup className="_jc-space-between taskdetaildrawer-header_sticky">
                    <div className="taskdetaildrawer-status">
                        <TaskStatus status={filteredTask.status} />
                    </div>
                    <IconButton
                        className="drawer-button"
                        icon="cancel"
                        iconColor="galaxy-800"
                        onClick={_closeDrawer}
                    />
                </DrawerButtonGroup>
            </DrawerHeader>
            <DrawerBody>
                <div className="taskdetaildrawer-item taskdetaildrawer-item_open">
                    <div className="heading-1 _mb-2">
                        {filteredTask.taskName}
                    </div>
                    <div className="sub-heading-1 _to-ellipsis _mb-3">
                        {filteredTask.taskAddress}
                    </div>
                    <TaskMap taskMapMarkers={getTaskMapMarkers()} />
                    <TaskHistory task={task} />
                </div>
                {isTwoPartTask && (
                    <Tabs
                        className="taskdetaildrawer-tabs"
                        selectedIndex={tabIndex}
                        onSelect={(index) => setTabIndex(index)}
                    >
                        <TabList>
                            <Tab>{t('taskManagement:drawer.pickup')}</Tab>
                            <Tab>{t('taskManagement:drawer.delivery')}</Tab>
                        </TabList>
                    </Tabs>
                )}
                <TaskDetailDrawerItem title={t('drawer.taskDetail')}>
                    <Detail
                        iconName="userLine"
                        iconSize="s"
                        iconColor="galaxy-500"
                        iconClassName="taskdetaildrawer-item-icon"
                        className="_d-flex _ai-flex-start"
                        mainContent={
                            <EditableText
                                mainContent={filteredTask.customerName}
                                handleSubmit={updateCustomerName}
                                isEditable={hasEditPermissions}
                            />
                        }
                    />
                    <Detail
                        iconName="call"
                        iconSize="s"
                        iconColor="galaxy-500"
                        iconClassName="taskdetaildrawer-item-icon"
                        className="_d-flex _ai-flex-start"
                        mainContent={
                            <EditableText
                                mainContent={filteredTask.contactPhone}
                                handleSubmit={updateContactPhone}
                                isEditable={hasEditPermissions}
                            />
                        }
                    />
                    <Detail
                        iconName="message"
                        iconSize="s"
                        iconColor="galaxy-500"
                        iconClassName="taskdetaildrawer-item-icon"
                        className="_d-flex _ai-flex-start"
                        mainContent={
                            <EditableText
                                mainContent={filteredTask.contactEmail}
                                handleSubmit={updateContactEmail}
                                isEditable={hasEditPermissions}
                            />
                        }
                    />
                    <Detail
                        iconName="pin"
                        iconSize="s"
                        iconColor="galaxy-500"
                        iconClassName="taskdetaildrawer-item-icon"
                        className="_d-flex _ai-flex-start"
                        mainContent={displayModalEditDetail(
                            filteredTask.customerAddress,
                            () => setEditAddressModalOpen(true)
                        )}
                    />
                    <Detail
                        iconName="clock"
                        iconSize="s"
                        iconColor="galaxy-500"
                        iconClassName="taskdetaildrawer-item-icon"
                        className="_d-flex _ai-flex-start"
                        mainContent={displayTimeWindows(
                            filteredTask.timeWindows
                        )}
                    />
                    <Detail
                        iconName="taskLine"
                        iconSize="s"
                        iconColor="galaxy-500"
                        iconClassName="taskdetaildrawer-item-icon"
                        className="_d-flex _ai-flex-start"
                        mainContent={
                            <div
                                className="_d-flex _fw-wrap _p-relative"
                                style={{
                                    rowGap: '0.8rem',
                                    columnGap: '0.8rem',
                                    padding: '0.6rem 0.8rem',
                                    bottom: '0.6rem',
                                    cursor: 'default'
                                }}
                            >
                                {displayLabels(filteredTask.labels)}
                            </div>
                        }
                    />
                    <Detail
                        iconName="stopDetails"
                        iconSize="s"
                        iconClassName="taskdetaildrawer-item-icon"
                        className="_d-flex _ai-flex-start"
                        mainContent={displayNotes(filteredTask.notes)}
                    />
                </TaskDetailDrawerItem>
                <TaskDetailDrawerItem
                    title={t('drawer.inventoryItems', {
                        total: filteredTask.inventoryItems.length
                    })}
                >
                    {!loadingInventory && displayInventoryItems(inventoryItems)}
                </TaskDetailDrawerItem>
                <TaskDetailDrawerItem title={t('proof.title')}>
                    {!loadingInventory && (
                        <ProofOfDelivery
                            inventoryItems={filteredTask.inventoryItems}
                        />
                    )}
                </TaskDetailDrawerItem>
                {hasEditPermissions && (
                    <div className="taskdetaildrawer-item taskdetaildrawer-item_open">
                        <div className="taskdetaildrawer-item-body">
                            <Button
                                variant="secondary-dismissive"
                                onClick={showDeleteTasksModal}
                                style={{ width: '100%' }}
                            >
                                {t('common:delete')}
                            </Button>
                        </div>
                    </div>
                )}
            </DrawerBody>
            <DeleteTasksModal variant="dialogue" hasCustomHeader>
                <DeleteTasksModalContent
                    hideModal={hideDeleteTasksModal}
                    selectedTaskIds={[id]}
                    hideOther={handleTaskDetailDrawerClose}
                />
            </DeleteTasksModal>
            {isEditTimeWindowOpen && (
                <EditTimeWindowModal
                    ref={editTimeWindowModalRef}
                    taskId={id}
                    isDelivery={isTwoPartTask ? Boolean(tabIndex) : isDelivery}
                    onHide={() => setEditTimeWindowOpen(false)}
                    onDateUpdate={handleTaskDetailDrawerClose}
                />
            )}
            {isEditAddressModalOpen && (
                <EditTaskAddressModal
                    ref={editAddressModalRef}
                    taskId={id}
                    isDelivery={isTwoPartTask ? Boolean(tabIndex) : isDelivery}
                    onHide={() => setEditAddressModalOpen(false)}
                />
            )}
        </Drawer>
    );
}

export default TaskDetailDrawer;
