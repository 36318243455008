import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Text } from '~/ui';
import { replaceSelectedMapStop } from '~/reducers/selectedMapStopsSlice';

import { ResequenceModalSearch } from './ResequenceModalSearch';
import { ResequenceModalButtonGroup } from './ResequenceModalButtonGroup';
import ResequenceModalStopList from './ResequenceModalStopList';

import { mapPlanStopUtils } from '~/utils/map/map-planstop-utils';

export const ResequenceModalContent = ({
    selectedStops,
    unselectedStops,
    onResequence,
    stopIdPropertyName,
    isLiveStopControl,
    isDifferentRoutes = false
}) => {
    const dispatch = useDispatch();

    const [selectedStopId, setSelectedStopId] = useState(null);
    const [dragStartIndex, setDragStartIndex] = useState(null);

    const { t } = useTranslation('translation');
    const headerTitle = t('ResequenceModal.Content.title');

    const isShowButtonGroup = selectedStops.length === 1;
    const _handleDrop = (e, index) => {
        const newPlanStopsArray = mapPlanStopUtils.processStopsReorder(
            e,
            selectedStops,
            stopIdPropertyName,
            dragStartIndex,
            index
        );
        dispatch(replaceSelectedMapStop(newPlanStopsArray));
    };

    return (
        <div className="resequencemodal-content">
            <div className="stop-list">
                {selectedStops.map((planStop, index) => (
                    <ResequenceModalStopList
                        key={planStop[stopIdPropertyName]}
                        stopIdPropertyName={stopIdPropertyName}
                        selectedStop={planStop}
                        handleDragStart={() => setDragStartIndex(index)}
                        handleDragOver={(e) => e.preventDefault()}
                        handleDrop={(e) => _handleDrop(e, index)}
                    />
                ))}
            </div>
            <div className="resequencemodal-content-search">
                <Text
                    className="_text-3"
                    color="galaxy-800"
                    variant="12-normal"
                >
                    {headerTitle}
                </Text>
                <ResequenceModalSearch
                    selectedStopId={selectedStopId}
                    setSelectedStopId={setSelectedStopId}
                    stops={unselectedStops}
                    stopIdPropertyName={stopIdPropertyName}
                />
                <Button
                    onClick={() => onResequence(selectedStopId)}
                    disabled={isDifferentRoutes || !selectedStopId}
                    className="resequencemodal-content-button"
                >
                    <div className="_ai-center _jc-center">
                        <Icon icon="iconReorder" color="white" />
                        <Text
                            className="_text-3"
                            color="white"
                            variant="12-normal"
                        >
                            {t('PlanStopsControl.button.resequence')}
                        </Text>
                    </div>
                </Button>
                {isLiveStopControl && (
                    <div className="resequencemodal-content-button_disclaimer _text-3">
                        {t('PlanStopsControl.button.disclaimer')}
                    </div>
                )}
            </div>
            {isShowButtonGroup && (
                <ResequenceModalButtonGroup
                    selectedStops={selectedStops}
                    unselectedStops={unselectedStops}
                    stopIdPropertyName={stopIdPropertyName}
                    onResequence={onResequence}
                />
            )}
        </div>
    );
};
