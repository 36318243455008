import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { RouteCardText } from '~/ui';

function RouteCardEta({ stopData, icon }) {
    const [timeWindow, setTimeWindow] = useState();

    const { t } = useTranslation('common');

    useEffect(() => {
        if (!stopData || stopData.isCanceled) {
            return setTimeWindow();
        }

        const {
            arrivalTime,
            completedAt,
            serviceWindow,
            isUnassigned,
            isCompleted,
            isInProgress
        } = stopData;

        if (isCompleted && completedAt) {
            const completedTime = DateTime.fromISO(completedAt).toFormat('t');
            return setTimeWindow(completedTime);
        }

        if (isUnassigned && serviceWindow) {
            const estimatedTimeWindow = t('time.timeWindow', {
                start: DateTime.fromISO(serviceWindow.start).toFormat('t'),
                end: DateTime.fromISO(serviceWindow.end).toFormat('t')
            });
            return setTimeWindow(estimatedTimeWindow);
        }

        if (isInProgress && serviceWindow) {
            const estimatedTimeWindow = t('time.estimatedTimeWindow', {
                start: serviceWindow.start,
                end: serviceWindow.end
            });
            return setTimeWindow(estimatedTimeWindow);
        }

        const _arrivalTime = Date.parse(arrivalTime);
        const estimatedTime = t('time.estimatedTime', {
            time: Number.isNaN(_arrivalTime)
                ? arrivalTime
                : DateTime.fromISO(arrivalTime).toFormat('t')
        });
        return setTimeWindow(estimatedTime);
    }, [stopData]);

    return (
        <RouteCardText className="routecard-eta" icon={icon}>
            {timeWindow}
        </RouteCardText>
    );
}

export default RouteCardEta;
