import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    ManageStopControlMenu,
    ManageStopControlSingle,
    StopModalMenuButton
} from '~/ui';

import constants from '~/utils/constants';

function SingleTasksControl({
    taskData,
    onClickDispatchButton = () => {},
    onClickPairButton = () => {},
    footerButtonTitle,
    footerButtonIcon,
    onClickFooterButton = () => {}
}) {
    const { t } = useTranslation('translation');

    function _handleClickDispatch() {
        return onClickDispatchButton && onClickDispatchButton();
    }

    function _handleClickPair() {
        return onClickPairButton && onClickPairButton();
    }

    return (
        <ManageStopControlSingle
            className="singlestopcontrol"
            stopData={taskData}
            footerButtonTitle={footerButtonTitle}
            footerButtonIcon={footerButtonIcon}
            onClickFooterButton={onClickFooterButton}
        >
            <ManageStopControlMenu>
                <StopModalMenuButton
                    titleIcon="vehicleEmpty"
                    title={t('UnassignedTasksControl.button.dispatch')}
                    onClickHandler={_handleClickDispatch}
                    stopText={t('UnassignedTasksControl.button.tasksSelected', {
                        count: 1,
                        total: constants.onDemandDispatch.CONTROL_DISPATCH_LIMIT
                    })}
                    shouldRender
                />
                <StopModalMenuButton
                    titleIcon="link"
                    title={t('UnassignedTasksControl.button.pair')}
                    onClickHandler={_handleClickPair}
                    shouldRender
                />
            </ManageStopControlMenu>
        </ManageStopControlSingle>
    );
}

export default SingleTasksControl;
