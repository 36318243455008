import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { Alert, CollapsibleCard, Icon, RouteCardMarkerNoButton } from '~/ui';
import SuggestedDriverMetric, {
    suggestMetricTypes
} from './SuggestedDriverMetric';
import generalUtils from '~/utils/general-utils';
import { selectLiveDriverById } from '~/reducers/liveDriversSlice';

import './suggesteddrivercard.scss';

export function SuggestedDriverCard({
    suggestedDriverData,
    clientDriverId,
    driverColors,
    orderedBy,
    isBestDriver,
    isOpen,
    onClick = () => {}
}) {
    const { driver: driverData, suggestions: suggestionsData } =
        suggestedDriverData;
    const serialSelectedLiveDriver = useSelector(
        selectLiveDriverById(clientDriverId)
    );
    const { t } = useTranslation();
    const inducedDelays = suggestionsData[0].explainMetrics.newNumberOfDelays;

    function _getCardHeader() {
        return (
            <div className="suggesteddrivercard-header _d-flex _ai-center">
                <RouteCardMarkerNoButton
                    initials={generalUtils.getUserInitials(
                        driverData.firstname,
                        driverData.lastname
                    )}
                    colorCSS={driverColors}
                />
                <div className="suggesteddrivercard-header_text _d-flex _fd-column _ai-flex-start">
                    <div className="_text-2 _to-ellipsis">
                        {generalUtils.getUserName(
                            driverData.firstname,
                            driverData.lastname
                        )}
                    </div>
                    {isBestDriver && (
                        <div className="suggesteddrivercard-best">
                            {t(
                                `SuggestMetrics.BestDriverOrderedBy.${orderedBy}`
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    function _getMetrics() {
        const { explainMetrics } = suggestionsData[0];
        return Object.entries(suggestMetricTypes).map(
            ([metricType, metricData], idx) => {
                if (idx === 0) {
                    // only first metric supported by API for now
                    return (
                        <SuggestedDriverMetric
                            key={metricType}
                            metricType={metricType}
                            totalValue={
                                explainMetrics[
                                    metricData.totalSuggestMetricName
                                ]
                            }
                            inducedValue={
                                explainMetrics[
                                    metricData.inducedSuggestMetricName
                                ]
                            }
                            orderedBy={orderedBy}
                        />
                    );
                }
                if (generalUtils.isFlaggedFeatureDisplayed()) {
                    // currently under feature flag with dummy data since
                    // metrics are not supported by API at the moment
                    return (
                        <SuggestedDriverMetric
                            key={metricType}
                            metricType={metricType}
                            totalValue={7100}
                            inducedValue={2500}
                            orderedBy={orderedBy}
                        />
                    );
                }
                return null;
            }
        );
    }

    function _handleClickDispatch() {
        const tasksToDispatch = suggestionsData.map((task) => {
            const { taskId, deliveryTime, pickupTime } = task;

            // convert times from seconds to ISO date
            return {
                id: taskId,
                deliveryTime: DateTime.fromSeconds(
                    deliveryTime || pickupTime
                ).toISO(),
                pickupTime: DateTime.fromSeconds(
                    pickupTime || deliveryTime
                ).toISO()
            };
        });

        const payload = {
            clientId: driverData.client,
            driverId: driverData.id,
            tasksToDispatch,
            isLiveDriver: Boolean(serialSelectedLiveDriver)
        };

        return onClick && onClick(payload);
    }

    return (
        <CollapsibleCard
            className="suggesteddrivercard"
            cardHeader={_getCardHeader()}
            initOpen={isOpen}
        >
            <div className="suggesteddrivercard-metrics">
                {_getMetrics()}
                {Boolean(inducedDelays) && (
                    <Alert
                        className="suggesteddrivermetric-delays"
                        variant="danger"
                        icon="warningFill2"
                        iconColor="mars"
                    >
                        {t('SuggestMetrics.inducedDelays', {
                            count: inducedDelays
                        })}
                    </Alert>
                )}
            </div>
            <button
                className="button _w-100"
                type="button"
                onClick={_handleClickDispatch}
            >
                <Icon icon="iconRouteFill" size="s" color="comet" />
                <div className="suggesteddrivercard-dispatch_text">
                    {t('UnassignedTasksControl.button.dispatch')}
                </div>
            </button>
        </CollapsibleCard>
    );
}
