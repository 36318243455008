import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';

export const bookingsByClientSlice = createSlice({
    name: 'bookingsByClient',
    initialState: {},
    reducers: {
        setBookingsByClient: (state, action) => {
            return action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return {};
        });
    }
});

export const { setBookingsByClient } = bookingsByClientSlice.actions;

export const selectBookingsByClient = (state) => state.bookingsByClient;

export default bookingsByClientSlice.reducer;
