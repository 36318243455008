import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectShowZonePolygons,
    setShowZonePolygons
} from '~/reducers/mapSettingsSlice';

import MapSettingMenuItem from '../MapSettingMenuItem/MapSettingMenuItem';

export default function MapSettingMenuRoutesSection({ mapRouteMode }) {
    const { t } = useTranslation(['translation']);
    const dispatch = useDispatch();

    const showZonePolygonState = useSelector(
        selectShowZonePolygons(mapRouteMode)
    );

    const onZonePolygonsStateChange = useCallback((checked) => {
        dispatch(setShowZonePolygons({ mode: mapRouteMode, value: checked }));
    }, []);

    return (
        <>
            <span className="mapsettingssection_title">{t('zone')}</span>
            <MapSettingMenuItem
                iconName="routeMarker"
                text={t('zonePolygons')}
                checkedState={showZonePolygonState}
                onChangeState={onZonePolygonsStateChange}
            />
        </>
    );
}
