import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';
import { planDataFactory } from '~/utils/data-factory';

function _removeStopsDataByClientRouteIds(clientRouteIds, existingState) {
    for (const clientRouteId of clientRouteIds) {
        let indexFirst;
        let indexLast;
        for (let index = 0; index < existingState.length; index++) {
            const planStop = planDataFactory.makePlanStop(existingState[index]);
            if (planStop.clientRouteId === clientRouteId) {
                if (indexFirst === undefined) {
                    indexFirst = index;
                }
                indexLast = index;
            }
        }
        if (indexFirst !== undefined && indexLast !== undefined) {
            const numElementsToRemove = indexLast - indexFirst + 1;
            existingState.splice(indexFirst, numElementsToRemove);
        }
    }
}

export const planStopsLevelData = createSlice({
    name: 'planStopsLevelData',
    initialState: [],
    reducers: {
        replacePlanStopsLevelData: (state, action) => {
            const clientRouteIds = {};
            for (const newData of action.payload) {
                const planStop = planDataFactory.makePlanStop(newData);
                clientRouteIds[planStop.clientRouteId] = true;
            }
            _removeStopsDataByClientRouteIds(
                Object.keys(clientRouteIds),
                state
            );
            state.push(...action.payload);
            return state;
        },
        removePlanStopsByClientRouteIds: (state, action) => {
            if (!Array.isArray(action.payload)) {
                return state;
            }
            _removeStopsDataByClientRouteIds(action.payload, state);
            return state;
        },
        resetPlanStopsLevelData: () => {
            return [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return [];
        });
    }
});

export const {
    replacePlanStopsLevelData,
    resetPlanStopsLevelData,
    removePlanStopsByClientRouteIds
} = planStopsLevelData.actions;
export const selectPlanStopsLevelData = (state) => state.planStopsLevelData;
export default planStopsLevelData.reducer;
