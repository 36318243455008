import React, { createContext } from 'react';
import { useSelector } from 'react-redux';
import ProcessIndicator from '~/ui/components/ProcessIndicator/ProcessIndicator';
import { selectProcessIndicator } from '~/reducers/processIndicatorSlice';

const ProcessIndicatorContext = createContext();

export default ProcessIndicatorContext;

/**
 * Provides the `ProcessIndicatorContext` and also renders the `ProcessIndicator` with the `children`
 */
function ProcessIndicatorProvider({ children }) {
    const processIndicatorInstance = useSelector(selectProcessIndicator);

    return (
        <BaseProcessIndicatorProvider>
            {children}
            <ProcessIndicator
                message={processIndicatorInstance.message}
                promiseInProgress={processIndicatorInstance.inProgress}
                promiseRejected={processIndicatorInstance.error}
                position={processIndicatorInstance.position}
            />
        </BaseProcessIndicatorProvider>
    );
}

/**
 * Provides the `ProcessIndicatorContext` without rendering `ProcessIndicator`
 */
export function BaseProcessIndicatorProvider({ children }) {
    const processIndicatorInstance = useSelector(selectProcessIndicator);

    function isProcessIndicatorVisible() {
        return processIndicatorInstance.inProgress;
    }

    return (
        <ProcessIndicatorContext.Provider
            value={{
                isProcessIndicatorVisible,
                processIndicatorType: processIndicatorInstance.type,
                processIndicatorPayload: processIndicatorInstance.payload
            }}
        >
            {children}
        </ProcessIndicatorContext.Provider>
    );
}

export { ProcessIndicatorProvider };
