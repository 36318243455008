export default {
    'icon-xxxs': '0.6rem',
    'icon-xxs': '1rem',
    'icon-xs': '1.2rem',
    'icon-s': '1.6rem',
    'icon-m': '2rem',
    'icon-l': '2.4rem',
    'icon-xl': '3.6rem',
    'icon-xxl': '4.8rem',
    'navigation-height': '4.8rem',
    'action-bar-height': '6.4rem',
    'collapsed-drawer-height': '6.4rem',
    'modal-width-m': '114rem',
    'modal-height-m': '64rem'
};
