import React from 'react';
import classNames from 'classnames';
import { Icon } from '~/ui';
import './alert.scss';

function Alert({
    variant,
    icon,
    iconColor = 'galaxy-400',
    iconSize = 's',
    className,
    children
}) {
    function _getClassName() {
        const defaultClassName = 'alert _d-flex';
        const conditionalClasses = {
            'alert_has-icon': icon,
            [`alert_${variant}`]: variant
        };
        return classNames(defaultClassName, conditionalClasses, className);
    }

    return (
        <div className={_getClassName()}>
            {icon && <Icon icon={icon} color={iconColor} size={iconSize} />}
            <div className="alert_details">{children}</div>
        </div>
    );
}

export default Alert;
