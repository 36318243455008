import axios from 'axios';
import dateUtils from '~/utils/date-utils';
import constants from '~/utils/constants';

export default {
    path: '/courier_task_assignments',
    post({ date, close_routes }) {
        if (!date) {
            return Promise.reject('date not provided');
        }
        date = dateUtils.convertToISODateOnly(date);

        return axios.post(
            this.path,
            { date, close_routes },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    },
    /**
     * @param id - task assignment run id
     * @param {{date: string}} params - run date
     */
    get(id, params) {
        if (!id && !params) {
            return Promise.reject('must specify run id or date');
        }
        if (params && params.date) {
            params.date = dateUtils.convertToISODateOnly(params.date);
        }
        const path = id ? `${this.path}/${id}` : this.path;
        return axios.get(path, {
            params,
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }
};
