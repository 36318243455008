import React from 'react';
import { TooltipButton } from '~/ui';

import './drawer-footerbutton.scss';

function DrawerFooterButton({
    className,
    buttonText,
    tooltipMessage = '',
    onClick = () => {}
}) {
    function _handleOnClick() {
        return onClick && onClick();
    }

    function _getClassName() {
        let boxClassName = 'drawer-footerbutton _text-2 button_clear';
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }

    return (
        <TooltipButton
            className={_getClassName()}
            tooltipMessage={tooltipMessage}
            onClick={_handleOnClick}
        >
            <span>{buttonText}</span>
        </TooltipButton>
    );
}

export default DrawerFooterButton;
