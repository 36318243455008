import React from 'react';
import { useDispatch } from 'react-redux';

import { removeSelectedMapStop } from '~/reducers/selectedMapStopsSlice';

import { IconButton, ManageStopControlListItem, theme } from '~/ui';

function ResequenceModalStopList({
    selectedStop,
    handleDragStart,
    handleDragOver,
    handleDrop,
    stopIdPropertyName,
    tooltipPlacement = 'top'
}) {
    const dispatch = useDispatch();
    const { stopNumber, stopName } = selectedStop;
    const stopId = selectedStop[stopIdPropertyName];

    function _removeStop() {
        dispatch(removeSelectedMapStop(stopId));
    }

    return (
        <div
            className="resequencemodal-stopitem"
            draggable="true"
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            id={stopId}
        >
            <ManageStopControlListItem
                className="resequencemodal-stopitem-left"
                text={stopName}
                iconButtonText={stopNumber}
                iconButtonTextColor={theme.colors['galaxy-800']}
                tooltipPlacement={tooltipPlacement}
            />
            <div className="_d-flex stopitem-buttongroup">
                <IconButton
                    className="icon-button_clear stopitem-button"
                    onClick={_removeStop}
                    icon="cancel"
                    iconColor="galaxy-800"
                />
                <IconButton
                    className="icon-button_clear stopitem-button"
                    icon="iconDragger"
                    iconColor="galaxy-800"
                />
            </div>
        </div>
    );
}

export default ResequenceModalStopList;
