import React from 'react';
import constants from '~/utils/constants';
import classNames from 'classnames';

import './marker-popup.scss';

function MarkerPopup({
    headerContent,
    bodyContent,
    bodyClassName,
    emittedEventHandler
}) {
    function _closePopup() {
        emittedEventHandler({
            event: constants.mapChildEvents.POPUP_MOUSELEAVE
        });
    }
    function _handleMouseEnter() {
        emittedEventHandler({
            event: constants.mapChildEvents.POPUP_MOUSEENTER
        });
    }
    function _getClassName() {
        const defaultClassName = 'marker-popup-body';
        return classNames(defaultClassName, bodyClassName);
    }
    return (
        <div
            className="marker-popup"
            onMouseEnter={_handleMouseEnter}
            onMouseLeave={_closePopup}
        >
            <div className="marker-popup-header">{headerContent}</div>
            <div className={_getClassName()}>{bodyContent}</div>
        </div>
    );
}

export default MarkerPopup;
