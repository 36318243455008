import React, { useEffect, useState } from 'react';
import { Map } from '~/ui';
import '../TaskDetailDrawer.scss';

function TaskMap({ taskMapMarkers }) {
    const [taskMapRef, setTaskMapRef] = useState(null);
    const mapOptions = {
        onGoogleApiLoaded: handleApiLoaded,
        yesIWantToUseGoogleMapApiInternals: true,
        center: {
            lat: taskMapMarkers[0].props.lat,
            lng: taskMapMarkers[0].props.lng
        }
    };

    useEffect(() => {
        if (!taskMapRef || taskMapMarkers.length === 1) {
            return;
        }
        const bounds = new window.google.maps.LatLngBounds();
        taskMapMarkers.forEach((taskMapMarker) => {
            bounds.extend({
                lat: taskMapMarker.props.lat,
                lng: taskMapMarker.props.lng
            });
        });
        taskMapRef.fitBounds(bounds);
    }, [taskMapRef, taskMapMarkers]);

    function handleApiLoaded({ map }) {
        setTaskMapRef(map);
    }

    return (
        <Map
            mode="simple"
            className="taskdetaildrawer-map"
            googleMapOptions={mapOptions}
        >
            {taskMapMarkers.map((taskMapMarker) => taskMapMarker)}
        </Map>
    );
}

export default TaskMap;
