import React, { useState, useEffect, useRef } from 'react';
import { Icon } from '~/ui';

import unitsUtils from '~/utils/unit-converter';

import './collapsible.scss';

function Collapsible({
    className,
    direction = 'down',
    textOpen,
    textClose,
    initOpen = false,
    overrideClickHandler = null,
    disabled = false,
    children,

    iconOpen,
    iconClose,
    iconSize,
    iconColor,
    iconPosition = 'right'
}) {
    const [icon, setIcon] = useState();
    const [text, setText] = useState();
    const [isOpen, setIsOpen] = useState(initOpen);
    const [panelMaxHeight, setPanelMaxHeight] = useState(1200);
    const [panelStyle, setPanelStyle] = useState({});

    const panelRef = useRef(null);

    useEffect(() => {
        if (!iconClose && !iconOpen) {
            setIcon();
            return;
        }

        return isOpen ? setIcon(iconOpen) : setIcon(iconClose);
    }, [isOpen, iconClose, iconOpen]);

    useEffect(() => {
        if (!textClose && !textOpen) {
            setText();
            return;
        }

        return isOpen ? setText(textOpen) : setText(textClose);
    }, [isOpen, textClose, textOpen]);

    useEffect(() => {
        if (panelRef.current) {
            const panelHeight = panelRef.current.scrollHeight;
            if (panelHeight > 0) setPanelMaxHeight(panelHeight);
        }
    }, [isOpen, panelRef]);

    useEffect(() => {
        const maxHeight = `${unitsUtils.convertPixelstoRem(panelMaxHeight)}rem`;
        return isOpen ? setPanelStyle({ maxHeight }) : setPanelStyle({});
    }, [isOpen, panelMaxHeight]);

    function _handleClick() {
        const clickHandler = overrideClickHandler || _toggleCollapse;
        clickHandler();
    }

    function _toggleCollapse() {
        setIsOpen(!isOpen);
    }

    function _getClassName() {
        let boxClassName = 'collapsible';
        boxClassName =
            (isOpen && `${boxClassName} collapsible_open`) || boxClassName;
        boxClassName =
            (disabled && `${boxClassName} collapsible_disabled`) ||
            boxClassName;
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }

    return (
        <div
            className={_getClassName()}
            data-direction={direction}
            aria-expanded={isOpen}
        >
            {children && direction === 'up' && (
                <div
                    className="collapsible-panel"
                    style={panelStyle}
                    ref={panelRef}
                >
                    {children}
                </div>
            )}
            <div className="collapsible-toggle">
                <button
                    className="collapsible-toggle_button _d-flex _jc-space-between _ai-center"
                    type="button"
                    disabled={disabled}
                    onClick={_handleClick}
                >
                    {icon && iconPosition === 'left' && (
                        <Icon
                            className="collapsible-toggle_icon"
                            icon={icon}
                            size={iconSize}
                            color={iconColor}
                        />
                    )}
                    {text && (
                        <span className="collapsible-toggle_text">{text}</span>
                    )}
                    {icon && iconPosition === 'right' && (
                        <Icon
                            className="collapsible-toggle_icon"
                            icon={icon}
                            size={iconSize}
                            color={iconColor}
                        />
                    )}
                </button>
            </div>
            {children && direction === 'down' && (
                <div
                    className="collapsible-panel"
                    style={panelStyle}
                    ref={panelRef}
                >
                    {children}
                </div>
            )}
        </div>
    );
}

export default Collapsible;
