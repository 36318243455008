import PT from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Box } from '~/ui';
import Icon from '../Icon';

const commonVariantStyles = {
    color: 'galaxy-800',
    fontSize: '1.6rem',
    placeholder: {
        color: 'galaxy-400'
    }
};

const variantStyles = {
    basic: {
        ...commonVariantStyles,
        border: 'solid 0.1rem var(--color-galaxy-300)',
        borderRadius: '0.8rem',
        padding: '0.6rem 1.6rem'
    },
    edit: {
        ...commonVariantStyles,
        fontSize: '1.4rem',
        border: 'solid 0.1rem var(--color-ocean)',
        backgroundColor: 'var(--color-neptune-100)',
        borderRadius: '0.8rem',
        position: 'relative',
        bottom: '0.7rem',
        padding: '0.6rem 0.8rem',
        focus: {
            outline: 'none'
        }
    },
    search: {
        ...commonVariantStyles,
        border: (theme) => `solid 0.1rem ${theme.colors['galaxy-200']}`,
        borderRadius: '0.8rem',
        padding: '1rem 1rem 1rem 4rem',
        icon: 'search'
    }
};

const widths = {
    xs: '10.9rem',
    s: '16rem',
    m: '32rem',
    fullWidth: '100%'
};

const TextInput = React.forwardRef(function TextInput(
    {
        disabled = false,
        placeholder,
        value = '',
        width = 'm',
        variant = 'basic',
        onChange,
        sx,
        wrapperSx = {},
        type = 'text',
        className,
        ...extra
    },
    ref
) {
    const [state, setState] = useState(value);
    const selectedStyle = variantStyles[variant];

    useEffect(() => setState(value), [value]);

    function onInputChange(e) {
        setState(e.target.value);

        return onChange && onChange(e.target.value);
    }

    return (
        <Box
            className={className}
            sx={{
                position: 'relative',
                ...wrapperSx
            }}
        >
            {selectedStyle.icon && (
                <Icon
                    icon={selectedStyle.icon}
                    size="s"
                    sx={{ position: 'absolute', left: '1rem', top: '1rem' }}
                />
            )}
            <Box
                as="input"
                disabled={disabled}
                placeholder={placeholder}
                padding={selectedStyle.padding}
                ref={ref}
                sx={{
                    border: selectedStyle.border,
                    borderRadius: selectedStyle.borderRadius,
                    color: selectedStyle.color,
                    backgroundColor: selectedStyle.backgroundColor,
                    fontSize: selectedStyle.fontSize,
                    width: widths[width],
                    position: selectedStyle.position,
                    bottom: selectedStyle.bottom,
                    '&::placeholder': {
                        color: selectedStyle.placeholder.color
                    },
                    '&:disabled': {
                        color: 'galaxy-400',
                        backgroundColor: 'transparent'
                    },
                    '&:focus': selectedStyle.focus,
                    ...sx
                }}
                type={type}
                value={state}
                onChange={onInputChange}
                {...extra}
            />
        </Box>
    );
});

TextInput.propTypes = {
    /** Wrapper styles */
    wrapperSx: PT.oneOfType([PT.object]),
    /** Disable input component */
    disabled: PT.bool,
    /** The input type */
    type: PT.oneOf(['text', 'number']),
    /** Text input placeholder */
    placeholder: PT.string,
    /** Text input value */
    value: PT.string,
    /** Text input style variant */
    variant: PT.oneOf(['search', 'basic', 'edit']),
    /** Width of text input */
    width: PT.oneOf(['xs', 's', 'm', 'fullWidth']),
    /** Callback that returns new input value */
    onChange: PT.func,
    /** Component's class name */
    className: PT.string
};

export default TextInput;
