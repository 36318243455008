import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';

/**
 * Redux slice for user-selected task IDs
 * @category Reducers
 * @module selectedTaskIds
 */

/** @typedef {import('@reduxjs/toolkit').PayloadAction} PayloadAction */

export const selectedTaskIdsSlice = createSlice({
    name: 'selectedTaskIds',
    initialState: [],
    reducers: {
        /**
         * add new task ID to the slice
         * @method addSelectedTaskId
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {String} action.payload - the id of the task
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { addSelectedTaskId } from '~/reducers/selectedTaskIdsSlice';
         * import { useDispatch } from 'react-redux';
         * const dispatch = useDispatch();
         *
         * // add task ID
         * const taskId = 'dd5db981-6a37-4616-8348-6522fd26b765';
         * dispatch(addSelectedTaskId(taskId));
         */
        addSelectedTaskId: (state, action) => {
            const hasTaskId = state.includes(action.payload);
            if (!hasTaskId) {
                state.push(action.payload);
            }
            return state;
        },

        /**
         * remove a task ID from the slice
         * @method removeSelectedTaskId
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {String} action.payload - the id of the task
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { removeSelectedTaskId } from '~/reducers/selectedTaskIdsSlice';
         * import { useDispatch } from 'react-redux';
         * const dispatch = useDispatch();
         *
         * // remove task ID
         * const taskId = 'dd5db981-6a37-4616-8348-6522fd26b765';
         * dispatch(removeSelectedTaskId(taskId));
         */
        removeSelectedTaskId: (state, action) => {
            const index = state.indexOf(action.payload);
            if (index !== -1) {
                state.splice(index, 1);
            }
            return state;
        },

        /**
         * sets / replaces the current state of the slice with the payload value
         * @method setSelectedTaskIds
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {(String|String[])} action.payload - a single task ID or an array of task IDs
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';
         * import { useDispatch } from 'react-redux';
         * const dispatch = useDispatch();
         *
         * // set single task
         * const singleId = 'dd5db981-6a37-4616-8348-6522fd26b765';
         * dispatch(setSelectedTaskIds(singleId));
         *
         * // set multiple tasks
         * const multipleIds = [
         *     'f87cbd52-0f40-4299-aeef-64b4ad8243fa',
         *     '0e27b929-0344-46e1-960e-54bdf0f1b6f2',
         *     '1a60888c-dc5d-44ea-be85-b31c4c2a3d8e'
         * ];
         * dispatch(setSelectedTaskIds(multipleIds));
         */
        setSelectedTaskIds: (state, action) => {
            return _.isArray(action.payload)
                ? action.payload
                : [action.payload];
        },

        /**
         * reset the slice back to default values
         * @method resetSelectedTaskIds
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { resetSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';
         * import { useDispatch } from 'react-redux';
         * const dispatch = useDispatch();
         *
         * // reset task IDs
         * dispatch(resetSelectedTaskIds());
         */
        resetSelectedTaskIds: () => {
            return [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return [];
        });
    }
});
export const {
    addSelectedTaskId,
    removeSelectedTaskId,
    setSelectedTaskIds,
    resetSelectedTaskIds
} = selectedTaskIdsSlice.actions;

/**
 * selects all the current map stops in the slice
 * @method selectSelectedTaskIds
 * @param {Object} state - the current state
 * @returns {String[]} an array of map stop IDs
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';
 * import { useSelector } from 'react-redux';
 *
 * // returns all map stops
 * const taskIds = useSelector(selectSelectedTaskIds);
 */
export const selectSelectedTaskIds = (state) => state.selectedTaskIds;

export default selectedTaskIdsSlice.reducer;
