import React from 'react';
import PT from 'prop-types';

import { IconButton } from '~/ui';
import './routecard-marker.scss';

function RouteCardMarker({
    className,
    disabled,
    visible = true,
    text,
    textColor,
    textStyle,
    icon,
    iconSize = 'm',
    iconColor,
    iconStyle,
    tooltipMessage,
    tooltipPlacement = 'bottom',
    onClick,
    onMouseEnter,
    onMouseLeave
}) {
    function _getClassName() {
        let boxClassName = 'routecard-marker';
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }

    return (
        <div className={_getClassName()}>
            <IconButton
                className="routecard_select-button"
                style={iconStyle}
                disabled={disabled}
                visible={visible}
                text={text}
                textColor={textColor}
                textStyle={textStyle}
                icon={icon}
                iconSize={iconSize}
                iconColor={iconColor}
                tooltipMessage={tooltipMessage}
                tooltipPlacement={tooltipPlacement}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            />
        </div>
    );
}

RouteCardMarker.propTypes = {
    /** additional css classes to attach to this component */
    className: PT.string
};

export default RouteCardMarker;
