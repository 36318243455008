/* eslint-disable camelcase */
import fileDownload from 'js-file-download';
import axios from 'axios';
import * as XLSX from 'xlsx';

// @TODO: sample data to be replaced by API data
import * as sampleData from '~/samples/cn-full-custom-cost-matrix.json';
import constants from '~/utils/constants';

const terms = {
    zone: {
        NOZONE: 'NOZONE'
    },
    customCostTerm: {
        CONSTANT: 'constant',
        TRIP: 'trip',
        STOP: 'stop'
    },
    calculationType: {
        PROGRESSIVE: 'progressive',
        CUTOFF: 'cutoff'
    },
    sheetName: {
        ZONETOZONECOSTS: 'zoneToZoneCosts',
        VARIABLECOSTS: 'variableCosts'
    }
};

const getTermsRowData = (commonTermData, customCostTerm, termData, xOfType) => {
    const { thresholds, weights, isProgressive } = termData;
    const rowData = {
        ...commonTermData,
        custom_cost_term: customCostTerm,
        threshold_attribute: thresholds.attribute,
        threshold_values: thresholds.values.join(','),
        weight_attribute: weights.attribute,
        weight_values: weights.values.join(','),
        calculation_type: isProgressive
            ? terms.calculationType.PROGRESSIVE
            : terms.calculationType.CUTOFF,
        firstOfType: false,
        lastOfType: false
    };

    const hasXOfTypeThreshold = Boolean(thresholds[xOfType]);
    const hasXOfTypeWeight = Boolean(weights[xOfType]);
    const hasXOfType = hasXOfTypeThreshold || hasXOfTypeWeight;

    if (hasXOfType) {
        rowData[xOfType] = true;
        if (hasXOfTypeThreshold) {
            rowData.threshold_values = thresholds[xOfType].join(',');
        }
        if (hasXOfTypeWeight) {
            rowData.weight_values = weights[xOfType].join(',');
        }
    }

    return { hasXOfType, rowData };
};

const getVariableCostsRowData = (commonRowData, customCostTerm, termData) => {
    const allRows = [];
    const { rowData } = getTermsRowData(
        commonRowData,
        customCostTerm,
        termData
    );
    const { hasXOfType: hasFirstOfType, rowData: firstOfTypeRowData } =
        getTermsRowData(commonRowData, customCostTerm, termData, 'firstOfType');
    const { hasXOfType: hasLastOfType, rowData: lastOfTypeRowData } =
        getTermsRowData(commonRowData, customCostTerm, termData, 'lastOfType');

    allRows.push(rowData);
    if (hasFirstOfType) allRows.push(firstOfTypeRowData);
    if (hasLastOfType) allRows.push(lastOfTypeRowData);

    return allRows;
};

const transformEntries = (entries) => {
    return entries.reduce((all, entry) => {
        const commonRowData = {
            start_zone_id: entry.start_zone_id || terms.zone.NOZONE,
            end_zone_id: entry.end_zone_id || terms.zone.NOZONE
        };
        const { constantTerm, stopTerms, tripTerms } = entry.custom_cost_terms;

        if (constantTerm) {
            all.push({
                ...commonRowData,
                custom_cost_term: terms.customCostTerm.CONSTANT,
                value: constantTerm.value
            });
        }

        if (tripTerms) {
            tripTerms.forEach((term) => {
                const rows = getVariableCostsRowData(
                    commonRowData,
                    terms.customCostTerm.TRIP,
                    term
                );
                all.push(...rows);
            });
        }

        if (stopTerms) {
            stopTerms.forEach((term) => {
                const rows = getVariableCostsRowData(
                    commonRowData,
                    terms.customCostTerm.STOP,
                    term
                );
                all.push(...rows);
            });
        }

        return all;
    }, []);
};

const convertToSpreadsheet = (entries) => {
    const customCostMatrix = transformEntries(entries);
    const zoneToZoneCosts = customCostMatrix.filter((item) => {
        return item.custom_cost_term === terms.customCostTerm.CONSTANT;
    });
    const variableCosts = customCostMatrix.filter((item) => {
        return item.custom_cost_term !== terms.customCostTerm.CONSTANT;
    });

    const wb = XLSX.utils.book_new();
    const zoneToZoneCostsSheet = XLSX.utils.json_to_sheet(zoneToZoneCosts);
    const variableCostsSheet = XLSX.utils.json_to_sheet(variableCosts);
    XLSX.utils.book_append_sheet(
        wb,
        zoneToZoneCostsSheet,
        terms.sheetName.ZONETOZONECOSTS
    );
    XLSX.utils.book_append_sheet(
        wb,
        variableCostsSheet,
        terms.sheetName.VARIABLECOSTS
    );
    return wb;
};

const convertToJson = (data) => {
    const workbook = XLSX.read(data);

    const zoneToZoneCosts = XLSX.utils.sheet_to_json(
        workbook.Sheets[terms.sheetName.ZONETOZONECOSTS]
    );
    const variableCosts = XLSX.utils.sheet_to_json(
        workbook.Sheets[terms.sheetName.VARIABLECOSTS]
    );

    // @todo: add zone name to zone id translation
    // @todo: validate and transform into final JSON format for API submission
    console.info(zoneToZoneCosts);
    console.info(variableCosts);
};

export const useCustomCostConfiguration = () => {
    const downloadFolder = constants.url.DOWNLOADS;
    const downloadFilename = constants.downloads.costConfiguration;
    const downloadUrl = `${downloadFolder}/${downloadFilename}`;

    const downloadBlank = async () => {
        const res = await axios({
            url: downloadUrl,
            method: 'GET',
            baseURL: process.env.PUBLIC_URL,
            responseType: 'blob',
            params: {
                v: Date.now()
            }
        });

        fileDownload(res.data, downloadFilename);
    };

    const downloadCurrent = () => {
        const { entries } = sampleData.default;
        const wb = convertToSpreadsheet(entries);
        XLSX.writeFile(wb, downloadFilename);
    };

    const uploadNew = async (event) => {
        const [uploadedFile] = event.target.files;
        const data = await uploadedFile.arrayBuffer();
        convertToJson(data);
    };

    return { downloadBlank, downloadCurrent, uploadNew };
};
