import React, { useEffect, useState } from 'react';
import PT from 'prop-types';

import { Checkbox, Icon, Tooltip, useClickOutside } from '~/ui';

import './DropDownSelection.css';

const DropDownSelection = React.forwardRef(function DropDownSelection(
    {
        title = '',
        placeholder = '',
        items = [],
        hasSelectAll = true,
        selectAllText = '',
        handleChange,
        initialValues,
        disabledItems = [],
        styles,
        isDropDownDisplayed,
        onToggle,
        onHide
    },
    ref
) {
    const [selectedItems, setSelectedItems] = useState(initialValues || []);
    const [selectedAll, setSelectedAll] = useState([]);
    const [displayedSelectedItems, setDisplayedSelectedItems] = useState([]);
    useClickOutside(ref, onHide);

    useEffect(() => {
        if (initialValues) {
            setSelectedItems(initialValues);
        }
    }, [initialValues]);

    useEffect(() => {
        // checkmark 'select all' when all items are selected
        if (selectedItems.length === items.length && items.length !== 0) {
            setSelectedAll([true]);
        } else {
            setSelectedAll([false]);
        }
    }, [selectedItems]);

    useEffect(() => {
        const selectedItemsWithoutDisabledItems = selectedItems.flatMap(
            (item) => (disabledItems.includes(item) ? [] : item.name)
        );
        setDisplayedSelectedItems(selectedItemsWithoutDisabledItems);
    }, [selectedItems]);

    function handleItemSelection(payload) {
        setSelectedAll([]);
        setSelectedItems(payload.selectedValues);
        handleChange(payload.selectedValues);
    }

    function handleSelectAll(payload) {
        setSelectedAll(payload.selectedValues);
        if (payload.selectedValues.length) {
            setSelectedItems(items);
            handleChange(items);
        } else {
            setSelectedItems([]);
            handleChange([]);
        }
    }

    return (
        <div ref={ref} className="dropdown">
            <label
                htmlFor="dropDownInput"
                className="dropdown-input-wrapper _mt-6"
            >
                <span className="dropdown-input-title">{title}</span>
                <Tooltip
                    placement="top"
                    content={displayedSelectedItems.join(', ')}
                    disabled={!displayedSelectedItems.length}
                >
                    <div
                        onClick={onToggle}
                        onKeyDown={onToggle}
                        role="button"
                        tabIndex={0}
                    >
                        <input
                            id="dropDownInput"
                            type="text"
                            className="dropdown-input"
                            defaultValue={displayedSelectedItems.join(', ')}
                            placeholder={placeholder}
                            readOnly
                        />
                    </div>
                </Tooltip>
            </label>
            <button
                type="button"
                className={`icon-wrapper dropdown-arrow ${
                    isDropDownDisplayed && 'dropdown-arrow--rotate'
                }`}
                onClick={onToggle}
            >
                <Icon icon="chevronDown" color="galaxy-800" />
            </button>
            {isDropDownDisplayed && (
                <div className={`${styles?.selections} dropdown-selections`}>
                    {hasSelectAll && (
                        <Checkbox
                            id="select-all"
                            name="select-all"
                            className="dropdown-selections-items"
                            value
                            selectedValues={selectedAll}
                            handleChange={handleSelectAll}
                        >
                            {`${selectAllText} (${items.length})`}
                        </Checkbox>
                    )}
                    {items.map((item) => {
                        const isDisabled = disabledItems.some(
                            (disabledItem) => {
                                return disabledItem.id === item.id;
                            }
                        );
                        return (
                            <Checkbox
                                key={item.id}
                                id={item.id}
                                name={item.name}
                                className="dropdown-selections-items"
                                value={item}
                                selectedValues={selectedItems}
                                handleChange={handleItemSelection}
                                disabled={isDisabled}
                            >
                                {item.name}
                            </Checkbox>
                        );
                    })}
                </div>
            )}
        </div>
    );
});

DropDownSelection.propTypes = {
    /** Input label title */
    title: PT.string,
    /** Input placeholder */
    placeholder: PT.string,
    /** Array of selectable items (checkbox) */
    items: PT.arrayOf(PT.object),
    /** Select all checkbox is displayed */
    hasSelectAll: PT.bool,
    /** Text for select all checkbox */
    selectAllText: PT.string,
    /** Handle checkbox change function */
    handleChange: PT.func,
    /** Initial value for selected items */
    initialValues: PT.arrayOf(PT.object),
    /** Array of items that are disabled */
    disabledItems: PT.arrayOf(PT.object),
    /** Boolean to display the dropdown down */
    isDropDownDisplayed: PT.bool,
    /** Function called on outside click */
    onHide: PT.func,
    /** Function called to toggle drop down */
    onToggle: PT.func
};

export default DropDownSelection;
