import {
    combineReducers,
    configureStore,
    getDefaultMiddleware
} from '@reduxjs/toolkit';
import {
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    persistStore,
    PURGE,
    REGISTER,
    REHYDRATE
} from 'redux-persist';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

import { activeClientsSlice } from '~/reducers/activeClientsSlice';
import { availableDriversIdSlice } from '~/reducers/availableDriversIdSlice';
import { bookingMetricsSlice } from '~/reducers/bookingMetricsSlice';
import { bookingsByClientSlice } from '~/reducers/bookingsByClientSlice';
import { cepResultsSlice } from '~/reducers/cepResultsSlice';
import { clientsSlice } from '~/reducers/clientsSlice';
import { currentPageSlice } from '~/reducers/currentPageSlice';
import { detectWebSolutionSlice } from '~/reducers/detectWebSolutionSlice';
import { driversByClientSlice } from '~/reducers/driversByClientSlice';
import { driversLocationsSlice } from '~/reducers/driversLocationsSlice';
import { filteredDataCountSlice } from '~/reducers/filteredDataCountSlice';
import { filteredOutRoutesSlice } from '~/reducers/filteredOutRoutesSlice';
import { forecastSlice } from '~/reducers/forecastSlice';
import { hiddenRoutesSlice } from '~/reducers/hiddenRoutesSlice';
import { lastPlanMapCenterSlice } from '~/reducers/lastPlanMapCenterSlice';
import { lastPlanMapZoomSlice } from '~/reducers/lastPlanMapZoomSlice';
import { latestEditHistoryByClientSlice } from '~/reducers/latestEditHistoryByClientSlice';
import { liveDriversSlice } from '~/reducers/liveDriversSlice';
import { mainClientSlice } from '~/reducers/mainClientSlice';
import { mapDrawerSettingsSlice } from './reducers/mapDrawerSettingsSlice';
import { mapSettingsSlice } from './reducers/mapSettingsSlice';
import { mapSideBarSlice } from '~/reducers/mapSideBarSlice';
import { onDemandDispatchTaskCountSlice } from '~/reducers/onDemandDispatchTaskCountSlice';
import { pendingJobsSlice } from '~/reducers/pendingJobsSlice';
import { planClientsLevelData } from '~/reducers/planClientsLevelDataSlice';
import { planRoutesLevelData } from '~/reducers/planRoutesLevelDataSlice';
import { planStopsLevelData } from '~/reducers/planStopsLevelDataSlice';
import { processIndicatorSlice } from '~/reducers/processIndicatorSlice';
import { routePlanMetrics } from '~/reducers/routePlanMetricsSlice';
import { routePlansByDateSlice } from '~/reducers/routePlansByDateSlice';
import { routesDrawerFilterSlice } from './reducers/routesDrawerFilterSlice';
import { routesDrawerSortSlice } from './reducers/routesDrawerSortSlice';
import { selectedDateSlice } from '~/reducers/selectedDateSlice';
import { selectedDrawerCardDataSlice } from '~/reducers/selectedDrawerCardDataSlice';
import { selectedDrawerCardIdSlice } from '~/reducers/selectedDrawerCardIdSlice';
import { selectedMapRoutesSlice } from '~/reducers/selectedMapRoutesSlice';
import { selectedMapStopsSlice } from '~/reducers/selectedMapStopsSlice';
import { selectedTaskFiltersSlice } from '~/reducers/selectedTaskFiltersSlice';
import { selectedTaskIdsSlice } from '~/reducers/selectedTaskIdsSlice';
import { selectedTaskRowIdSlice } from '~/reducers/selectedTaskRowIdSlice';
import { selectedUserGroupIdSlice } from '~/reducers/selectedUserGroupIdSlice';
import { taskMetricsSlice } from '~/reducers/taskMetricsSlice';
import { tasksSlice } from './reducers/tasksSlice';
import { toastsSlice } from '~/reducers/toastsSlice';
import { unassignedPlanTaskCountSlice } from '~/reducers/unassignedPlanTaskCountSlice';
import { userGroupsByIdSlice } from '~/reducers/userGroupsByIdSlice';
import { userGroupsForCurrentUserSlice } from '~/reducers/userGroupsForCurrentUserSlice';
import { usersByClientSlice } from '~/reducers/usersByClientSlice';
import { usersByIdSlice } from '~/reducers/usersByIdSlice';
import { webColorsSlice } from './reducers/webColorsSlice';
import { workflowSlice } from '~/reducers/workflowSlice';

// keep `reducers` sorted alphabetically
// to help find them from `redux-devTools` list
const reducers = combineReducers({
    activeClients: activeClientsSlice.reducer,
    availableDriversId: availableDriversIdSlice.reducer,
    bookingMetrics: bookingMetricsSlice.reducer,
    bookingsByClient: bookingsByClientSlice.reducer,
    cepResults: cepResultsSlice.reducer,
    clients: clientsSlice.reducer,
    currentPage: currentPageSlice.reducer,
    detectWebSolution: detectWebSolutionSlice.reducer,
    driversByClient: driversByClientSlice.reducer,
    driversLocations: driversLocationsSlice.reducer,
    filteredDataCount: filteredDataCountSlice.reducer,
    filteredOutRoutes: filteredOutRoutesSlice.reducer,
    forecast: forecastSlice.reducer,
    hiddenRoutes: hiddenRoutesSlice.reducer,
    lastPlanMapCenter: lastPlanMapCenterSlice.reducer,
    lastPlanMapZoom: lastPlanMapZoomSlice.reducer,
    latestEditHistoryByClient: latestEditHistoryByClientSlice.reducer,
    liveDrivers: liveDriversSlice.reducer,
    mainClient: mainClientSlice.reducer,
    mapDrawerSettings: mapDrawerSettingsSlice.reducer,
    mapSettings: mapSettingsSlice.reducer,
    mapSideBar: mapSideBarSlice.reducer,
    onDemandDispatchTaskCount: onDemandDispatchTaskCountSlice.reducer,
    pendingJobs: pendingJobsSlice.reducer,
    planClientsLevelData: planClientsLevelData.reducer,
    planRoutesLevelData: planRoutesLevelData.reducer,
    planStopsLevelData: planStopsLevelData.reducer,
    processIndicator: processIndicatorSlice.reducer,
    routePlanMetrics: routePlanMetrics.reducer,
    routePlansByDate: routePlansByDateSlice.reducer,
    routesDrawerFilter: routesDrawerFilterSlice.reducer,
    routesDrawerSort: routesDrawerSortSlice.reducer,
    selectedDate: selectedDateSlice.reducer,
    selectedDrawerCardData: selectedDrawerCardDataSlice.reducer,
    selectedDrawerCardId: selectedDrawerCardIdSlice.reducer,
    selectedMapRoutes: selectedMapRoutesSlice.reducer,
    selectedMapStops: selectedMapStopsSlice.reducer,
    selectedTaskFilters: selectedTaskFiltersSlice.reducer,
    selectedTaskIds: selectedTaskIdsSlice.reducer,
    selectedTaskRowId: selectedTaskRowIdSlice.reducer,
    selectedUserGroupId: selectedUserGroupIdSlice.reducer,
    taskMetrics: taskMetricsSlice.reducer,
    tasks: tasksSlice.reducer,
    toasts: toastsSlice.reducer,
    unassignedPlanTaskCount: unassignedPlanTaskCountSlice.reducer,
    userGroupsById: userGroupsByIdSlice.reducer,
    userGroupsForCurrentUser: userGroupsForCurrentUserSlice.reducer,
    usersByClient: usersByClientSlice.reducer,
    usersById: usersByIdSlice.reducer,
    webColors: webColorsSlice.reducer,
    workflow: workflowSlice.reducer
});

const createNoopStorage = () => {
    return {
        getItem() {
            return Promise.resolve(null);
        },
        setItem(_key, value) {
            return Promise.resolve(value);
        },
        removeItem() {
            return Promise.resolve();
        }
    };
};

// Workaround for failing to create sync storage.
// More about workaround: https://github.com/vercel/next.js/discussions/15687#discussioncomment-45319
const storage =
    typeof window !== 'undefined'
        ? createWebStorage('local')
        : createNoopStorage();

const persistConfig = {
    key: 'root',
    storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
    })
});

const persistor = persistStore(store);

export { store, persistor };
