import React from 'react';
import classNames from 'classnames';

import { ManageStopControlMenu, StopModalMenuButton } from '~/ui';

import './manage-stop-control-multiple.scss';

function ManageStopControlMultiple({
    stopList,
    children,
    className,
    controlTitle,
    controlHelp,
    footerButtonTitle,
    footerButtonIcon,
    onClickFooterButton = () => {}
}) {
    function _getClassName() {
        const defaultClassName = `managestopcontrol_multiple _d-flex _fd-column`;
        return classNames(defaultClassName, className);
    }
    return (
        stopList && (
            <div className={_getClassName()}>
                <div className="managestopcontrol_main">
                    <span className="_text-2">{controlTitle}</span>
                    <div className="_d-flex _ai-center">
                        <span className="_text-3-alt">{controlHelp}</span>
                    </div>
                    <div className="managestopcontrol_stop-list">
                        {stopList}
                    </div>
                </div>
                {children}
                {footerButtonTitle && (
                    <ManageStopControlMenu className="managestopcontrol_footer">
                        <StopModalMenuButton
                            titleIcon={footerButtonIcon}
                            title={footerButtonTitle}
                            onClickHandler={onClickFooterButton}
                        />
                    </ManageStopControlMenu>
                )}
            </div>
        )
    );
}

export { ManageStopControlMultiple };
