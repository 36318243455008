import axios from 'axios';
import constants from '~/utils/constants';
import generalUtils from '~/utils/general-utils';

export const singlePlanEditAPI = {
    path: '/schedules/evaluate?action=',
    unassignTasks(clientId, { date, routeId, taskIds }) {
        if (!clientId) {
            return Promise.reject('Missing clientId');
        }
        if (!date) {
            return Promise.reject('Missing date');
        }
        if (!routeId) {
            return Promise.reject('Missing routeId');
        }
        if (!taskIds || !Array.isArray(taskIds) || !taskIds.length) {
            return Promise.reject('Missing taskIds');
        }

        return axios.post(
            `${this.path}trim&accept`,
            {
                date,
                routeId,
                taskIds,
                type: constants.productTypes.SCHEDULER
            },
            {
                headers: {
                    [constants.requestHeaders.WISE_CLIENT_ID]: clientId
                }
            }
        );
    },
    augmentRoute(clientId, { date, driverId, taskIds }) {
        if (!clientId) {
            return Promise.reject('Missing clientId');
        }
        if (!date) {
            return Promise.reject('Missing date');
        }
        if (!driverId) {
            return Promise.reject('Missing driverId');
        }

        if (!taskIds || !Array.isArray(taskIds) || !taskIds.length) {
            return Promise.reject('Missing taskIds');
        }

        return axios.post(
            `${this.path}augment&accept`,
            {
                date,
                type: constants.productTypes.SCHEDULER,
                driverId,
                taskIds
            },
            {
                headers: {
                    [constants.requestHeaders.WISE_CLIENT_ID]: clientId
                }
            }
        );
    },
    resequenceTasks({ clientId, clientPreferences, date, routeId, taskIds }) {
        if (!clientId) {
            return Promise.reject('Missing clientId');
        }
        if (!date) {
            return Promise.reject('Missing date');
        }
        if (!routeId) {
            return Promise.reject('Missing routeId');
        }
        if (!taskIds || !Array.isArray(taskIds) || !taskIds.length) {
            return Promise.reject('Missing taskIds');
        }

        return axios.post(
            `${this.path}resequence&accept`,
            {
                ...clientPreferences,
                date,
                routeId,
                stopIds: taskIds,
                type: constants.productTypes.SCHEDULER
            },
            {
                headers: {
                    [constants.requestHeaders.WISE_CLIENT_ID]: clientId
                }
            }
        );
    },
    optimizeRoutes({ clientId, clientPreferences, date, routeIds }) {
        if (!clientId) return Promise.reject('Missing clientId');

        if (!date) return Promise.reject('Missing date');

        if (!routeIds?.length || !Array.isArray(routeIds))
            return Promise.reject('Missing routeIds');

        return axios.post(
            `${this.path}optimize&accept`,
            {
                ...clientPreferences,
                date,
                routeIds,
                type: constants.productTypes.SCHEDULER
            },
            {
                headers: {
                    [constants.requestHeaders.WISE_CLIENT_ID]: clientId
                }
            }
        );
    },
    reassignTasks({
        clientId,
        clientPreferences,
        date,
        taskIds,
        oldRouteId,
        selectedDriverId,
        selectedRouteId
    }) {
        if (!clientId) return Promise.reject('Missing clientId');

        if (!date) return Promise.reject('Missing date');

        if (!oldRouteId) return Promise.reject('Missing oldRouteId');

        if (!taskIds?.length || !Array.isArray(taskIds))
            return Promise.reject('Missing taskIds');

        const isNewRouteIdValid = generalUtils.isValidUUID(selectedRouteId);
        const newRouteId = (isNewRouteIdValid && selectedRouteId) || '';
        const driverId = (!isNewRouteIdValid && selectedDriverId) || '';

        return axios.post(
            `${this.path}reassign&accept`,
            {
                ...clientPreferences,
                date,
                driverId,
                newRouteId,
                oldRouteId,
                taskIds,
                type: constants.productTypes.SCHEDULER
            },
            {
                headers: {
                    [constants.requestHeaders.WISE_CLIENT_ID]: clientId
                }
            }
        );
    }
};
