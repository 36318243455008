import { DateTime, Duration } from 'luxon';

/**
 * Date Converters
 *
 * @category Utils
 * @module utils/dateUtilsConverters
 *
 * @example
 * import dateUtilsConverters from '~/utils/date-utils-converters';
 */

/**
 * Converts ISO date string to JS Date
 *
 * @param {String} date - string format of ISO date, for example: 2021-01-01
 * @returns {Date} - JS Date
 *
 * @example
 * // returns JS date for 2021-01-01
 * const isoDate = dateUtilsConverters.convertISODateToJsDate('2021-01-01');
 *
 * // returns JS date for 1978-07-04
 * const nonIsoDate = dateUtilsConverters.convertISODateToJsDate('July 4 1978');
 *
 * // returns 'Invalid Date'
 * const badDate = dateUtilsConverters.convertISODateToJsDate('a long, long time ago...');
 *
 * // returns null
 * const nullDate = dateUtilsConverters.convertISODateToJsDate();
 *
 * @see [JS Date Object]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date}
 */
function convertISODateToJsDate(date) {
    return date ? new Date(date) : null;
}

/**
 * Convert ISO date to numeric date (example: "2022/01/20")
 *
 * @param {string} date
 * @returns {string}
 */
function convertISODateToNumericDate(date) {
    return DateTime.fromISO(date).toFormat('D');
}

/**
 * Converts JS Date to ISO date string
 *
 * @param {Date} date - JS Date
 * @returns {String} date - string format of ISO date
 */
function convertJsDateToISODate(date) {
    return date ? DateTime.fromJSDate(date).toISO() : null;
}

/**
 * Formats the date or timestamp to ISO Date without time
 *
 * @param {(Date | string | number)} date - JS Date or timestamp (example: 2021-04-07T00:00:00.000-04:00)
 * @returns {string} - ISO date format without time (example: 2021-04-07)
 */
function convertToISODateOnly(date) {
    return date ? DateTime.fromJSDate(new Date(date)).toISODate() : null;
}

/**
 * Convert date and time to ISO format
 *
 * @param {Date} date - JS date
 * @param {string} time - 24-hour time (example: "09:00")
 * @returns {string}
 */
function convert24HourDateTimeToISO(date, time) {
    return DateTime.fromObject({
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        hour: time.split(':')[0],
        minute: time.split(':')[1]
    }).toISO();
}

/**
 * Get 24-hour time from ISO date time (example: "19:00")
 *
 * @param {string} time - time in ISO format
 * @returns {string}
 */
function get24HourTime(time) {
    return DateTime.fromISO(time).toFormat('T');
}

/**
 * Convert milliseconds to number of hours and minutes
 *
 * @param { Number } milliseconds
 * @returns {{hours: *, minutes: *}}
 */
function convertMillisecondsToHoursAndMinutes(milliseconds) {
    const duration = Duration.fromMillis(milliseconds)
        .shiftTo('hours', 'minutes')
        .normalize()
        .toObject();
    duration.minutes = Math.floor(duration.minutes);
    return duration;
}

export default {
    convertISODateToJsDate,
    convertISODateToNumericDate,
    convertJsDateToISODate,
    convertToISODateOnly,
    convert24HourDateTimeToISO,
    get24HourTime,
    convertMillisecondsToHoursAndMinutes
};
