import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';

export const routePlanMetrics = createSlice({
    name: 'routePlanMetrics',
    initialState: {
        numberOfUnassignedTasks: 0,
        numberOfTasks: 0,
        numberOfRoutes: 0,
        scheduledClients: 0
    },
    reducers: {
        setRoutePlanMetrics: (state, action) => {
            return action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return {
                numberOfUnassignedTasks: 0,
                numberOfTasks: 0,
                numberOfRoutes: 0,
                scheduledClients: 0
            };
        });
    }
});

export const { setRoutePlanMetrics } = routePlanMetrics.actions;

export const selectRoutePlanMetrics = (state) => state.routePlanMetrics;

export default routePlanMetrics.reducer;
