import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    resetSelectedMapStops,
    selectSelectedMapStops
} from '~/reducers/selectedMapStopsSlice';
import { ManageStopControl } from '~/ui';
import { ResequenceModal } from '~/components/MapPage/ResequenceModal/ResequenceModal';
import SingleStopControl from './SingleStopControl';
import MultiStopsControl from './MultiStopsControl';
import { mapPlanStopUtils } from '~/utils/map/map-planstop-utils';
import './plan-stops-control.scss';
import { usePlanMapPropsContext } from '~/components/MapPage/PlanMap/PlanMapPropsContext';
import ReassignModal from '~/components/MapPage/ReassignModal';
import constants from '~/utils/constants';

const stopIdPropertyName = 'clientRouteTaskId';
const { views } = constants.stopsControl;

export const PlanStopsControl = () => {
    const dispatch = useDispatch();
    const { handleStopResequencing, planStops, routesLevelData } =
        usePlanMapPropsContext();
    const selectedMapStopsIds = useSelector(selectSelectedMapStops);
    const [activeView, setActiveView] = useState(views.MAIN);

    const { t } = useTranslation('translation');

    const {
        firstSelectedPlanStopData,
        isDifferentRoutesSelected,
        isMultiStopsSelected,
        allPlanStops = [],
        selectedPlanStops = [],
        unselectedPlanStops = []
    } = useMemo(() => {
        if (!selectedMapStopsIds.length) {
            if (activeView !== views.MAIN) setActiveView(views.MAIN);
            return {};
        }
        const {
            allStops,
            firstSelectedStop,
            firstSelectedClientRouteId,
            selectedStops,
            unselectedStops
        } = mapPlanStopUtils.mapIdtoStopData(planStops, selectedMapStopsIds);
        return {
            allPlanStops: allStops,
            firstSelectedPlanStopData: firstSelectedStop,
            isMultiStopsSelected: selectedStops.length > 1,
            selectedPlanStops: selectedStops,
            unselectedPlanStops: unselectedStops,
            isDifferentRoutesSelected:
                mapPlanStopUtils.isDifferentRoutesStopSelected(
                    firstSelectedClientRouteId,
                    selectedStops
                )
        };
    }, [planStops, selectedMapStopsIds, activeView]);

    const deselectButtonClickHandler = useCallback(() => {
        dispatch(resetSelectedMapStops());
    }, [dispatch]);

    const onCloseHandler = useCallback(() => setActiveView(views.MAIN), []);

    const onResequence = useCallback(
        (selectedStopId, isInsertAfter) => {
            const selectedStop = allPlanStops.find(
                (stop) => stop[stopIdPropertyName] === selectedStopId
            );

            if (!selectedStop) {
                console.warn('Stop not selected');
                return;
            }

            const { stopNumber: newStopNumber } = selectedStop;
            handleStopResequencing({ newStopNumber, isInsertAfter });
        },
        [allPlanStops, handleStopResequencing]
    );

    if (!firstSelectedPlanStopData) return null;

    return (
        <ManageStopControl className="planstopscontrol">
            {activeView === views.MAIN && (
                <>
                    {isMultiStopsSelected ? (
                        <MultiStopsControl
                            selectedPlanStops={selectedPlanStops}
                            stopIdPropertyName={stopIdPropertyName}
                            disableResequencing={isDifferentRoutesSelected}
                            footerButtonTitle={t(
                                'PlanStopsControl.button.deselectAll'
                            )}
                            footerButtonIcon="iconClose"
                            onClickFooterButton={deselectButtonClickHandler}
                            onSwitchView={setActiveView}
                        />
                    ) : (
                        <SingleStopControl
                            stopData={firstSelectedPlanStopData}
                            footerButtonTitle={t(
                                'PlanStopsControl.button.deselect'
                            )}
                            footerButtonIcon="iconClose"
                            onClickFooterButton={deselectButtonClickHandler}
                            onSwitchView={setActiveView}
                        />
                    )}
                </>
            )}
            {activeView === views.RESEQUENCE && (
                <ResequenceModal
                    clickHandler={onCloseHandler}
                    isDifferentRoutesSelected={isDifferentRoutesSelected}
                    selectedStopsData={selectedPlanStops}
                    unselectedStopsData={unselectedPlanStops}
                    stopIdPropertyName={stopIdPropertyName}
                    onResequence={onResequence}
                />
            )}
            {activeView === views.REASSIGN && (
                <ReassignModal
                    onClose={onCloseHandler}
                    routes={routesLevelData}
                    selectedStops={selectedPlanStops}
                    stopIdPropertyName={stopIdPropertyName}
                />
            )}
        </ManageStopControl>
    );
};
