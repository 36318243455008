import React from 'react';
import './TaskMapMarker.css';

function TaskMapMarker({ isSelected = true }) {
    return (
        <>
            {isSelected ? (
                <div className="taskmapmarker taskmapmarker_selected _d-inline-block" />
            ) : (
                <div className="taskmapmarker taskmapmarker_unselected _d-inline-block" />
            )}
        </>
    );
}

export default TaskMapMarker;
