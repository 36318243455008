import React, {
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PT from 'prop-types';
import { FlexLayout, theme, Toast } from '~/ui';
import { resetToasts, selectToasts } from '~/reducers/toastsSlice';

const ToastContext = createContext();

export default ToastContext;

const positions = {
    'top-left': {
        top: '12rem',
        left: '0'
    },
    'top-center': {
        top: '12rem',
        left: '50%',
        transform: 'translateX(-50%)'
    },
    'top-right': {
        top: '12rem',
        right: '0'
    }
};

function ToastContextProvider({ children, position = 'top-center' }) {
    const [toasts, setToasts] = useState([]);
    const storeToasts = useSelector(selectToasts);
    const dispatch = useDispatch();

    const removeToast = useCallback(
        (toast) => {
            setToasts((items) => items.filter((i) => i !== toast));
        },
        [setToasts]
    );

    const addToast = useCallback(
        (toast) => {
            setToasts((items) => [
                { ...toast, id: new Date().getTime() },
                ...items
            ]);
        },
        [setToasts]
    );

    useEffect(() => {
        if (storeToasts.length > 0) {
            storeToasts.forEach(addToast);
            dispatch(resetToasts());
        }
    }, [storeToasts, addToast]);

    const value = useMemo(
        () => ({
            addToast,
            clearToasts: () => {
                setToasts([]);
            }
        }),
        [addToast, setToasts]
    );

    const selectedPosition = positions[position];

    return (
        <ToastContext.Provider value={value}>
            {children}
            <FlexLayout
                className="toastcontext"
                flexDirection="column"
                alignItems="center"
                sx={{
                    '> *': {
                        '&:not(:last-child)': { marginBottom: '0.8rem' },
                        pointerEvents: 'all'
                    },
                    pointerEvents: 'none',
                    position: 'fixed',
                    zIndex: theme.zIndex.toast,
                    ...selectedPosition
                }}
            >
                {toasts.map((toast) => (
                    <Toast
                        key={toast.id}
                        duration={toast.duration}
                        message={toast.message}
                        onRemove={() => removeToast(toast)}
                        variant={toast.variant}
                    />
                ))}
            </FlexLayout>
        </ToastContext.Provider>
    );
}

ToastContextProvider.propTypes = {
    /** Position of toast in viewport */
    position: PT.oneOf(['top-left', 'top-center', 'top-right'])
};

export { ToastContextProvider };
