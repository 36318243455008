/** @jsxRuntime classic */
// known bug - see : https://github.com/system-ui/theme-ui/issues/1160
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Icon } from '~/ui/';
import './depot-marker.scss';

function DepotMarker({ variant }) {
    return (
        <div
            className="depot-marker _jc-center _ai-center"
            data-variant={variant || null}
        >
            <Icon icon="depot" size="s" color="white" />
        </div>
    );
}

export default DepotMarker;
