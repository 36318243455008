import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
    Alert,
    Button,
    Collapsible,
    Icon,
    LabelInput,
    useClickOutside
} from '~/ui';

import tasksApi from '~/api/tasks';
import { selectTasks, updateTaskById } from '~/reducers/tasksSlice';
import { addToast } from '~/reducers/toastsSlice';
import constants from '~/utils/constants';
import taskUtils from '~/utils/task-utils';

import './EditTaskAddressModal.scss';

const EditTaskAddressModal = React.forwardRef(function EditTaskAddressModal(
    { taskId, isDelivery, onHide },
    ref
) {
    const { t } = useTranslation(['taskManagement', 'common']);
    const domElement = document.getElementById('modal-root');
    const dispatch = useDispatch();

    const allTasks = useSelector(selectTasks);
    const [inputs, setInputs] = useState({
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipcode: '',
        longitude: '',
        latitude: ''
    });

    useClickOutside(ref, onHide);

    useEffect(() => {
        const taskAddress = isDelivery
            ? allTasks[taskId].deliveryLocation
            : allTasks[taskId].pickupLocation;
        setInputs({
            addressLine1: taskAddress.addressLine1,
            addressLine2: taskAddress.addressLine2,
            city: taskAddress.city,
            state: taskAddress.state,
            zipcode: taskAddress.zipcode,
            longitude: String(taskAddress.location.lng),
            latitude: String(taskAddress.location.lat)
        });
    }, [taskId]);

    function onInputChange(field, { target: { value } }) {
        const currentFields = { ...inputs };
        currentFields[field] = value;
        setInputs(currentFields);
    }

    async function onSave() {
        const property = isDelivery
            ? constants.taskTypes.DELIVERY_LOCATION
            : constants.taskTypes.PICKUP_LOCATION;
        const previousValue = allTasks[taskId][property];
        const updatedValue = {
            name: previousValue.name,
            addressLine1: inputs.addressLine1,
            addressLine2: inputs.addressLine2,
            city: inputs.city,
            state: inputs.state,
            zipcode: inputs.zipcode,
            location: {
                lng: parseFloat(inputs.longitude),
                lat: parseFloat(inputs.latitude)
            }
        };

        try {
            await tasksApi.update(taskId, {
                [property]: updatedValue
            });
            const getResponse = await tasksApi.getTask(taskId, {
                extent: property
            });
            const updatedTask = getResponse.data.data;
            const taskStatus = taskUtils.getTaskStatus(updatedTask);
            dispatch(
                updateTaskById({
                    taskId,
                    value: { ...updatedTask, taskStatus }
                })
            );
            onHide();
        } catch (error) {
            console.error(error);
            dispatch(
                addToast({
                    message: t('error:taskEditError'),
                    variant: 'error'
                })
            );
        }
    }

    return ReactDOM.createPortal(
        <div className="overlay">
            <div className="modal" ref={ref}>
                <div className="modal-header">
                    <span>{t('editAddress.title')}</span>
                    <button
                        type="button"
                        className="icon-wrapper"
                        onClick={onHide}
                    >
                        <Icon icon="cancel" color="galaxy-800" />
                    </button>
                </div>
                <div className="editaddress-content">
                    <div className="_d-grid editaddress-inputs">
                        <LabelInput
                            className="editaddress-input_address"
                            label={t('editAddress.address')}
                            placeholder={t('editAddress.address')}
                            value={inputs.addressLine1}
                            onChange={(e) => onInputChange('addressLine1', e)}
                        />
                        <LabelInput
                            className="editaddress-input_address _d-flex"
                            placeholder={t('editAddress.address2')}
                            value={inputs.addressLine2}
                            onChange={(e) => onInputChange('addressLine2', e)}
                        />
                        <LabelInput
                            className="editaddress-input_city"
                            label={t('editAddress.city')}
                            placeholder={t('editAddress.city')}
                            value={inputs.city}
                            onChange={(e) => onInputChange('city', e)}
                        />
                        <LabelInput
                            className="editaddress-input_province"
                            label={t('editAddress.province')}
                            placeholder={t('editAddress.province')}
                            value={inputs.state}
                            onChange={(e) => onInputChange('state', e)}
                        />
                        <LabelInput
                            className="editaddress-input_postalcode"
                            label={t('editAddress.postalCode')}
                            placeholder={t('editAddress.postalCode')}
                            value={inputs.zipcode}
                            onChange={(e) => onInputChange('zipcode', e)}
                        />
                        <Collapsible
                            className="editaddress-collapsible"
                            textClose={t('editAddress.textClose')}
                            textOpen={t('editAddress.textOpen')}
                            iconClose="chevronDown"
                            iconOpen="chevronUp"
                            iconColor="meteor"
                            iconPosition="left"
                        >
                            <div className="_d-grid editaddress-coords">
                                <Alert
                                    variant="info"
                                    icon="infoLine"
                                    iconSize="m"
                                    iconColor="meteor"
                                    className="editaddress-coords_alert"
                                >
                                    {t('editAddress.coordinateAlert')}
                                </Alert>
                                <LabelInput
                                    className="editaddress-coords-input_longitude"
                                    label={t('editAddress.longitude')}
                                    placeholder={t('editAddress.longitude')}
                                    value={inputs.longitude}
                                    inputType="number"
                                    onChange={(e) =>
                                        onInputChange('longitude', e)
                                    }
                                />
                                <LabelInput
                                    className="editaddress-coords-input_latitude"
                                    label={t('editAddress.latitude')}
                                    placeholder={t('editAddress.latitude')}
                                    value={inputs.latitude}
                                    inputType="number"
                                    onChange={(e) =>
                                        onInputChange('latitude', e)
                                    }
                                />
                            </div>
                        </Collapsible>
                    </div>
                    <div className="_d-flex _jc-flex-end">
                        <Button
                            variant="secondary"
                            marginRight="1rem"
                            onClick={onHide}
                        >
                            {t('common:cancel')}
                        </Button>
                        <Button onClick={onSave}>{t('common:save')}</Button>
                    </div>
                </div>
            </div>
        </div>,
        domElement
    );
});

export default EditTaskAddressModal;
