import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InventoryItemStatus from './InventoryItemStatus/InventoryItemStatus';
import './InventoryItem.css';
import { IconButton, useModal } from '~/ui';
import InventoryItemEditModalContent from './InventoryItemEditModalContent/InventoryItemEditModalContent';
import { validatePermissions } from '~/utils/validatePermissions';
import constants from '~/utils/constants';
import { selectUserGroupsForCurrentUser } from '~/reducers/userGroupsForCurrentUserSlice';

function InventoryItem({ item, index, onDelete }) {
    const { t } = useTranslation('taskManagement');
    const [isHover, setIsHover] = useState(false);
    const [inventoryItem, setInventoryItem] = useState(item);
    const associatedUserGroups = useSelector(selectUserGroupsForCurrentUser);
    const hasEditPermissions = validatePermissions(
        associatedUserGroups,
        constants.userPermissionFeatures.TASK_MANAGEMENT,
        constants.userPermissionRoles.EDITOR
    );
    const editableInventoryItemClassName = hasEditPermissions
        ? 'inventoryitem-edit_enabled'
        : '';
    const {
        showModal: showInventoryItemEditModal,
        hideModal: hideInventoryItemEditModal,
        modal: InventoryItemEditModal
    } = useModal();

    function onMouseEnter() {
        setIsHover(true);
    }

    function onMouseLeave() {
        setIsHover(false);
    }

    function openInventoryItemEditModal() {
        showInventoryItemEditModal();
    }

    function updateInventoryItem(payload) {
        setInventoryItem({ ...inventoryItem, ...payload });
    }

    return (
        <div
            className={`inventoryitem ${editableInventoryItemClassName} _d-flex _ai-center _jc-space-between _mb-3`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <div className="inventoryitem-detail">
                <div className="heading-3 _to-ellipsis">
                    {inventoryItem.item_name}
                </div>
                <div className="sub-heading-2 _to-ellipsis">
                    {t('taskManagement:drawer.barcodeId', {
                        id: inventoryItem.barcode_id
                    })}
                </div>
            </div>
            {isHover && hasEditPermissions ? (
                <IconButton icon="edit" onClick={openInventoryItemEditModal} />
            ) : (
                <InventoryItemStatus status={inventoryItem.status} />
            )}
            <InventoryItemEditModal variant="small" hasCustomHeader>
                <InventoryItemEditModalContent
                    id={inventoryItem.id}
                    index={index}
                    itemName={inventoryItem.item_name}
                    barcodeId={inventoryItem.barcode_id}
                    quantity={inventoryItem.actual_quantity}
                    weight={inventoryItem.unit_weight}
                    hideModal={hideInventoryItemEditModal}
                    onUpdate={updateInventoryItem}
                    onDelete={onDelete}
                />
            </InventoryItemEditModal>
        </div>
    );
}

export default InventoryItem;
