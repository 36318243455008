import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Text } from '~/ui';

const MOVE_UP = -1;
const MOVE_DOWN = 1;

export const ResequenceModalButtonGroup = ({
    selectedStops,
    unselectedStops,
    onResequence,
    stopIdPropertyName
}) => {
    const [newResequencingParams, setNewResequencingParams] = useState(null);

    const { t } = useTranslation('translation');
    const isButtonDisabled = selectedStops.length < 1;

    useEffect(() => {
        if (newResequencingParams === null) return;

        const { selectedStopId, isInsertAfter } = newResequencingParams;

        setNewResequencingParams(null);
        onResequence(selectedStopId, isInsertAfter);
    }, [newResequencingParams]);

    const handleClick = useCallback(
        (index) => {
            const allStops = [...selectedStops, ...unselectedStops].sort(
                (firstStop, secondStop) =>
                    firstStop.stopNumber - secondStop.stopNumber
            );

            const [firstSelectedStop] = selectedStops;
            const currentPosition = allStops.findIndex(
                ({ stopNumber }) => stopNumber === firstSelectedStop.stopNumber
            );
            const direction = index === 0 ? MOVE_UP : MOVE_DOWN;
            const newPosition = currentPosition + direction;

            if (
                currentPosition === -1 ||
                newPosition < 0 ||
                newPosition >= allStops.length
            ) {
                console.warn('Invalid stop index');
                return;
            }

            setNewResequencingParams({
                selectedStopId: allStops[newPosition][stopIdPropertyName],
                isInsertAfter: direction === MOVE_DOWN
            });
        },
        [selectedStops, stopIdPropertyName, unselectedStops]
    );

    const iconAndTitleTuples = [
        ['arrowUp', t('ResequenceModal.ButtonGroup.title_up')],
        ['arrowDown', t('ResequenceModal.ButtonGroup.title_down')]
    ];

    return (
        <div className="resequencemodal-buttongroup">
            {iconAndTitleTuples.map(([icon, buttonText], index) => (
                <Button
                    disabled={isButtonDisabled}
                    key={icon}
                    onClick={() => handleClick(index)}
                >
                    <Icon icon={icon} color="meteor" />
                    <Text variant="12-normal" color="meteor">
                        {buttonText}
                    </Text>
                </Button>
            ))}
        </div>
    );
};
