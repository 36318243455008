import React from 'react';
import PT from 'prop-types';

import './button-group.scss';

function ButtonGroup({ buttons = [] }) {
    return (
        <div className="buttongroup _d-flex">
            {buttons.map((item) => (
                <button
                    key={`buttongroup_button-${item.text}`}
                    type="button"
                    className="buttongroup_button"
                    disabled={item.disabled}
                    // prevent `data-selected` from rendering when false or falsy
                    // for correct css rule application
                    data-selected={item.selected || null}
                    onClick={() => {
                        if (!item.selected) item.onClick();
                    }}
                >
                    {item.text}
                </button>
            ))}
        </div>
    );
}

ButtonGroup.propTypes = {
    /**
     * Collection of buttons
     */
    buttons: PT.arrayOf(PT.object)
};

export default ButtonGroup;
