/**
 * Web Colors
 * @category Utils
 * @subcategory Constants
 *
 * @constant
 * @type {WebColor[]}
 */
const webColors = [
    {
        bgColor: '#814096',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#FFA424',
        fgColor: '#09101A'
    },
    {
        bgColor: '#0587D3',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#367D71',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#E8768A',
        fgColor: '#09101A'
    },
    {
        bgColor: '#1C93C0',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#906F5A',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#FFDA00',
        fgColor: '#09101A'
    },
    {
        bgColor: '#597592',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#4F6EF4',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#DDAC5E',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#CF116D',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#006AFF',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#BA66D5',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#FF824A',
        fgColor: '#09101A'
    },
    {
        bgColor: '#37BFAA',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#FFC76F',
        fgColor: '#09101A'
    },
    {
        bgColor: '#FF5E3F',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#28BAC8',
        fgColor: '#09101A'
    },
    {
        bgColor: '#694EEF',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#D0E071',
        fgColor: '#09101A'
    },
    {
        bgColor: '#BB9251',
        fgColor: '#09101A'
    },
    {
        bgColor: '#12DFB1',
        fgColor: '#09101A'
    },
    {
        bgColor: '#D964AC',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#75B565',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#D4B6DD',
        fgColor: '#09101A'
    },
    {
        bgColor: '#FDE8CA',
        fgColor: '#09101A'
    },
    {
        bgColor: '#85B7FF',
        fgColor: '#09101A'
    },
    {
        bgColor: '#B0CCC8',
        fgColor: '#09101A'
    },
    {
        bgColor: '#FFDBE2',
        fgColor: '#09101A'
    },
    {
        bgColor: '#78D1F2',
        fgColor: '#09101A'
    },
    {
        bgColor: '#DFD4CD',
        fgColor: '#09101A'
    },
    {
        bgColor: '#FFF4B5',
        fgColor: '#09101A'
    },
    {
        bgColor: '#D9E2EC',
        fgColor: '#09101A'
    },
    {
        bgColor: '#9AA2C4',
        fgColor: '#09101A'
    },
    {
        bgColor: '#FFE2B5',
        fgColor: '#09101A'
    },
    {
        bgColor: '#F183B8',
        fgColor: '#09101A'
    },
    {
        bgColor: '#7395C4',
        fgColor: '#09101A'
    },
    {
        bgColor: '#FF87FA',
        fgColor: '#09101A'
    },
    {
        bgColor: '#FFCDB7',
        fgColor: '#09101A'
    },
    {
        bgColor: '#AFE5DD',
        fgColor: '#09101A'
    },
    {
        bgColor: '#E0CBA9',
        fgColor: '#09101A'
    },
    {
        bgColor: '#FFA0A0',
        fgColor: '#09101A'
    },
    {
        bgColor: '#93ECF5',
        fgColor: '#09101A'
    },
    {
        bgColor: '#B7BEFF',
        fgColor: '#09101A'
    },
    {
        bgColor: '#B2BA7E',
        fgColor: '#09101A'
    },
    {
        bgColor: '#B4A48A',
        fgColor: '#09101A'
    },
    {
        bgColor: '#33AC91',
        fgColor: '#FFFFFF'
    },
    {
        bgColor: '#FFBBE9',
        fgColor: '#09101A'
    },
    {
        bgColor: '#BBE89E',
        fgColor: '#09101A'
    }
];

export default webColors;
