import React from 'react';
import classNames from 'classnames';

import { IconButton, Text, TextOverflowTooltip } from '~/ui';

import './manage-stop-control-list-item.scss';

function ManageStopControlListItem({
    text,
    iconButtonText,
    iconButtonTextColor,
    iconButtonTextStyle,
    tooltipPlacement = 'top',
    className
}) {
    function _getClassName() {
        const defaultClassName = `managestopcontrol_list-item _d-flex _ai-center`;
        return classNames(defaultClassName, className);
    }
    return (
        <div className={_getClassName()}>
            <IconButton
                disabled
                text={iconButtonText}
                textColor={iconButtonTextColor}
                textStyle={iconButtonTextStyle}
            />
            <TextOverflowTooltip placement={tooltipPlacement} content={text}>
                <Text
                    className="stopitem-title _text-3 _to-ellipsis"
                    variant="12-normal"
                >
                    {text}
                </Text>
            </TextOverflowTooltip>
        </div>
    );
}

export { ManageStopControlListItem };
