import axios from 'axios';
import constants from '~/utils/constants';

export const editRouteHistoryAPI = {
    path: '/editroutehistory',
    getMetrics(clientId, schedulerId) {
        if (!clientId) {
            return Promise.reject('Missing clientId');
        }
        if (!schedulerId) {
            return Promise.reject('Missing schedulerId');
        }
        return axios.get(`${this.path}/metrics?schedulerId=${schedulerId}`, {
            headers: {
                [constants.requestHeaders.WISE_CLIENT_ID]: clientId
            }
        });
    },
    getLatestEditHistory(clientId, schedulerId) {
        if (!clientId) {
            return Promise.reject('Missing clientId');
        }
        if (!schedulerId) {
            return Promise.reject('Missing schedulerId');
        }
        return axios.get(
            `${this.path}?limit=1&status=undoable&scheduler_id=${schedulerId}`,
            {
                headers: {
                    [constants.requestHeaders.WISE_CLIENT_ID]: clientId
                }
            }
        );
    }
};
