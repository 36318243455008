import React from 'react';
import PT from 'prop-types';

import { RouteCardMetric } from '~/ui';
import './routecard-summary.scss';

function RouteCardSummary({ className, metrics }) {
    function _getClassName() {
        let boxClassName = 'routecard-summary';
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }

    function _getMetrics() {
        return metrics.map((item, idx) => {
            const {
                metric,
                value,
                maxValue,
                icon,
                iconSize,
                iconColor,
                icons
            } = item;
            const key = `${metric}-${idx}`;

            return (
                <RouteCardMetric
                    key={key}
                    className={item.className}
                    metric={metric}
                    value={value}
                    maxValue={maxValue}
                    icon={icon}
                    iconSize={iconSize}
                    iconColor={iconColor}
                    icons={icons}
                />
            );
        });
    }

    return (
        metrics &&
        metrics.length > 0 && (
            <div className={_getClassName()}>{_getMetrics()}</div>
        )
    );
}

RouteCardSummary.propTypes = {
    /** additional css classes to attach to this component */
    className: PT.string
};

export default RouteCardSummary;
