import axios from 'axios';
import constants from '~/utils/constants';

export default {
    path: '/courier_schedules',
    post({ taskAssignerId, driverGroupId, close_routes }) {
        if (!taskAssignerId || !driverGroupId) {
            return Promise.reject('missing taskAssignerID or driverGroupId');
        }

        return axios.post(
            this.path,
            { taskAssignerId, driverGroupId, close_routes },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    },
    stopScheduler(taskAssignerId) {
        if (!taskAssignerId) {
            return Promise.reject('missing taskAssignerID');
        }

        return axios.post(
            `${this.path}?action=stop`,
            { taskAssignerId },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    }
};
