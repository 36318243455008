import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { Icon } from '~/ui';

import './card.scss';

function Card({ className, children }) {
    function _getClassName() {
        let boxClassName = 'card';
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }

    return <div className={_getClassName()}>{children}</div>;
}

function CardHeader({ className, children }) {
    function _getClassName() {
        let boxClassName = 'card-header';
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }

    return <div className={_getClassName()}>{children}</div>;
}

function CardBody({ className, children, onMouseEnter, onMouseLeave }) {
    function _getClassName() {
        let boxClassName = 'card-body';
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }

    return (
        <div
            className={_getClassName()}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {children}
        </div>
    );
}

function CardFooter({ className, children }) {
    function _getClassName() {
        let boxClassName = 'card-footer';
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }

    return <div className={_getClassName()}>{children}</div>;
}

function CardCurrentDriver({ className, title, text }) {
    function _getClassName() {
        let boxClassName = 'card-currentdriver';
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }

    return (
        <div className={_getClassName()}>
            {title && <div className="card-title _text-4-alt">{title}</div>}
            {text && <div className="card-text _text-2">{text}</div>}
        </div>
    );
}

function CollapsibleCard({
    className,
    initOpen = false,
    cardHeader,
    children
}) {
    const [isOpen, setIsOpen] = useState(initOpen);

    useEffect(() => {
        setIsOpen(initOpen);
    }, [initOpen]);

    function _toggleIsOpen() {
        setIsOpen(!isOpen);
    }

    function _getBodyClassName() {
        const baseClassName = 'card-collapsiblebody';
        const conditionalClasses = {
            collapsed: !isOpen
        };
        return classNames(baseClassName, conditionalClasses);
    }

    return (
        <Card className={className}>
            <button
                className="card-collapsibleheader"
                type="button"
                onClick={_toggleIsOpen}
            >
                {cardHeader}
                <Icon
                    className="card-collapsibleheader_chevron"
                    icon="chevronDown"
                    data-is-open={isOpen}
                />
            </button>
            <div className={_getBodyClassName()}>{children}</div>
        </Card>
    );
}

export {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardCurrentDriver,
    CollapsibleCard
};
