import React, { useState, useRef } from 'react';
import { IconButton, TextInput } from '~/ui';
import './EditableText.css';

function EditableText({
    mainContent,
    handleSubmit,
    index = 0,
    isEditable = false
}) {
    const [isTextInput, setIsTextInput] = useState(false);
    const textInputRef = useRef(null);
    const className = isEditable ? 'editabletext_enabled' : '';
    let newTextContent = { index };

    function onClickCheck() {
        if (newTextContent.value && newTextContent.value !== mainContent) {
            handleSubmit(newTextContent);
        }
        setIsTextInput(false);
    }

    function onClickCancel() {
        setIsTextInput(false);
    }

    function handleOnChange(value) {
        if (value) newTextContent = { ...newTextContent, value };
    }

    function onClickDetail() {
        if (isEditable) setIsTextInput(!isTextInput);
    }

    function getMainContent() {
        return (
            <>
                <TextInput
                    ref={textInputRef}
                    value={mainContent}
                    width="fullWidth"
                    variant="edit"
                    onChange={handleOnChange}
                />
                <div className="_d-flex _jc-flex-end">
                    <div className="_d-inline _ml-2">
                        <IconButton
                            className="editabletext-button"
                            icon="cancel"
                            iconSize="s"
                            iconColor="meteor"
                            onClick={onClickCancel}
                        />
                    </div>
                    <div className="_d-inline _ml-2">
                        <IconButton
                            className="editabletext-button"
                            icon="checkLine"
                            iconSize="s"
                            onClick={onClickCheck}
                        />
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            {isTextInput ? (
                getMainContent()
            ) : (
                <span
                    className={`editabletext ${className}`}
                    onClick={onClickDetail}
                    onKeyDown={onClickDetail}
                    role="button"
                    tabIndex={0}
                >
                    {mainContent}
                </span>
            )}
        </>
    );
}

export default EditableText;
