import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';

export const filteredOutRoutesSlice = createSlice({
    name: 'filteredOutRoutes',
    initialState: null,
    reducers: {
        setFilteredOutRoutes: (state, action) => {
            state = action.payload;
            return state;
        },
        resetFilteredOutRoutes: () => {
            return null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return null;
        });
    }
});

// export action creators
export const { setFilteredOutRoutes, resetFilteredOutRoutes } =
    filteredOutRoutesSlice.actions;

// export selector
export const selectFilteredOutRoutes = (state) => state.filteredOutRoutes;

// export selectedRoutes reducer
export default filteredOutRoutesSlice.reducer;
