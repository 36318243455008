import React from 'react';
import { Icon, TooltipButton } from '~/ui';
import './icon-button.scss';

function IconButton({
    className,
    style,
    disabled,
    visible = true,
    text,
    textColor,
    textStyle,
    icon,
    iconSize = 'm',
    iconColor,
    tooltipMessage = '',
    tooltipPlacement = 'bottom',
    onClick,
    onMouseEnter,
    onMouseLeave
}) {
    function _getClassName() {
        let boxClassName = 'icon-button';
        boxClassName =
            (icon && text && `${boxClassName} icon-button_text-over-icon`) ||
            boxClassName;
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }

    function _getButtonContents() {
        if (icon && text) {
            return (
                <>
                    {_getButtonIcon()}
                    {_getButtonText()}
                </>
            );
        }

        if (icon) {
            return _getButtonIcon();
        }

        return _getButtonText();
    }

    function _getButtonIcon() {
        return <Icon icon={icon} size={iconSize} color={iconColor} />;
    }

    function _getButtonText() {
        return (
            <span
                className="icon-button_text"
                style={{ color: textColor, ...textStyle }}
            >
                {text}
            </span>
        );
    }

    return (
        visible && (
            <TooltipButton
                className={_getClassName()}
                style={style}
                disabled={disabled}
                visible={visible}
                tooltipMessage={tooltipMessage}
                tooltipPlacement={tooltipPlacement}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {_getButtonContents()}
            </TooltipButton>
        )
    );
}

export default IconButton;
