import React from 'react';
import classNames from 'classnames';
import { Tooltip } from '~/ui';
import './tooltipbutton.scss';

function TooltipButton({
    children,
    className,
    style,
    disabled,
    variant,
    visible = true,
    tooltipMessage = '',
    tooltipPlacement = 'bottom',
    alignItems = 'center',
    flexDirection,
    flexWrap,
    justifyContent = 'center',
    onClick,
    onMouseEnter,
    onMouseLeave
}) {
    function _getClassName() {
        const defaultClassName = 'tooltipbutton';
        const conditionalClasses = {
            [`${defaultClassName}_${variant}`]: variant,
            '_d-flex': true,
            [`_fd-${flexDirection}`]: flexDirection,
            [`_fw-${flexWrap}`]: flexWrap,
            [`_jc-${justifyContent}`]: justifyContent,
            [`_ai-${alignItems}`]: alignItems
        };
        return classNames(defaultClassName, conditionalClasses, className);
    }

    return (
        visible && (
            <Tooltip content={tooltipMessage} placement={tooltipPlacement}>
                <button
                    className={_getClassName()}
                    type="button"
                    style={style}
                    disabled={disabled}
                    onClick={onClick}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                >
                    {children}
                </button>
            </Tooltip>
        )
    );
}

export default TooltipButton;
