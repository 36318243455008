import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import constants from '~/utils/constants';
import { selectForecast } from '~/reducers/forecastSlice';
import { selectBookingMetrics } from '~/reducers/bookingMetricsSlice';
import { selectRoutePlanMetrics } from '~/reducers/routePlanMetricsSlice';
import { resetToInitialState } from '~/reducers/common-actions';
import workflowEventsApi from '~/api/workflow-events';
import dateUtils from '~/utils/date-utils-converters';

const { routePhase, driverPhase, taskPhase } = constants;

export const clickedMarkTasks = createAsyncThunk(
    'workflow/clickedMarkTasks',
    async (newState, { getState }) => {
        const eventName = newState
            ? constants.workflowEvents.TASK_VERIFICATION_COMPLETED
            : constants.workflowEvents.TASK_VERIFICATION_UNCOMPLETED;
        const routeDate = getState().selectedDate;

        await workflowEventsApi.post({
            name: eventName,
            route_date: dateUtils.convertToISODateOnly(routeDate)
        });

        return { tasksMarked: newState, routeDate };
    }
);

export const clickedMarkDrivers = createAsyncThunk(
    'workflow/clickedMarkDrivers',
    async (newState, { getState }) => {
        const eventName = newState
            ? constants.workflowEvents.DRIVER_BOOKING_COMPLETED
            : constants.workflowEvents.DRIVER_BOOKING_UNCOMPLETED;
        const routeDate = getState().selectedDate;

        await workflowEventsApi.post({
            name: eventName,
            route_date: dateUtils.convertToISODateOnly(routeDate)
        });

        return { driversMarked: newState, routeDate };
    }
);

export const clickedMarkRoutes = createAsyncThunk(
    'workflow/clickedMarkRoutes',
    async (newState, { getState }) => {
        const eventName = newState
            ? constants.workflowEvents.OPTIMIZATION_COMPLETED
            : constants.workflowEvents.OPTIMIZATION_UNCOMPLETED;
        const routeDate = getState().selectedDate;

        await workflowEventsApi.post({
            name: eventName,
            route_date: dateUtils.convertToISODateOnly(routeDate)
        });

        return { routesMarked: newState, routeDate };
    }
);

export const workflowSlice = createSlice({
    name: 'workflow',
    initialState: {},
    reducers: {
        setDriversMarked: (state, action) => {
            const { routeDate, driversMarked } = action.payload;
            state = fetchRouteDateState(state, routeDate);
            state[routeDate].driversMarked = driversMarked;
            return state;
        },
        setTasksMarked: (state, action) => {
            const { routeDate, tasksMarked } = action.payload;
            state = fetchRouteDateState(state, routeDate);
            state[routeDate].tasksMarked = tasksMarked;
            return state;
        },
        setRoutesMarked: (state, action) => {
            const { routeDate, routesMarked } = action.payload;
            state = fetchRouteDateState(state, routeDate);
            state[routeDate].routesMarked = routesMarked;
            return state;
        },
        setRoutesScheduling: (state, action) => {
            const { routeDate, routesScheduling } = action.payload;
            state = fetchRouteDateState(state, routeDate);
            state[routeDate].routesScheduling = routesScheduling;
            return state;
        },
        setScheduled: (state, action) => {
            const { routeDate, isScheduled } = action.payload;
            state = fetchRouteDateState(state, routeDate);
            state[routeDate].isScheduled = isScheduled;
            return state;
        },
        setReceivedTasks: (state, action) => {
            const { routeDate, receivedTasks } = action.payload;
            state = fetchRouteDateState(state, routeDate);
            state[routeDate].receivedTasks = receivedTasks;
            return state;
        },
        setHasDriverAccess: (state, action) => {
            const { routeDate, hasDriverAccess } = action.payload;
            state = fetchRouteDateState(state, routeDate);
            state[routeDate].hasDriverAccess = hasDriverAccess;
            return state;
        },
        setHasPlanAccess: (state, action) => {
            const { routeDate, hasPlanAccess } = action.payload;
            state = fetchRouteDateState(state, routeDate);
            state[routeDate].hasPlanAccess = hasPlanAccess;
            return state;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return {};
        });
        builder.addCase(clickedMarkTasks.fulfilled, (state, { payload }) => {
            const { tasksMarked, routeDate } = payload;
            state[routeDate].tasksMarked = tasksMarked;
        });
        builder.addCase(clickedMarkDrivers.fulfilled, (state, { payload }) => {
            const { driversMarked, routeDate } = payload;
            state[routeDate].driversMarked = driversMarked;
        });
        builder.addCase(clickedMarkRoutes.fulfilled, (state, { payload }) => {
            const { routesMarked, routeDate } = payload;
            state[routeDate].routesMarked = routesMarked;
        });
    }
});

export const {
    setDriversMarked,
    setTasksMarked,
    setRoutesMarked,
    setRoutesScheduling,
    setScheduled,
    setReceivedTasks,
    setHasDriverAccess,
    setHasPlanAccess
} = workflowSlice.actions;

export const selectDriverPhase = (state, routeDate) => {
    const estimated = selectForecast(state)?.total_routes;
    const confirmed = getRequestsCount(state, [
        constants.bookingStatus.CONFIRMED
    ]);

    if (state.workflow[routeDate]?.driversMarked) return driverPhase.Marked;

    if (!estimated) return driverPhase.Initial;

    if (!confirmed) return driverPhase.DataGathered;

    if (confirmed >= estimated) return driverPhase.CompletedNotMarked;

    return driverPhase.InProgress;
};

export const selectTaskPhase = (state, routeDate) => {
    const received = state.workflow[routeDate]?.receivedTasks || false;

    if (state.workflow[routeDate]?.tasksMarked) return taskPhase.Marked;

    if (!received) return taskPhase.Initial;

    return taskPhase.DataGathered;
};

export const selectRoutesPhase = (state, routeDate) => {
    const workflowState = state.workflow[routeDate];

    if (workflowState?.routesScheduling) return routePhase.Scheduling;
    if (workflowState?.routesMarked) return routePhase.Marked;
    if (workflowState?.driversMarked && workflowState?.tasksMarked) {
        if (workflowState?.hasPlanAccess) {
            return routePhase.CanBeClicked;
        }
        return routePhase.CanBeClickedNoPlanAccess;
    }
    if (workflowState?.hasDriverAccess) {
        if (workflowState?.hasPlanAccess) {
            return routePhase.Initial;
        }
        return routePhase.InitialNoDriverAccess;
    }
    return routePhase.InitialNoDriverOrPlanAccess;
};

export const selectWorkflow = (state, routeDate) => {
    // TODO: replace data
    const tempData = 0; // feature not implemented. using falsy value
    const forecast = selectForecast(state);
    const routePlanMetrics = selectRoutePlanMetrics(state);

    return {
        drivers: {
            actualNeeded: 0,
            confirmed: getRequestsCount(state, [
                constants.bookingStatus.CONFIRMED
            ]),
            estimated: forecast?.total_routes,
            phase: selectDriverPhase(state, routeDate)
        },
        tasks: {
            received: state.workflow[routeDate]?.receivedTasks || false,
            scheduled:
                routePlanMetrics.numberOfTasks -
                routePlanMetrics.numberOfUnassignedTasks,
            unassigned: routePlanMetrics.numberOfUnassignedTasks,
            phase: selectTaskPhase(state, routeDate)
        },
        routes: {
            violation: tempData,
            total: routePlanMetrics.numberOfRoutes,
            phase: selectRoutesPhase(state, routeDate)
        },
        isScheduled: state.workflow[routeDate]?.isScheduled || false
    };
};

function fetchRouteDateState(state, routeDate) {
    if (!state[routeDate]) {
        state[routeDate] = {
            driversMarked: false,
            tasksMarked: false,
            routesMarked: false,
            routesScheduling: false,
            isScheduled: false,
            receivedTasks: undefined,
            hasDriverAccess: false,
            hasPlanAccess: false
        };
    }
    return state;
}

function getRequestsCount(state, statuses) {
    const bookingMetrics = selectBookingMetrics(state);
    if (bookingMetrics) {
        return Object.values(bookingMetrics).reduce(
            (counter, bookingMetric) => {
                statuses.forEach((status) => {
                    if (!bookingMetric[status]) {
                        return;
                    }
                    counter += bookingMetric[status];
                });
                return counter;
            },
            0
        );
    }

    return null;
}

export default workflowSlice.reducer;
