import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import {
    selectShowStopLabel,
    selectShowStopNumber
} from '~/reducers/mapSettingsSlice';
import constants from '~/utils/constants';
import { Icon } from '~/ui';
import './stopmarker.scss';

function StopMarker({
    className,
    number = '-1',
    label = '',
    colorCSS = {},
    data = {},
    isPlanned,
    isHighPriority,
    lat,
    lng,
    mapRouteMode = constants.mapRouteModes.PLAN,
    emittedEventHandler
}) {
    const [isDisabled, setIsDisabled] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const selectedMapStops = useSelector(selectSelectedMapStops);
    const showStopLabel = useSelector(selectShowStopLabel(mapRouteMode));
    const showStopNumber = useSelector(selectShowStopNumber(mapRouteMode));

    useEffect(() => {
        const isStopSelected = selectedMapStops.includes(
            data.clientRouteTaskId
        );
        setIsSelected(isStopSelected);
    }, [selectedMapStops]);

    useEffect(() => {
        setIsDisabled(typeof emittedEventHandler !== 'function');
    }, [emittedEventHandler]);

    function _handleMouseEnter() {
        emittedEventHandler({
            event: constants.mapChildEvents.STOP_MOUSEENTER,
            payload: {
                location: {
                    lat,
                    lng
                },
                stopMarkerData: data
            }
        });
    }

    function _handleDragStart() {
        emittedEventHandler({
            event: constants.mapChildEvents.STOP_DRAGSTART,
            payload: {
                isSelected,
                isPlanned,
                id: data.clientRouteTaskId,
                combinedIds: [data.clientRouteTaskId],
                clientRouteId: data.clientRouteId,
                type: constants.mapChildEvents.STOP_DRAGSTART,
                selectedMapStops
            }
        });
    }

    function _handleMouseUp() {
        emittedEventHandler({
            event: constants.mapChildEvents.STOP_MOUSEUP,
            payload: {
                isSelected,
                isTwoPart: data.isTwoPart,
                isPlanned,
                id: data.clientRouteTaskId,
                routeDate: data.routeDate,
                clientRouteId: data.clientRouteId,
                selectedMapStops,
                taskId: data.taskId,
                stopLevelData: data.toJSON()
            }
        });
    }

    function _getClassName() {
        let boxClassName = 'stopmarker';
        boxClassName =
            (isSelected && `${boxClassName} stopmarker_selected`) ||
            boxClassName;
        boxClassName =
            (isHighPriority && `${boxClassName} stopmarker-star`) ||
            boxClassName;
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }

    function _getMarkerBody() {
        if (isHighPriority) {
            return (
                <>
                    <Icon
                        color={colorCSS.backgroundColor}
                        fillOpacity={colorCSS.opacity || 1}
                        icon="stopPriority"
                        size="xl"
                    />
                    {showStopNumber && (
                        <span className="stopmarker-star_number">{number}</span>
                    )}
                </>
            );
        }
        return (
            <span className="stopmarker-circle" style={colorCSS}>
                {showStopNumber && number}
            </span>
        );
    }

    return (
        <button
            type="button"
            className={_getClassName()}
            onMouseEnter={_handleMouseEnter}
            draggable="true"
            onDragStart={_handleDragStart}
            onMouseUp={_handleMouseUp}
            disabled={isDisabled}
        >
            <div className="stopmarker-body">{_getMarkerBody()}</div>
            {showStopLabel && <span className="stopmarker-label">{label}</span>}
        </button>
    );
}

export default StopMarker;
