import socketModule from './socket-module';

function connectToSocketServer(socketUrl, accessToken) {
    socketModule.connectToServer({
        socketUrl,
        accessToken
    });
}

function joinRooms(clientIds) {
    socketModule.joinClientRooms({
        clientIds
    });
}

function leaveRooms(clientIds) {
    socketModule.leaveRooms({
        clientIds
    });
}

export default {
    connectToSocketServer,
    joinRooms,
    leaveRooms
};
