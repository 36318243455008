import { useEffect, useState } from 'react';
import { webMulticlientAPI } from '~/api/web-multiclient';
import constants from '~/utils/constants';

export const useWebInterval = (mapRouteMode, activeClients) => {
    const [webInterval, setWebInterval] = useState(0);
    const [clientsInInterval, setClientsInInterval] = useState({});
    const getWebMulticlient = async (activeClientIds) => {
        try {
            await webMulticlientAPI.get(activeClientIds);
        } catch (err) {
            console.error('Failed to get web messages:', err);
        }
    };

    /**
     * Fetch all drivers once on page load, to allow map search to work no matter what
     * `mapRouteMode` the page starts on
     */
    useEffect(() => {
        const clientIds = Object.keys(clientsInInterval);
        getWebMulticlient(clientIds);
    }, []);

    useEffect(() => {
        const activeClientIds = Object.keys(activeClients);
        const allClientsInInterval =
            webInterval &&
            activeClientIds.every((clientId) => !!clientsInInterval[clientId]);
        if (allClientsInInterval) {
            return;
        }
        setClientsInInterval({ ...activeClients });
    }, [activeClients]);

    useEffect(() => {
        if (mapRouteMode === constants.mapRouteModes.DISPATCHED) {
            const clientIds = Object.keys(clientsInInterval);
            getWebMulticlient(clientIds);
            setWebInterval(
                setInterval(() => {
                    getWebMulticlient(clientIds);
                }, constants.timings.GET_WEB_INTERVAL)
            );
        } else {
            clearInterval(webInterval);
            setWebInterval(0);
        }
    }, [mapRouteMode, clientsInInterval]);
};
