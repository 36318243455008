import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
    addSelectedMapRoute,
    removeSelectedMapRoute,
    selectSelectedMapRoutes
} from '~/reducers/selectedMapRoutesSlice';
import { setSelectedDrawerCardData } from '~/reducers/selectedDrawerCardDataSlice';
import { setSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import { selectClients } from '~/reducers/clientsSlice';
import { setIsOpenSelectedCardsDrawer } from '~/reducers/mapDrawerSettingsSlice';
import {
    setMapMarkerMode,
    selectShowRouteLabel,
    selectShowRoutePopup,
    setShouldFitPlanMapToBounds,
    selectViewCardDetails,
    selectIsMultipleCardSelectEnabled
} from '~/reducers/mapSettingsSlice';
import { Icon } from '~/ui';
import constants from '~/utils/constants';
import filterUtils from '~/utils/filter-utils';

import PlanRoute from '~/data-classes/plan/PlanRoute';
import './routemarker.scss';

function RouteMarker({
    routeLevelData,
    lat,
    lng,
    mapRouteMode = constants.mapRouteModes.PLAN,
    emittedEventHandler,
    colorCSS,
    hoverPopup,
    selectable
}) {
    const { t } = useTranslation('translation');
    const [isSelected, setIsSelected] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const selectedMapRoute = useSelector(selectSelectedMapRoutes);
    const showRouteLabel = useSelector(selectShowRouteLabel(mapRouteMode));
    const showPopup = useSelector(selectShowRoutePopup(mapRouteMode));
    const viewCardDetails = useSelector(selectViewCardDetails);
    const isMultipleCardSelectEnabled = useSelector(
        selectIsMultipleCardSelectEnabled
    );
    const dispatch = useDispatch();
    const clients = useSelector(selectClients);
    const { clientRouteId, isPlanned, vehicleEid } = routeLevelData;

    const shouldRenderStops = !isMultipleCardSelectEnabled && !viewCardDetails;

    let label = '';
    if (isPlanned) {
        label = t('routeName', {
            routeName: vehicleEid
        });
    } else {
        const { clientId } = routeLevelData;
        const clientName = clients[clientId]?.name || '';
        label = filterUtils.getInitials(clientName);
    }
    useEffect(() => {
        const isMarkerSelected = selectedMapRoute.includes(clientRouteId);
        setIsSelected(isMarkerSelected);
    }, [selectedMapRoute]);

    function _toggleIsSelected() {
        if (selectable) {
            if (shouldRenderStops) {
                dispatch(
                    setMapMarkerMode(constants.mapMarkerModes.STOPS_CLUSTERS)
                );
                const routeData =
                    routeLevelData instanceof PlanRoute
                        ? routeLevelData.toJSON()
                        : routeLevelData;
                dispatch(setShouldFitPlanMapToBounds(true));
                dispatch(setSelectedDrawerCardId(clientRouteId));
                dispatch(setSelectedDrawerCardData(routeData));
                dispatch(setIsOpenSelectedCardsDrawer(true));
            }
            dispatch(
                isSelected
                    ? removeSelectedMapRoute(clientRouteId)
                    : addSelectedMapRoute(clientRouteId)
            );
        }
    }

    function _handleMouseEnter() {
        if (emittedEventHandler) {
            emittedEventHandler({
                event: constants.mapChildEvents.ROUTE_MOUSEENTER,
                payload: {
                    location: {
                        lat,
                        lng
                    },
                    routeLevelData
                }
            });
        }
        setIsHovered(true);
    }

    function _handleMouseLeave() {
        if (emittedEventHandler) {
            emittedEventHandler({
                event: constants.mapChildEvents.ROUTE_MOUSELEAVE,
                payload: {
                    clientRouteId
                }
            });
        }
        setIsHovered(false);
    }

    function _getClassName() {
        let boxClassName = 'routemarker';
        boxClassName =
            (isSelected && `${boxClassName} routemarker_selected`) ||
            boxClassName;
        return boxClassName;
    }

    return (
        <button
            type="button"
            className={_getClassName()}
            onClick={_toggleIsSelected}
            onMouseEnter={_handleMouseEnter}
            onMouseLeave={_handleMouseLeave}
            onFocus={_handleMouseEnter}
        >
            <span className="routemarker-circle" style={colorCSS}>
                {!isSelected && <Icon color="white" icon="route" />}
                {isSelected && <Icon color="white" icon="checkmark" />}
            </span>
            {showRouteLabel && (
                <span className="routemarker-label">{label}</span>
            )}
            {showPopup && isHovered ? hoverPopup : null}
        </button>
    );
}
export default RouteMarker;
