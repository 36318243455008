import { Contact, IPrivateApiFields, TimeWindow } from './CommonTypes';

/**
 * Numerical enum of task priority value. Sequence starts at 1
 * representing a high priority task
 * @category API Types
 */
export enum TaskPriority {
    HIGH = 1,
    MEDIUM,
    LOW
}

/**
 * Numerical enum of task statuses.
 * @category API Types
 */
export enum TaskStatus {
    UNASSIGNED,
    ASSIGNED,
    IN_PROGRESS,
    COMPLETED,
    FAILED
}

/**
 * Type agnostic task props fields
 * @category API Types
 */
interface TypeAgnosticTaskProps {
    /**
     * the task priority
     */
    priority: TaskPriority;
    /**
     * the task size
     */
    size: number;
    /**
     * the task weight
     */
    weight: number;
    /**
     * indicates the task is eligible for pairing
     */
    pairingEligible: boolean;
    /**
     * the task workflows
     */
    workflow?: {
        requireSignature?: boolean;
        promptSignature?: boolean;
    };
    /**
     * the task pickup time. Included in type agnostic props as it
     * will be included as an explicit null in delivery tasks.
     */
    pickupTime: string | null;
    /**
     * the task delivery time. Included in type agnostic props as it
     * will be included as an explicit null in pickup tasks.
     */
    deliveryTime: string | null;
}

/**
 * Pickup task props fields
 * @category API Types
 */
interface PickupTaskProps extends TypeAgnosticTaskProps {
    /**
     * the pickup service time
     */
    pickupServiceTime: string;
    /**
     * the pickup time windows
     */
    pickupWindow: Array<TimeWindow>;
    /**
     * the pickup inventory
     * @todo write type definition for inventory item
     */
    pickupInventory?: Array<Record<string, unknown>>;
    /**
     * the original pickup task props
     */
    originalPickup?: Pick<ApiTask, 'labels'>;
}

/**
 * Delivery task props fields
 * @category API Types
 */
interface DeliveryTaskProps extends TypeAgnosticTaskProps {
    /**
     * the delivery service time
     */
    deliveryServiceTime: string;
    /**
     * the delivery time windows
     */
    deliveryWindow: Array<TimeWindow>;
    /**
     * the delivery inventory
     * @todo write type definition for inventory item
     */
    deliveryInventory?: Array<Record<string, unknown>>;
    /**
     * the original pickup task props
     */
    originaldelivery?: Pick<ApiTask, 'labels'>;
}

// JSDoc below type declaration is intentional.
// Better-docs cannot convert type intersections and unions
export type ApiTaskProps =
    | PickupTaskProps
    | DeliveryTaskProps
    | (PickupTaskProps & DeliveryTaskProps);

/**
 * The API Task props type. This type can be used for the props of a pickup,
 * delivery or two-part task.
 * @category API Types
 * @typedef ApiTaskProps
 * @type {(PickupTaskProps | DeliveryTaskProps | {...PickupTaskProps, ...DeliveryTaskProps})}
 */

/**
 * Type agnostic task fields
 * @category API Types
 */
interface TypeAgnosticTask extends IPrivateApiFields {
    /**
     * the task id
     */
    id: string;
    /**
     * the task deduplication id
     */
    deduplicationId: string;
    /**
     * the task consolidation id
     */
    consolidationId: string;
    /**
     * the task route date. ISO-8601 date format
     */
    routeDate: string;
    /**
     * the task name
     */
    name: string;
    /**
     * the task status
     */
    status: TaskStatus;
    /**
     * the task eid
     */
    eid?: number;
    /**
     * the task euid
     */
    euid: string | null;
    /**
     * the task route id
     */
    routeEid?: string;
    /**
     * the eid of the vehicle associted to this task
     */
    vehicleEid?: string;
    /**
     * indicates the task is locked to a route
     */
    locked: boolean;
    /**
     * indicates the task is locked to a specific position in a route
     */
    orderLocked: boolean;
    /**
     * id of the route this task is planned for
     */
    routeId?: string;
    /**
     * the task forecast type
     */
    forecastType?: string;
    /**
     * types of vehicles required by this task
     */
    vehicleTypes: Array<string>;
    /**
     * the task labels
     */
    labels: Array<string>;
    /**
     * the task eorder
     */
    eorder?: number;
    /**
     * client this task belongs to
     */
    client: string;
    /**
     * notification settings for this task
     */
    notification: {
        /**
         * indicates sms notifications are enabled
         */
        sms: boolean;
        /**
         * indicates email notifications are enabled
         */
        email: boolean;
    };
    /**
     * the 2-part task id
     * primarily to uniquely track the delivery and pickup task data for loops as keys
     */
    twoPartId?: string;
    /**
     * ISO-8601 timestamp of when the task was created
     */
    createdAt: string;
    /**
     * ISO-8601 timestamp of when the task was last updated
     */
    updatedAt: string;
}

/**
 * Pickup task fields
 * @category API Types
 */
export interface PickupTask extends TypeAgnosticTask {
    /**
     * the task pickup contact
     */
    pickupContact: Contact;
    /**
     * the task pickup location
     * @todo write type definitions for addresses
     */
    pickupLocation: Record<string, unknown>;
    /**
     * the pickup task props
     */
    props: TypeAgnosticTaskProps & PickupTaskProps;
    /**
     * the original delivery task props
     */
    originalProps?: Partial<TypeAgnosticTaskProps & PickupTaskProps>;
}

/**
 * Delivery task fields
 * @category API Types
 */
export interface DeliveryTask extends TypeAgnosticTask {
    /**
     * the task delivery contact
     */
    deliveryContact: Contact;
    /**
     * the task delivery location
     * @todo write type definitions for addresses
     */
    deliveryLocation: Record<string, unknown>;
    /**
     * the delivery task props
     */
    props: TypeAgnosticTaskProps & DeliveryTaskProps;
    /**
     * the original delivery task props
     */
    originalProps?: Partial<TypeAgnosticTaskProps & DeliveryTaskProps>;
}

// JSDoc below type declaration is intentional.
// Better-docs cannot convert type intersections and unions
export type ApiTask = PickupTask | DeliveryTask | (PickupTask & DeliveryTask);

/**
 * The API Task type. This type can be used for a pickup, a delivery,
 * or a two-part task.
 * @category API Types
 * @typedef ApiTask
 * @type {(PickupTask | DeliveryTask | {...PickupTask, ...DeliveryTask})}
 */
