import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { selectDriverLocationById } from '~/reducers/driversLocationsSlice';
import { selectShowDriverLines } from '~/reducers/mapSettingsSlice';
import constants from '~/utils/constants';
import { mapDrawingUtils } from '~/utils/map/map-drawing-utils';
import { theme } from '~/ui';

export default function RouteLine({
    mapInstance,
    mapRouteMode,
    driver,
    showHistory,
    colorCSS
}) {
    if (!mapInstance || !driver || !colorCSS) return null;

    const locationHistory = useSelector(selectDriverLocationById(driver.id));
    const showDriverLines = useSelector(selectShowDriverLines(mapRouteMode));
    const projectionLine = useRef(null);
    const historyLine = useRef(null);

    function updateLine(path, lineRef, strokeColor) {
        if (lineRef.current) {
            lineRef.current.setPath(path);
            lineRef.current.setOptions({
                strokeColor
            });
        } else {
            lineRef.current = new google.maps.Polyline({
                path,
                strokeColor,
                strokeWeight:
                    constants.mapsDrawingStyles.DRIVER_PATH_STROKE_WEIGHT,
                strokeOpacity: 1
            });
        }
    }

    // Update projection line
    useEffect(() => {
        const futurePath = driver.schedule
            .slice(driver.currentStopIndex, driver.schedule.length)
            .filter((stop) => !stop.isDepot)
            .map((stop) => stop.location);
        futurePath.unshift(driver.location);

        updateLine(futurePath, projectionLine, colorCSS.backgroundColor);

        if (showDriverLines) {
            mapDrawingUtils.drawMapObject(projectionLine.current, mapInstance);
        }

        return () => {
            mapDrawingUtils.removeMapObject(projectionLine.current);
        };
    }, [
        driver.currentStopIndex,
        driver.location,
        driver.toJSON().schedule,
        colorCSS
    ]);

    // Update history line
    useEffect(() => {
        if (
            !showHistory ||
            !locationHistory ||
            !Array.isArray(locationHistory)
        ) {
            return;
        }
        const historyPath = locationHistory.map((lh) =>
            _.pick(lh, 'lat', 'lng')
        );
        historyPath.push(driver.location);

        updateLine(historyPath, historyLine, theme.colors['galaxy-400']);

        if (showDriverLines) {
            mapDrawingUtils.drawMapObject(historyLine.current, mapInstance);
        }

        return () => {
            mapDrawingUtils.removeMapObject(historyLine.current);
        };
    }, [driver.location, locationHistory, colorCSS]);

    // Show/hide line based on settings
    useEffect(() => {
        if (showDriverLines) {
            if (projectionLine.current) {
                mapDrawingUtils.drawMapObject(
                    projectionLine.current,
                    mapInstance
                );
            }
            if (historyLine.current) {
                if (showHistory) {
                    mapDrawingUtils.drawMapObject(
                        historyLine.current,
                        mapInstance
                    );
                } else {
                    mapDrawingUtils.removeMapObject(
                        historyLine.current,
                        mapInstance
                    );
                }
            }
        } else {
            if (projectionLine.current) {
                mapDrawingUtils.removeMapObject(
                    projectionLine.current,
                    mapInstance
                );
            }
            if (historyLine.current) {
                mapDrawingUtils.removeMapObject(
                    historyLine.current,
                    mapInstance
                );
            }
        }
    }, [showDriverLines, showHistory, mapInstance]);

    return null;
}
