import PT from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { Box } from '~/ui';

const FlexLayout = React.forwardRef(function FlexLayout(
    {
        className,
        alignItems,
        children,
        flex,
        flexDirection,
        flexWrap,
        justifyContent,
        visibility = true,
        sx = {},
        ...extra
    },
    ref
) {
    function _getClassName() {
        const defaultClassName = 'flexlayout';
        const conditionalClasses = {
            '_d-flex': visibility,
            '_d-none': !visibility,
            [`_fd-${flexDirection}`]: flexDirection,
            [`_fw-${flexWrap}`]: flexWrap,
            [`_jc-${justifyContent}`]: justifyContent,
            [`_ai-${alignItems}`]: alignItems
        };
        return classNames(defaultClassName, conditionalClasses, className);
    }

    return (
        <Box
            className={_getClassName()}
            ref={ref}
            sx={{
                ...sx,
                flex
            }}
            {...extra}
        >
            {children}
        </Box>
    );
});

FlexLayout.propTypes = {
    className: PT.string,
    alignItems: PT.string,
    children: PT.node, // React children components
    flex: PT.string,
    flexDirection: PT.string,
    flexWrap: PT.string,
    justifyContent: PT.string,
    sx: PT.oneOfType([PT.object]),
    visibility: PT.bool
};

export default FlexLayout;
