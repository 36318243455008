import React from 'react';

import { ResequenceModalHeader } from './ResequenceModalHeader';
import { ResequenceModalContent } from './ResequenceModalContent';

import './resequence-modal.scss';

export const ResequenceModal = ({
    clickHandler,
    isDifferentRoutesSelected,
    selectedStopsData,
    unselectedStopsData,
    stopIdPropertyName,
    onResequence,
    isLiveStopControl
}) => {
    const numberOfSelectedStops = selectedStopsData.length;

    return (
        <div className="resequencemodal">
            <ResequenceModalHeader
                clickHandler={clickHandler}
                numberOfStops={numberOfSelectedStops}
            />
            <ResequenceModalContent
                isDifferentRoutes={isDifferentRoutesSelected}
                selectedStops={selectedStopsData}
                unselectedStops={unselectedStopsData}
                onResequence={onResequence}
                stopIdPropertyName={stopIdPropertyName}
                isLiveStopControl={isLiveStopControl}
            />
        </div>
    );
};
