import PT from 'prop-types';
import React from 'react';
import { Box as ThemeBox } from 'theme-ui';
import { styles } from '~/ui';

const Box = React.forwardRef(function Box({ sx, ...extra }, ref) {
    const clickableStyles =
        extra.disabled || extra.onClick ? styles.interactions.clickable : {};
    return <ThemeBox ref={ref} sx={{ ...clickableStyles, ...sx }} {...extra} />;
});

Box.propTypes = {
    /** Supports the sx prop */
    sx: PT.oneOfType([PT.object])
};

export default Box;
