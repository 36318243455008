import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Text, TextInput } from '~/ui';

export const ResequenceModalSearch = ({
    selectedStopId,
    setSelectedStopId,
    stopIdPropertyName,
    stops
}) => {
    const { t } = useTranslation('translation');
    const [inputValue, setInputValue] = useState('');
    const inputPlaceholder = t('ResequenceModal.Search.input_placeholder');
    const depotLabel = t('ResequenceModal.Search.depot_label');

    const mapStopToNumberAndNameText = useCallback(
        ({ stopNumber, stopName, isDepot }) =>
            `${stopNumber}. ${stopName}${isDepot ? ` ${depotLabel}` : ''}`,
        [depotLabel]
    );

    const filteredStops = useMemo(
        () =>
            stops.filter(
                (stop) =>
                    mapStopToNumberAndNameText(stop)
                        .toLowerCase()
                        .indexOf(inputValue.toLowerCase()) >= 0
            ),
        [inputValue, stops, mapStopToNumberAndNameText]
    );

    const selectedStop = filteredStops.find(
        (stop) => stop[stopIdPropertyName] === selectedStopId
    );

    const isShowDropdown = useMemo(
        () => Boolean(inputValue) && filteredStops.length > 0 && !selectedStop,
        [inputValue, selectedStop, filteredStops]
    );

    const handleInputChange = useCallback(
        (value) => {
            const foundStopByInputValue = filteredStops.find(
                (stop) => value === mapStopToNumberAndNameText(stop)
            );

            if (selectedStop && selectedStop !== foundStopByInputValue)
                setSelectedStopId(null);

            setInputValue(value);
        },
        [filteredStops, mapStopToNumberAndNameText, selectedStop]
    );

    return (
        <div className="resequencemodal-search">
            <TextInput
                placeholder={inputPlaceholder}
                type="text"
                value={inputValue}
                variant="basic"
                onChange={handleInputChange}
            />
            {isShowDropdown && (
                <div className="resequencemodal-search-dropwdown">
                    {filteredStops.map((stop) => {
                        const { stopNumber, stopName, isDepot } = stop;

                        return (
                            <Button
                                key={stop[stopIdPropertyName]}
                                className="_ai-center _jc-flexst"
                                onClick={() => {
                                    setInputValue(
                                        mapStopToNumberAndNameText({
                                            stopNumber,
                                            stopName,
                                            isDepot
                                        })
                                    );
                                    setSelectedStopId(stop[stopIdPropertyName]);
                                }}
                            >
                                <Text className="_text-3" variant="12-normal">
                                    {mapStopToNumberAndNameText({
                                        stopNumber,
                                        stopName,
                                        isDepot
                                    })}
                                </Text>
                            </Button>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
