function getMiles(meters) {
    return (meters * 0.000621371192).toFixed();
}

function getKilometers(meters) {
    return (meters / 1000).toFixed();
}

function getLocalDistance(meters, language) {
    const browserLanguage = language || navigator.language;
    return browserLanguage === 'en-US'
        ? this.getMiles(meters)
        : this.getKilometers(meters);
}

function getLocalDistanceUnits(language) {
    const browserLanguage = language || navigator.language;
    return browserLanguage === 'en-US'
        ? 'common:distance.miles'
        : 'common:distance.km';
}

function convertPixelstoRem(px) {
    return (px * parseFloat(getComputedStyle(document.body).fontSize)) / 100;
}

function convertRemToPixels(rem) {
    return (
        rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
    );
}

export default {
    getMiles,
    getKilometers,
    getLocalDistance,
    getLocalDistanceUnits,
    convertPixelstoRem,
    convertRemToPixels
};
