import React from 'react';
import './routecard-labels.scss';

import { Icon } from '~/ui';

function RouteCardLabels({ labels, icon, iconColor = 'galaxy-500' }) {
    const minNumOfLabels = 3;

    function _getIcon() {
        if (!icon || !icon.length) return null;
        return <Icon icon={icon} color={iconColor} marginRight="0.4rem" />;
    }

    function _getLabels() {
        const slicedLabels = labels.slice(0, minNumOfLabels);
        return labels.length > minNumOfLabels ? (
            <ul className="routecard-labels _d-flex _ai-center _jc-flex-start _fw-wrap">
                {slicedLabels.map((item) => (
                    <li key={item}>
                        <span>{item}</span>
                    </li>
                ))}
                <li key={labels.length - minNumOfLabels}>
                    <span>{labels.length - minNumOfLabels}+</span>
                </li>
            </ul>
        ) : (
            <ul className="routecard-labels _d-flex _ai-center _jc-flex-start _fw-wrap">
                {labels.map((item) => (
                    <li key={item}>
                        <span>{item}</span>
                    </li>
                ))}
            </ul>
        );
    }

    return (
        labels?.length > 0 && (
            <div className="routecard-label_icon _d-flex">
                {_getIcon()}
                {_getLabels()}
            </div>
        )
    );
}

export default RouteCardLabels;
