import React from 'react';

import { Icon } from '~/ui';

import dateUtils from '~/utils/date-utils';

import './DatePickerHeader.scss';

function ArrowButton({ icon, rotation = '90', onClick }) {
    const iconClassName = `arrowbutton-icon-${rotation}`;
    return (
        <button
            type="button"
            className="icon-wrapper icon-wrapper--dark"
            onClick={onClick}
        >
            <Icon
                icon={icon}
                color="galaxy-500"
                size="m"
                className={iconClassName}
            />
        </button>
    );
}

function DatePickerHeader({
    date,
    decreaseYear,
    increaseYear,
    decreaseMonth,
    increaseMonth
}) {
    return (
        <div className="_d-flex _ai-center _jc-space-between datepickerheader">
            <ArrowButton
                icon="doubleChevron"
                rotation="90"
                onClick={decreaseYear}
            />
            <ArrowButton
                icon="chevronDown"
                rotation="90"
                onClick={decreaseMonth}
            />
            <span className="datepickerheader-title">
                {dateUtils.formatMonthYear(date)}
            </span>
            <ArrowButton
                icon="chevronDown"
                rotation="270"
                onClick={increaseMonth}
            />
            <ArrowButton
                icon="doubleChevron"
                rotation="270"
                onClick={increaseYear}
            />
        </div>
    );
}

export default DatePickerHeader;
