import _ from 'lodash';
import { DateTime } from 'luxon';
import { idUtils } from '~/utils/id-utils';
import LiveStop from './LiveStop';

/**
 * LiveDriver data class
 *
 * @category Data Classes
 *
 * @example
 * import LiveDriver from '~/data-classes/dispatched/LiveDriver';
 *
 * const srcData = {};
 * const liveDriver = new LiveDriver(srcData);
 *
 */
class LiveDriver {
    /**
     * The constructor description
     * @param {Object} liveDriver - the source live driver data
     */
    constructor(liveDriver) {
        this._liveDriver = liveDriver;
    }

    /**
     * the live driver ID
     * @type {String}
     */
    get id() {
        return this._liveDriver.id;
    }

    /**
     * the client ID
     * @type {String}
     */
    get clientId() {
        return this._liveDriver.clientId;
    }

    /**
     * the client-driver ID
     * @type {String}
     */
    get clientDriverId() {
        return idUtils.getCombinedId(this.clientId, this.id || 'unassigned');
    }

    /**
     * the driver name
     * @type {String}
     */
    get name() {
        return this._liveDriver.profile.name;
    }

    /**
     * the driver initials
     * @type {String}
     */
    get initials() {
        return this._liveDriver.profile.initials;
    }

    /**
     * the route name
     * @type {String}
     */
    get routeName() {
        return this._liveDriver.routeName;
    }

    /**
     * indicates whether this driver encountered issues
     * @type {Boolean}
     */
    get hasIssues() {
        return (
            this._liveDriver.stats.numAtRiskExceptions ||
            this._liveDriver.stats.numLateExceptions ||
            this._liveDriver.stats.numInventoryExceptions
        );
    }

    /**
     * the number of at-risk exceptions
     * @type {Number}
     */
    get numAtRiskExceptions() {
        return this._liveDriver.stats.numAtRiskExceptions;
    }

    /**
     * the number of late exceptions
     * @type {Number}
     */
    get numLateExceptions() {
        return this._liveDriver.stats.numLateExceptions;
    }

    /**
     * the number of inventory exceptions
     * @type {Number}
     */
    get numInventoryExceptions() {
        return this._liveDriver.stats.numInventoryExceptions;
    }

    /**
     * the current stop's array index value
     * @type {Number}
     */
    get currentStopIndex() {
        return this._liveDriver.stats.currentStop;
    }

    /**
     * the number of stops
     * @type {Number}
     */
    get numStops() {
        return this._liveDriver.stats.numStops;
    }

    /**
     * indicates whether this driver has stops
     * @type {Boolean}
     */
    get hasStops() {
        return Boolean(this.numStops);
    }

    /**
     * the time remaining
     * @type {*}
     */
    get timeRemaining() {
        return this._liveDriver.stats.timeRemaining;
    }

    /**
     * the driver schedule
     * @type {LiveStop[]}
     */
    get schedule() {
        return this._liveDriver.schedule.map((stop) => new LiveStop(stop));
    }

    /**
     * the number of confirmed inventory items
     * @type {Number}
     */
    get numConfirmedInventoryItems() {
        return this._liveDriver.stats.numConfirmedInventoryItems;
    }

    /**
     * the number of inventory items
     * @type {Number}
     */
    get numInventoryItems() {
        return this._liveDriver.stats.numInventoryItems;
    }

    /**
     * the vehicle ID
     * @type {String}
     */
    get vehicleId() {
        return this._liveDriver.vehicle.id;
    }

    /**
     * the vehicle type
     * @type {String}
     */
    get vehicleType() {
        return this._liveDriver.vehicle.type;
    }

    /**
     * the vehicle name
     * @type {String}
     */
    get vehicleName() {
        return `${this.vehicleType} ${this.routeName}`;
    }

    /**
     * the used volume capacity of this vehicle
     * @type {Number}
     */
    get volumeCapacityUsed() {
        return this._liveDriver.vehicle.stats.volumeCapacityUsed;
    }

    /**
     * the maximum volume capacity of this vehicle
     * @type {Number}
     */
    get maxVolume() {
        return this._liveDriver.vehicle.maxVolume;
    }

    /**
     * the used weight capacity of this vehicle
     * @type {Number}
     */
    get weightCapacityUsed() {
        return this._liveDriver.vehicle.stats.weightCapacityUsed;
    }

    /**
     * the maximum weight capacity of this vehicle
     * @type {Number}
     */
    get maxWeight() {
        return this._liveDriver.vehicle.maxWeight;
    }

    /**
     * indicates whether this driver has completed this route
     * @type {Boolean}
     */
    get isCompleted() {
        return this._liveDriver.stats.isDriverComplete;
    }

    /**
     * indicates whether this driver is active
     * @type {Boolean}
     */
    get isDriverActive() {
        return this._liveDriver.stats.active;
    }

    /**
     * the driver location
     * @type {*}
     */
    get location() {
        if (this._liveDriver.latestLocationUpdate) {
            const cepLocationTime = DateTime.fromISO(
                this._liveDriver.lastLocationUpdate
            );
            const locationUpdateTime = DateTime.fromISO(
                this._liveDriver.latestLocationUpdate.serverTime
            );
            return locationUpdateTime > cepLocationTime
                ? _.pick(this._liveDriver.latestLocationUpdate, 'lat', 'lng')
                : this._liveDriver.cepLocation;
        }
        return this._liveDriver.cepLocation;
    }

    set latestLocationUpdate(latestUpdate) {
        this._liveDriver.latestLocationUpdate = latestUpdate;
    }

    /**
     * the driver location direction in degrees
     * @type {Number}
     */
    get direction() {
        return this._liveDriver.latestLocationUpdate?.direction || 45;
    }

    /**
     * the route center
     * @type {*}
     */
    get routeCenter() {
        return this._liveDriver.routeCenter;
    }

    /**
     * indicates whether the route is planned
     *
     * this property allows for driver data to be used with route marker
     *
     * @type {Boolean}
     */
    // eslint-disable-next-line class-methods-use-this
    get isPlanned() {
        return true;
    }

    /**
     * the total route distance
     * @type {Number}
     * @todo `distance` is not available from the source. need to get it populated
     */
    get distance() {
        return this._liveDriver.stats.distance || 0;
    }

    /**
     * @borrows LiveDriver#hasStops
     */
    get hasTasks() {
        /** aliased to share a common property with PlanRoute. */
        return this.hasStops;
    }

    /**
     * @borrows LiveDriver#name
     */
    get driverName() {
        /** aliased to share a common property with PlanRoute. */
        return this.name;
    }

    /**
     * @borrows LiveDriver#timeRemaining
     */
    get duration() {
        /** aliased to share a common property with PlanRoute. */
        return this.timeRemaining;
    }

    /**
     * @borrows LiveDriver#maxVolume
     */
    get vehicleMaxVolume() {
        /** aliased to share a common property with PlanRoute. */
        return this.maxVolume;
    }

    /**
     * @borrows LiveDriver#maxWeight
     */
    get vehicleMaxWeight() {
        /** aliased to share a common property with PlanRoute. */
        return this.maxWeight;
    }

    /**
     * @borrows LiveDriver#volumeCapacityUsed
     */
    get vehicleVolumeUsed() {
        /** aliased to share a common property with PlanRoute. */
        return this.volumeCapacityUsed;
    }

    /**
     * @borrows LiveDriver#weightCapacityUsed
     */
    get vehicleWeightUsed() {
        /** aliased to share a common property with PlanRoute. */
        return this.weightCapacityUsed;
    }

    /**
     * @borrows LiveDriver#routeName
     */
    get vehicleEid() {
        /** aliased to share a common property with PlanRoute. */
        return this.routeName;
    }

    /**
     * Serializes this class back to JSON
     * @returns {Object}
     */
    toJSON() {
        return this._liveDriver;
    }
}

export default LiveDriver;
