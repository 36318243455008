import React, { useCallback, useEffect, useRef } from 'react';
import PT from 'prop-types';
import GoogleMap from 'google-map-react';
import _ from 'lodash';

import { ProcessIndicatorProvider } from '~/contexts/ProcessIndicatorContext';
import { Tooltip } from '~/ui';
import constants from '~/utils/constants';
import { getPositionInElem } from '~/utils/getPositionInElem';

import './map.scss';

function Map({
    mode,
    overlay,
    children,
    style,
    googleMapOptions,
    className,
    zonePolygonTooltipName
}) {
    const mapContainerRef = useRef();
    const zonePolygonTooltipRef = useRef(null);
    const setZonePolygonTooltipRef = useCallback((ref) => {
        zonePolygonTooltipRef.current = ref;
    }, []);

    useEffect(() => {
        const onMouseMove = (e) => {
            const mapContainer = mapContainerRef.current;
            const zonePolygonTooltip = zonePolygonTooltipRef.current;
            if (!mapContainer || !zonePolygonTooltip) {
                return;
            }
            const [x, y] = getPositionInElem(
                [e.clientX, e.clientY],
                mapContainer
            );

            /**
             * Setting the `style` prop directly works around the react render cycle, and allows
             * the element to update its' position much faster, leading to a more responsive mousemove experience
             */
            zonePolygonTooltip.style.left = `${x}px`;
            zonePolygonTooltip.style.top = `${y}px`;
        };
        window.addEventListener('mousemove', onMouseMove);

        return () => {
            window.removeEventListener('mousemove', onMouseMove);
        };
    }, [mapContainerRef.current, zonePolygonTooltipRef.current]);
    const googleMapsKey = {
        key:
            process.env.REACT_APP_GOOGLE_MAPS_KEY ||
            constants.mapOptionSettings.DEFAULT_GOOGLE_MAP_KEY
    };

    const googleMapsCenter = {
        lat:
            Number(process.env.REACT_APP_GOOGLE_MAPS_DEFAULT_CENTER_LAT) ||
            constants.mapOptionSettings.DEFAULT_CENTER.lat,
        lng:
            Number(process.env.REACT_APP_GOOGLE_MAPS_DEFAULT_CENTER_LNG) ||
            constants.mapOptionSettings.DEFAULT_CENTER.lng
    };

    const mapModes = [
        constants.mapOptionSettings.MAP_MODE_DEFAULT,
        constants.mapOptionSettings.MAP_MODE_SIMPLE,
        constants.mapOptionSettings.MAP_MODE_STATIC
    ];

    const defaultOptions = {
        className: 'map',
        mapMode: constants.mapOptionSettings.MAP_MODE_DEFAULT,
        style: {},
        googleMapOptions: {
            bootstrapURLKeys: googleMapsKey,
            center: googleMapsCenter,
            defaultZoom: constants.mapOptionSettings.DEFAULT_ZOOM,
            options: {
                disableDefaultUI: true
            }
        }
    };

    const mapMode =
        mode && mapModes.includes(mode) ? mode : defaultOptions.mapMode;
    const boxStyles = _.merge(defaultOptions.style, style);

    function getClassName() {
        const defaultClassName = `${defaultOptions.className} map_mode-${mapMode}`;
        return className
            ? `${className} ${defaultClassName}`
            : defaultClassName;
    }

    function getMapOptions() {
        const defaultMapOptions = defaultOptions.googleMapOptions;
        let mapOptions;

        switch (mapMode) {
            case constants.mapOptionSettings.MAP_MODE_SIMPLE:
                mapOptions = _.merge(
                    defaultMapOptions,
                    {
                        options: {
                            disableDoubleClickZoom: true
                        }
                    },
                    googleMapOptions
                );
                break;

            case constants.mapOptionSettings.MAP_MODE_STATIC:
                mapOptions = _.merge(
                    defaultMapOptions,
                    {
                        options: {
                            disableDoubleClickZoom: true,
                            gestureHandling:
                                constants.mapOptionSettings
                                    .GESTURE_HANDLING_OFF,
                            zoomControl: false,
                            keyboardShortcuts: false,
                            clickableIcons: false
                        }
                    },
                    googleMapOptions
                );
                break;

            default:
                mapOptions = _.merge(defaultMapOptions, googleMapOptions);
                break;
        }

        return mapOptions;
    }

    return (
        <div
            id="map"
            className={getClassName()}
            style={boxStyles}
            ref={mapContainerRef}
        >
            <ProcessIndicatorProvider>
                {overlay}
                <GoogleMap {...getMapOptions()}>{children}</GoogleMap>
            </ProcessIndicatorProvider>
            <Tooltip
                content={zonePolygonTooltipName ?? ''}
                placement="top"
                isVisible
                sx={{
                    display: `${zonePolygonTooltipName ? 'block' : 'none'}`,
                    transform:
                        'translate(-50%, calc(-100% - 1.6rem)) !important'
                }}
                arrowProps={{
                    className: 'zone-polygon-tooltip-arrow'
                }}
                setRef={setZonePolygonTooltipRef}
            />
        </div>
    );
}

Map.propTypes = {
    /**
     * User-defined CSS class name
     */
    className: PT.string,
    /**
     * Use a preset map mode.
     *
     * Refer to [Map Modes](#map-modes) for the options set by this property.
     * These values can be overridden by **googleMapOptions**
     */
    mode: PT.oneOf(['default', 'simple', 'static']),
    /**
     * An object of **<GoogleMap />** properties to override the preset options as set by the **mode** property.
     *
     * Refer to the following links for what properties you can set:
     * + [Google Map React API](https://github.com/google-map-react/google-map-react/blob/master/API.md):
     *   available properties to set for **<GoogleMap />**
     * + [Google Maps MapOptions Reference](https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions):
     *   additional options available from [Google Maps](https://maps.google.com)
     */
    googleMapOptions: PT.oneOfType([PT.func, PT.object]),
    /**
     * A React element or array of React Elements to overlay onto the map
     */
    overlay: PT.oneOfType([PT.element, PT.arrayOf(PT.element)]),
    /**
     * A React child element or elements wrapped by the **Map** element to render into the map.
     */
    children: PT.oneOfType([PT.node, PT.arrayOf(PT.node)]),
    /**
     * A javaScript object with camelCased CSS properties per
     * [React inline styles](https://reactjs.org/docs/dom-elements.html#style) documentation
     */
    style: PT.objectOf(PT.oneOfType([PT.number, PT.string]))
};

Map.defaultProps = {
    className: '',
    mode: 'default',
    googleMapOptions: {},
    style: {}
};

export default Map;
