import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';

import constants from '~/utils/constants';

/**
 * Redux slice for various map-related settings
 * @category Reducers
 * @module mapSettingsSlice
 */

/** @typedef {import('@reduxjs/toolkit').PayloadAction} PayloadAction */

export const defaultPerTabMapSettings = {
    showZonePolygons: false,
    showRoutePolygons: false,
    showRoutePopup: false,
    showRouteLabel: false,
    showStopPopup: false,
    showStopNumber: false,
    showStopLabel: false,
    showDriverPopup: false,
    showDriverLabel: false,
    showDriverLines: false
};

export const defaultGlobalMapSettings = {
    hasIsolatedRoutes: false,
    mapMarkerMode: constants.mapMarkerModes.ROUTES,
    mapRouteMode: constants.mapRouteModes.EMPTY,
    shouldFitPlanMapToBounds: false,
    redirectedToMapPage: false,
    isClusteringStops: true,
    isClusteringToggleEnabled: true,
    isMultipleCardSelectEnabled: false,
    viewCardDetails: false,
    [constants.mapRouteModes.PLAN]: { ...defaultPerTabMapSettings },
    [constants.mapRouteModes.DISPATCHED]: {
        ...defaultPerTabMapSettings,
        showDriverLines: true,
        showStopNumber: true
    },
    [constants.mapRouteModes.COMPLETED]: { ...defaultPerTabMapSettings }
};

/**
 * apply map settings on a per-tab basis
 * @private
 * @param {String} mapRouteMode - the map route mode
 * @param {String} setting - the map setting to update
 * @param {Object} state - the current state
 * @param {(Boolean|String)} value - the map setting value to set, type depends on setting
 * @returns {Object} the updated state
 */
const applyToPerTabSettings = (mapRouteMode, setting, state, value) => {
    if (
        !Object.values(constants.mapRouteModes).includes(mapRouteMode) ||
        !Object.keys(defaultPerTabMapSettings).includes(setting) ||
        !_.isBoolean(value)
    ) {
        return;
    }
    const modeSettings = {
        ...state[mapRouteMode],
        [setting]: value
    };
    return { ...state, [mapRouteMode]: modeSettings };
};

export const mapSettingsSlice = createSlice({
    name: 'mapSettings',
    initialState: defaultGlobalMapSettings,
    reducers: {
        /**
         * set the current map mode setting for showing zone polygons
         * @method setShowZonePolygons
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {Object} action.payload - the reducer's payload
         * @param {String} action.payload.mode - the map route mode
         * @param {Boolean} action.payload.value - the map mode setting value to set
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowZonePolygons } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update zone polygons setting for plan route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowZonePolygons({ mode: constants.mapRouteModes.PLAN, value: true }));
         */
        setShowZonePolygons: (state, action) =>
            applyToPerTabSettings(
                action.payload.mode,
                'showZonePolygons',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing route polygons
         * @method setShowRoutePolygons
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {Object} action.payload - the reducer's payload
         * @param {String} action.payload.mode - the map route mode
         * @param {Boolean} action.payload.value - the map mode setting value to set
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowRoutePolygons } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update route polygons setting for plan route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowRoutePolygons({ mode: constants.mapRouteModes.PLAN, value: true }));
         */
        setShowRoutePolygons: (state, action) =>
            applyToPerTabSettings(
                action.payload.mode,
                'showRoutePolygons',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing route popups
         * @method setShowRoutePopup
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {Object} action.payload - the reducer's payload
         * @param {String} action.payload.mode - the map route mode
         * @param {Boolean} action.payload.value - the map mode setting value to set
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowRoutePopup } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update route popups setting for plan route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowRoutePopup({ mode: constants.mapRouteModes.PLAN, value: true }));
         */
        setShowRoutePopup: (state, action) =>
            applyToPerTabSettings(
                action.payload.mode,
                'showRoutePopup',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing route labels
         * @method setShowRouteLabel
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {Object} action.payload - the reducer's payload
         * @param {String} action.payload.mode - the map route mode
         * @param {Boolean} action.payload.value - the map mode setting value to set
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowRouteLabel } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update route labels setting for plan route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowRouteLabel({ mode: constants.mapRouteModes.PLAN, value: true }));
         */
        setShowRouteLabel: (state, action) =>
            applyToPerTabSettings(
                action.payload.mode,
                'showRouteLabel',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing stop popups
         * @method setShowStopPopup
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {Object} action.payload - the reducer's payload
         * @param {String} action.payload.mode - the map route mode
         * @param {Boolean} action.payload.value - the map mode setting value to set
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowStopPopup } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update stop popups setting for plan route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowStopPopup({ mode: constants.mapRouteModes.PLAN, value: true }));
         */
        setShowStopPopup: (state, action) =>
            applyToPerTabSettings(
                action.payload.mode,
                'showStopPopup',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing stop numbers
         * @method setShowStopNumber
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {Object} action.payload - the reducer's payload
         * @param {String} action.payload.mode - the map route mode
         * @param {Boolean} action.payload.value - the map mode setting value to set
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowStopNumber } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update stop numbers setting for plan route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowStopNumber({ mode: constants.mapRouteModes.PLAN, value: true }));
         */
        setShowStopNumber: (state, action) =>
            applyToPerTabSettings(
                action.payload.mode,
                'showStopNumber',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing stop labels
         * @method setShowStopLabel
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {Object} action.payload - the reducer's payload
         * @param {String} action.payload.mode - the map route mode
         * @param {Boolean} action.payload.value - the map mode setting value to set
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowStopLabel } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update stop labels setting for plan route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowStopLabel({ mode: constants.mapRouteModes.PLAN, value: true }));
         */
        setShowStopLabel: (state, action) =>
            applyToPerTabSettings(
                action.payload.mode,
                'showStopLabel',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing driver popups
         * @method setShowDriverPopup
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {Object} action.payload - the reducer's payload
         * @param {String} action.payload.mode - the map route mode
         * @param {Boolean} action.payload.value - the map mode setting value to set
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowDriverPopup } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update driver popups setting for dispatched route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowDriverPopup({ mode: constants.mapRouteModes.DISPATCHED, value: true }));
         */
        setShowDriverPopup: (state, action) =>
            applyToPerTabSettings(
                action.payload.mode,
                'showDriverPopup',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing driver labels
         * @method setShowDriverLabel
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {Object} action.payload - the reducer's payload
         * @param {String} action.payload.mode - the map route mode
         * @param {Boolean} action.payload.value - the map mode setting value to set
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowDriverLabel } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update driver labels setting for dispatched route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowDriverLabel({ mode: constants.mapRouteModes.DISPATCHED, value: true }));
         */
        setShowDriverLabel: (state, action) =>
            applyToPerTabSettings(
                action.payload.mode,
                'showDriverLabel',
                state,
                action.payload.value
            ),
        /**
         * set the current map mode setting for showing driver lines
         * @method setShowDriverLines
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {Object} action.payload - the reducer's payload
         * @param {String} action.payload.mode - the map route mode
         * @param {Boolean} action.payload.value - the map mode setting value to set
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShowDriverLines } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update driver lines setting for dispatched route mode to `true`
         * const dispatch = useDispatch();
         * dispatch(setShowDriverLines({ mode: constants.mapRouteModes.DISPATCHED, value: true }));
         */
        setShowDriverLines: (state, action) =>
            applyToPerTabSettings(
                action.payload.mode,
                'showDriverLines',
                state,
                action.payload.value
            ),
        /**
         * set the global map setting whether the map has isolated routes
         * @method setHasIsolatedRoutes
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {Boolean} action.payload - the reducer's payload
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setHasIsolatedRoutes } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // update isolated routes setting to `true`
         * const dispatch = useDispatch();
         * dispatch(setHasIsolatedRoutes(true);
         */
        setHasIsolatedRoutes: (state, action) => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            return { ...state, hasIsolatedRoutes: action.payload };
        },
        /**
         * set the global map setting identifying the map marker mode
         * @method setMapMarkerMode
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {String} action.payload
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setMapMarkerMode } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update map marker mode to `STOPS_CLUSTERS`
         * const dispatch = useDispatch();
         * dispatch(setMapMarkerMode(constants.mapMarkerModes.STOPS_CLUSTERS));
         */
        setMapMarkerMode: (state, action) => {
            let isValidMode = false;
            for (const mode in constants.mapMarkerModes) {
                if (action.payload === constants.mapMarkerModes[mode]) {
                    isValidMode = true;
                    break;
                }
            }
            if (isValidMode) {
                return { ...state, mapMarkerMode: action.payload };
            }
            return state;
        },
        /**
         * set the global map setting identifying the map route mode
         * @method setMapRouteMode
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {String} action.payload
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setMapRouteMode } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         * import constants from '~/utils/constants';
         *
         * // update map route mode to `COMPLETED`
         * const dispatch = useDispatch();
         * dispatch(setMapRouteMode(constants.mapRouteModes.COMPLETED));
         */
        setMapRouteMode: (state, action) => {
            let isValidMode = false;
            for (const mode in constants.mapRouteModes) {
                if (action.payload === constants.mapRouteModes[mode]) {
                    isValidMode = true;
                    break;
                }
            }
            if (isValidMode) {
                return { ...state, mapRouteMode: action.payload };
            }
            return state;
        },
        /**
         * set the global map setting whether the map should fit markers to map bounds
         * @method setShouldFitPlanMapToBounds
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {Boolean} action.payload - the reducer's payload
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setShouldFitPlanMapToBounds } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // set markers to fit to bounds
         * const dispatch = useDispatch();
         * dispatch(setShouldFitPlanMapToBounds(true));
         */
        setShouldFitPlanMapToBounds: (state, action) => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            return { ...state, shouldFitPlanMapToBounds: action.payload };
        },
        /**
         * set the global map setting whether the user was redirected to map page
         * @method setRedirectedToMapPage
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {Boolean} action.payload - the reducer's payload
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setRedirectedToMapPage } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // identify that the user was redirected to map page
         * const dispatch = useDispatch();
         * dispatch(setRedirectedToMapPage(true));
         */
        setRedirectedToMapPage: (state, action) => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            state.redirectedToMapPage = action.payload;
            return state;
        },
        /**
         * set the global map setting whether the map is clustering stops
         * @method setIsClusteringStops
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {Boolean} action.payload - the reducer's payload
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setIsClusteringStops } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // identify that the map is clustering stops
         * const dispatch = useDispatch();
         * dispatch(setIsClusteringStops(true));
         */
        setIsClusteringStops: (state, action) => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            state.isClusteringStops = action.payload;
            return state;
        },
        /**
         * set the global map setting whether the map clustering toggle is enabled
         * @method setIsClusteringToggleEnabled
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {Boolean} action.payload - the reducer's payload
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setIsClusteringToggleEnabled } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // identify that the map clustering toggle is enabled
         * const dispatch = useDispatch();
         * dispatch(setIsClusteringToggleEnabled(true));
         */
        setIsClusteringToggleEnabled: (state, action) => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            state.isClusteringToggleEnabled = action.payload;
            return state;
        },
        /**
         * set the global map setting whether the map is allowing multiple cards to be selected from the drawers
         * @method setIsMultipleCardSelectEnabled
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {Boolean} action.payload - the reducer's payload
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setIsMultipleCardSelectEnabled } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // identify that the map is allowing multiple cards to be selected from the drawers
         * const dispatch = useDispatch();
         * dispatch(setIsMultipleCardSelectEnabled(true));
         */
        setIsMultipleCardSelectEnabled: (state, action) => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            state.isMultipleCardSelectEnabled = action.payload;
            return state;
        },
        /**
         * set the global map setting whether the map drawer is set to view card details
         * @method setViewCardDetails
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {Boolean} action.payload - the reducer's payload
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { setViewCardDetails } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // identify the map drawer is set to view card details
         * const dispatch = useDispatch();
         * dispatch(setViewCardDetails(true));
         */
        setViewCardDetails: (state, action) => {
            const isBoolean = _.isBoolean(action.payload);
            if (!isBoolean) {
                return state;
            }
            state.viewCardDetails = action.payload;
            return state;
        },
        /**
         * reset most global map settings back to default values, persisting select settings
         * @method resetMapState
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { resetMapState } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // reset map state
         * const dispatch = useDispatch();
         * dispatch(resetMapState());
         */
        resetMapState: (state) => {
            const persistSettings = _.pick(state, [
                constants.mapRouteModes.PLAN,
                constants.mapRouteModes.DISPATCHED,
                constants.mapRouteModes.COMPLETED,
                'isClusteringToggleEnabled',
                'isClusteringStops',
                'mapRouteMode'
            ]);
            return {
                ...defaultGlobalMapSettings,
                ...persistSettings
            };
        },
        /**
         * reset the all global map settings back to default values
         * @method resetMapSettings
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { resetMapSettings } from '~/reducers/mapSettingsSlice';
         * import { useDispatch } from 'react-redux';
         *
         * // reset map settings
         * const dispatch = useDispatch();
         * dispatch(resetMapSettings());
         */
        resetMapSettings: () => {
            return defaultGlobalMapSettings;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return defaultGlobalMapSettings;
        });
    }
});

export const {
    setShowZonePolygons,
    setShowRoutePolygons,
    setShowRoutePopup,
    setShowRouteLabel,
    setShowStopPopup,
    setShowStopNumber,
    setShowStopLabel,
    setShowDriverPopup,
    setShowDriverLabel,
    setShowDriverLines,
    setHasIsolatedRoutes,
    setMapMarkerMode,
    setMapRouteMode,
    setShouldFitPlanMapToBounds,
    setRedirectedToMapPage,
    setIsClusteringStops,
    setIsClusteringToggleEnabled,
    setIsMultipleCardSelectEnabled,
    setViewCardDetails,
    resetMapSettings,
    resetMapState
} = mapSettingsSlice.actions;

/**
 * selects the current map mode setting for showing zone polygons
 * @method
 * @param {String} mapRouteMode
 * @returns {Boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowZonePolygons } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showZonePolygons = useSelector(selectShowZonePolygons(constants.mapRouteModes.PLAN));
 */
export const selectShowZonePolygons = (mapRouteMode) => (state) =>
    state.mapSettings[mapRouteMode]?.showZonePolygons;

/**
 * selects the current map mode setting for showing route polygons
 * @method
 * @param {String} mapRouteMode
 * @returns {Boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowRoutePolygons } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showRoutePolygons = useSelector(selectShowRoutePolygons(constants.mapRouteModes.PLAN));
 */
export const selectShowRoutePolygons = (mapRouteMode) => (state) =>
    state.mapSettings[mapRouteMode]?.showRoutePolygons;

/**
 * selects the current map mode setting for showing route popups
 * @method
 * @param {String} mapRouteMode
 * @returns {Boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowRoutePopup } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showRoutePopup = useSelector(selectShowRoutePopup(constants.mapRouteModes.PLAN));
 */
export const selectShowRoutePopup = (mapRouteMode) => (state) =>
    state.mapSettings[mapRouteMode]?.showRoutePopup;

/**
 * selects the current map mode setting for showing route labels
 * @method
 * @param {String} mapRouteMode
 * @returns {Boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowRouteLabel } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showRouteLabel = useSelector(selectShowRouteLabel(constants.mapRouteModes.PLAN));
 */
export const selectShowRouteLabel = (mapRouteMode) => (state) =>
    state.mapSettings[mapRouteMode]?.showRouteLabel;

/**
 * selects the current map mode setting for showing stop popups
 * @method
 * @param {String} mapRouteMode
 * @returns {Boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowStopPopup } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showStopPopup = useSelector(selectShowStopPopup(constants.mapRouteModes.PLAN));
 */
export const selectShowStopPopup = (mapRouteMode) => (state) =>
    state.mapSettings[mapRouteMode]?.showStopPopup;

/**
 * selects the current map mode setting for showing stop numbers
 * @method
 * @param {String} mapRouteMode
 * @returns {Boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowStopNumber } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showStopNumber = useSelector(selectShowStopNumber(constants.mapRouteModes.PLAN));
 */
export const selectShowStopNumber = (mapRouteMode) => (state) =>
    state.mapSettings[mapRouteMode]?.showStopNumber;

/**
 * selects the current map mode setting for showing stop labels
 * @method
 * @param {String} mapRouteMode
 * @returns {Boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowStopLabel } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showStopLabel = useSelector(selectShowStopLabel(constants.mapRouteModes.PLAN));
 */
export const selectShowStopLabel = (mapRouteMode) => (state) =>
    state.mapSettings[mapRouteMode]?.showStopLabel;

/**
 * selects the current map mode setting for showing driver popups
 * @method
 * @param {String} mapRouteMode
 * @returns {Boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowDriverPopup } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showDriverPopup = useSelector(selectShowDriverPopup(constants.mapRouteModes.DISPATCHED));
 */
export const selectShowDriverPopup = (mapRouteMode) => (state) =>
    state.mapSettings[mapRouteMode]?.showDriverPopup;

/**
 * selects the current map mode setting for showing driver labels
 * @method
 * @param {String} mapRouteMode
 * @returns {Boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowDriverLabel } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showDriverLabel = useSelector(selectShowDriverLabel(constants.mapRouteModes.DISPATCHED));
 */
export const selectShowDriverLabel = (mapRouteMode) => (state) =>
    state.mapSettings[mapRouteMode]?.showDriverLabel;

/**
 * selects the current map mode setting for showing driver lines
 * @method
 * @param {String} mapRouteMode
 * @returns {Boolean} the current map mode setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShowDriverLines } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map mode setting
 * const showDriverLines = useSelector(selectShowDriverLines(constants.mapRouteModes.DISPATCHED));
 */
export const selectShowDriverLines = (mapRouteMode) => (state) =>
    state.mapSettings[mapRouteMode]?.showDriverLines;

/**
 * selects the current global map setting whether the map should fit markers to map bounds
 * @method
 * @returns {Boolean} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectHasIsolatedRoutes } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const hasIsolatedRoutes = useSelector(selectHasIsolatedRoutes());
 */
export const selectHasIsolatedRoutes = (state) =>
    state.mapSettings.hasIsolatedRoutes;

/**
 * selects the current global map setting identifying the map marker mode
 * @method
 * @returns {String} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectMapMarkerMode } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const mapMarkerMode = useSelector(selectMapMarkerMode());
 */
export const selectMapMarkerMode = (state) => state.mapSettings.mapMarkerMode;

/**
 * selects the current global map setting identifying the map route mode
 * @method
 * @returns {String} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectMapRouteMode } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const mapRouteMode = useSelector(selectMapRouteMode());
 */
export const selectMapRouteMode = (state) => state.mapSettings.mapRouteMode;

/**
 * selects the current global map setting whether the map should fit markers to map bounds
 * @method
 * @returns {Boolean} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectShouldFitPlanMapToBounds } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const shouldFitPlanMapToBounds = useSelector(selectShouldFitPlanMapToBounds());
 */
export const selectShouldFitPlanMapToBounds = (state) =>
    state.mapSettings.shouldFitPlanMapToBounds;

/**
 * selects the current global map setting whether the user was redirected to map page
 * @method
 * @returns {Boolean} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectRedirectedToMapPage } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const redirectedToMapPage = useSelector(selectRedirectedToMapPage());
 */
export const selectRedirectedToMapPage = (state) =>
    state.mapSettings.redirectedToMapPage;

/**
 * selects the current global map setting whether the map is clustering stops
 * @method
 * @returns {Boolean} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectIsClusteringStops } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const isClusteringStops = useSelector(selectIsClusteringStops());
 */
export const selectIsClusteringStops = (state) =>
    state.mapSettings.isClusteringStops;

/**
 * selects the current global map setting whether the map clustering toggle is enabled
 * @method
 * @returns {Boolean} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectIsClusteringToggleEnabled } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const isClusteringToggleEnabled = useSelector(selectIsClusteringToggleEnabled());
 */
export const selectIsClusteringToggleEnabled = (state) =>
    state.mapSettings.isClusteringToggleEnabled;

/**
 * selects the current global map setting whether the map is allowing multiple cards to be selected from the drawers
 * @method
 * @returns {Boolean} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectIsMultipleCardSelectEnabled } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const isMultipleCardSelectEnabled = useSelector(selectIsMultipleCardSelectEnabled());
 */
export const selectIsMultipleCardSelectEnabled = (state) =>
    state.mapSettings.isMultipleCardSelectEnabled;

/**
 * selects the current global map setting whether the map drawer is set to view card details
 * @method
 * @returns {Boolean} the current global map setting
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectViewCardDetails } from '~/reducers/mapSettingsSlice';
 * import { useSelector } from 'react-redux';
 * import constants from '~/utils/constants';
 *
 * // get the current map setting
 * const viewCardDetails = useSelector(selectViewCardDetails());
 */
export const selectViewCardDetails = (state) =>
    state.mapSettings.viewCardDetails;

export default mapSettingsSlice.reducer;
