import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { setSelectedTaskRowId } from '~/reducers/selectedTaskRowIdSlice';
import { resetSelectedMapStops } from '~/reducers/selectedMapStopsSlice';

import constants from '~/utils/constants';

import {
    ManageStopControlMenu,
    RouteCardAddress,
    RouteCardEta,
    RouteCardLabels,
    RouteCardTitle,
    StopModalMenuButton,
    RouteCardMetric,
    TooltipButton
} from '~/ui';
import './manage-stop-control-single.scss';

function ManageStopControlSingle({
    stopData,
    children,
    className,
    footerButtonTitle,
    footerButtonIcon,
    onClickFooterButton = () => {}
}) {
    const [isLateStatus, setIsLateStatus] = useState(false);

    const dispatch = useDispatch();

    const { delay, taskId } = stopData;
    const { t } = useTranslation('translation');

    useEffect(() => {
        if (delay === constants.delayCodes.LATE) {
            setIsLateStatus(true);
        }
    }, [stopData]);

    function _getClassName() {
        const defaultClassName = `managestopcontrol_single _d-flex _fd-column`;
        return classNames(defaultClassName, className);
    }

    function _getStopMetric() {
        return (
            <RouteCardMetric
                value={t('PlanStopsControl.title.late_stop')}
                iconSize="s"
                iconColor="mars"
                icon="clockFill2"
            />
        );
    }

    const handleClickViewMore = () => {
        dispatch(resetSelectedMapStops());
        dispatch(setSelectedTaskRowId(taskId));
    };

    return (
        stopData && (
            <div className={_getClassName()}>
                <div className="managestopcontrol_main">
                    {isLateStatus && _getStopMetric()}
                    <RouteCardTitle className="_text-2">
                        {stopData.stopName}
                    </RouteCardTitle>
                    <RouteCardAddress address={stopData.location} icon="pin" />
                    <RouteCardEta stopData={stopData} icon="clock" />
                    <RouteCardLabels labels={stopData.labels} icon="union" />
                    <TooltipButton
                        className="managestopcontrol_view-more"
                        variant="link"
                        onClick={handleClickViewMore}
                    >
                        {t('Collapsible.view_more')}
                    </TooltipButton>
                </div>
                {children}
                {footerButtonTitle && (
                    <ManageStopControlMenu className="managestopcontrol_footer">
                        <StopModalMenuButton
                            titleIcon={footerButtonIcon}
                            title={footerButtonTitle}
                            onClickHandler={onClickFooterButton}
                        />
                    </ManageStopControlMenu>
                )}
            </div>
        )
    );
}

export { ManageStopControlSingle };
