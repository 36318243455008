import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import tasksApi from '~/api/tasks';
import taskAssignmentsApi from '~/api/courier-task-assignments';
import courierSchedulesApi from '~/api/courier-schedules';
import { selectDate } from '~/reducers/selectedDateSlice';
import { selectClients } from '~/reducers/clientsSlice';
import { setRoutesScheduling } from '~/reducers/workflowSlice';
import constants from '~/utils/constants';
import useToastContext from './toastContext';

/**
 * Maintains state and effects related to re running the scheduler
 *
 * This includes (un)locking/(un)closing tasks when the optimization choice changes
 */
export function useRunScheduler() {
    const dispatch = useDispatch();
    const selectedDate = useSelector(selectDate);
    const driverGroups = useSelector(selectClients);

    const { t } = useTranslation('workflow');
    const { addToast } = useToastContext();

    function dispatchRoutesScheduling(routesScheduling) {
        dispatch(
            setRoutesScheduling({
                routeDate: selectedDate,
                routesScheduling
            })
        );
    }

    const catchAction = (e) => {
        console.error(e);
        addToast({
            message: t('error:schedulerRunningError'),
            variant: 'error'
        });
        dispatchRoutesScheduling(false);
    };

    const runScheduler = useCallback(
        async (selectedOptimizationChoice = null) => {
            const schedulerPromises = [];
            try {
                const isClosedRoutes =
                    selectedOptimizationChoice ===
                    constants.schedulerKeys.closeAllRoutes;
                const schedulerConfig = {
                    date: selectedDate,
                    rerun: isClosedRoutes
                };

                dispatchRoutesScheduling(true);

                // driverGroups includes the main client
                if (Object.keys(driverGroups).length === 1) {
                    await tasksApi.startScheduler(schedulerConfig);
                    return;
                }
                const taskAssignerConfig = {
                    date: selectedDate,
                    close_routes: isClosedRoutes
                };

                const courierSchedulerConfig = { close_routes: isClosedRoutes };
                const taskAssignmentResponse = await taskAssignmentsApi.post(
                    taskAssignerConfig
                );
                const taskAssignerId = taskAssignmentResponse.data.data.runId;
                courierSchedulerConfig.taskAssignerId = taskAssignerId;

                setTimeout(async () => {
                    for (const driverGroupId of Object.keys(driverGroups)) {
                        courierSchedulerConfig.driverGroupId = driverGroupId;
                        schedulerPromises.push(
                            courierSchedulesApi.post(courierSchedulerConfig)
                        );
                    }
                    try {
                        await Promise.all(schedulerPromises);
                    } catch (e) {
                        catchAction(e);
                    }
                }, constants.timings.COURIER_SCHEDULER_INTERVAL);
            } catch (e) {
                catchAction(e);
            }
        },
        [driverGroups, selectedDate]
    );

    return { runScheduler };
}
