import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';

const _initialValue = 0;
export const detectWebSolutionSlice = createSlice({
    name: 'detectWebSolution',
    initialState: _initialValue,
    reducers: {
        notifyReceivedWebSolution: (state) => {
            return ++state;
        },
        resetDetectWebSolution: () => {
            return _initialValue;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return _initialValue;
        });
    }
});

export const { notifyReceivedWebSolution, resetDetectWebSolution } =
    detectWebSolutionSlice.actions;
export const selectDetectWebSolution = (state) => state.detectWebSolution;
export default detectWebSolutionSlice.reducer;
