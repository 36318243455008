import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon } from '~/ui';
import tasksApi from '~/api/tasks';
import { removeTasksById, selectTasksById } from '~/reducers/tasksSlice';
import { resetSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';
import { addToast } from '~/reducers/toastsSlice';
import '~/components/UsersAndPermissionsPage/UserGroupModal/UserGroupModal.css';
import { updateTaskMetrics } from '~/reducers/taskMetricsSlice';
import { selectDate } from '~/reducers/selectedDateSlice';
import taskUtils from '~/utils/task-utils';

function DeleteTasksModalContent({ hideModal, selectedTaskIds, hideOther }) {
    const { t } = useTranslation(['common', 'taskManagement', 'error']);
    const selectedTasks = useSelector((state) =>
        selectTasksById(state, selectedTaskIds)
    );
    const selectedDate = useSelector(selectDate);
    const dispatch = useDispatch();

    async function onClickDelete() {
        const numOfSelectedTasks = selectedTaskIds.length;
        try {
            if (numOfSelectedTasks === 1) {
                await tasksApi.deleteTask(selectedTaskIds[0]);
            } else {
                await tasksApi.deleteTasks(selectedTaskIds);
            }
            if (hideOther) hideOther();
            hideModal();
            dispatch(removeTasksById(selectedTaskIds));
            dispatch(resetSelectedTaskIds());
            dispatch(updateTaskMetrics(selectedDate));
        } catch (error) {
            console.error(error);
            dispatch(
                addToast({
                    message: t('error:deleteTaskError', {
                        count: numOfSelectedTasks
                    }),
                    variant: 'error'
                })
            );
            hideModal();
        }
    }

    function displayTasks() {
        return selectedTasks.map((task) => {
            const key = `selectedtask_${task.id}`;
            return <div key={key}>{taskUtils.formatTaskName(task)}</div>;
        });
    }
    return (
        <>
            <div className="modal-header">
                <div className="_d-flex">
                    <Icon icon="warningFill" color="mars" marginRight="1rem" />
                    <span>
                        {t('taskManagement:deleteTask', {
                            count: selectedTaskIds.length
                        })}
                    </span>
                </div>
                <button type="button" className="icon-wrapper">
                    <Icon
                        icon="cancel"
                        color="galaxy-800"
                        onClick={hideModal}
                    />
                </button>
            </div>
            <div className="modal-content modal-content-delete">
                <div className="_mb-6">
                    {t('taskManagement:deleteTaskConfirmation', {
                        count: selectedTaskIds.length
                    })}
                </div>
                <div className="_d-flex _fd-column modal_dialogue-content">
                    {displayTasks()}
                </div>
                <div className="_d-flex _jc-flex-end _mt-6">
                    <Button
                        variant="secondary-dismissive"
                        onClick={hideModal}
                        marginRight="2.4rem"
                    >
                        {t('common:cancel')}
                    </Button>
                    <Button
                        variant="primary-dismissive"
                        onClick={onClickDelete}
                    >
                        {t('common:delete')}
                    </Button>
                </div>
            </div>
        </>
    );
}

export default DeleteTasksModalContent;
