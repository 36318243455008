import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';
import { planDataFactory } from '~/utils/data-factory';

function _findClientLevelDataElement(planClient, planClientsLevelData) {
    let foundIndex = -1;
    const { clientId } = planClient;
    for (let index = 0; index < planClientsLevelData.length; index++) {
        const currentPlanClient = planDataFactory.makePlanClient(
            planClientsLevelData[index]
        );
        const currentClientId = currentPlanClient.clientId;
        if (clientId === currentClientId) {
            foundIndex = index;
            break;
        }
    }
    return foundIndex;
}

export const planClientsLevelData = createSlice({
    name: 'planClientsLevelData',
    initialState: [],
    reducers: {
        addPlanClientLevelData: (state, action) => {
            const newPlanClient = planDataFactory.makePlanClient(
                action.payload
            );
            const indexExisting = _findClientLevelDataElement(
                newPlanClient,
                state
            );
            if (indexExisting !== -1) {
                state[indexExisting] = action.payload;
            } else {
                state.push(action.payload);
            }
            return state;
        },
        addPlanUnplannedClientLevelData: (state, action) => {
            const newPlanClient = planDataFactory.makePlanClient(
                action.payload
            );
            const indexExisting = _findClientLevelDataElement(
                newPlanClient,
                state
            );
            if (indexExisting === -1) {
                return state;
            }
            const existingPlanClient = planDataFactory.makePlanClient(
                state[indexExisting]
            );
            existingPlanClient.numUnplannedTasks =
                newPlanClient.numUnplannedTasks;
            state[indexExisting] = existingPlanClient.toJSON();
            return state;
        },
        resetPlanClientsLevelData: () => {
            return [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return [];
        });
    }
});

export const {
    addPlanClientLevelData,
    addPlanUnplannedClientLevelData,
    resetPlanClientsLevelData
} = planClientsLevelData.actions;

export const selectPlanClientsLevelData = (state) => state.planClientsLevelData;
export default planClientsLevelData.reducer;
