/* eslint-disable no-restricted-globals */
import io from 'socket.io-client';
import constants from '~/utils/constants';

import { store } from '~/store';
import {
    addActiveClient,
    removeActiveClient
} from '~/reducers/activeClientsSlice';
import { updateLiveDrivers } from '~/reducers/liveDriversSlice';
import { setOnDemandDispatchTaskCount } from '~/reducers/onDemandDispatchTaskCountSlice';

import socketMessageHandler from '~/utils/socket/socket-message-handler';
import filterSocketMessages from '~/utils/socket/filter-socket-message';

let socket;
const joinedRooms = new Set();
// todo: join with socket-instance.js
async function _handleSocketMessage(message) {
    const { message: socketEvent, clientId, schedulerTaskId, data } = message;
    if (schedulerTaskId && !data.schedulerTaskId) {
        data.schedulerTaskId = schedulerTaskId;
    }
    switch (socketEvent) {
        case constants.socketCustom.SOLUTION:
            socketMessageHandler.handleWebSolution({ data, clientId });
            break;
        case constants.socketCustom.CEP_RESULTS:
            handleCEPMessage(data, clientId);
            break;
        case constants.socketCustom.DIRECT_ROUTE_IMPACT:
            await socketMessageHandler.handleDirectRouteImpact({
                data,
                clientId
            });
            break;
        case constants.socketCustom.DRIVERS:
            store.dispatch(updateLiveDrivers({ drivers: data, clientId }));
            break;
        case constants.socketCustom.METRICS:
            store.dispatch(
                setOnDemandDispatchTaskCount(data.tasks.activeTasks.unassigned)
            );
            break;
        case constants.socketCustom.JOIN_ROOMS:
            store.dispatch(addActiveClient(clientId));
            break;
        case constants.socketCustom.LEAVE_ROOMS:
            store.dispatch(removeActiveClient(clientId));
            break;
        case constants.socketCustom.PAIRING_EXECUTED:
            socketMessageHandler.handlePairingExecuted({ data });
            break;
        case constants.socketCustom.ON_DEMAND_TASK_ADDED:
            socketMessageHandler.handleOnDemandTaskAdded(data);
            break;
        case constants.socketCustom.ON_DEMAND_TASK_ADDED_BULK:
            socketMessageHandler.handleOnDemandTaskAddedBulk();
            break;
        case constants.socketCustom.ON_DEMAND_TASK_REMOVED:
            socketMessageHandler.handleOnDemandTaskRemoved(data);
            break;
        default:
            // socketEvent ignored
            break;
    }
}

function handleCEPMessage(data, clientId) {
    data = filterSocketMessages.filterCepResults({
        data,
        clientId
    });
    socketMessageHandler.handleCepResults({ data, clientId });
}

function connectToServer(payload) {
    socket = io(payload.socketUrl, {
        auth: { token: payload.accessToken }
    });

    socket.on(constants.socketReserved.ERROR, (error) => {
        console.warn('Socket Error', error);
        close();
    });

    socket.on(constants.socketReserved.CONNECT_ERROR, (error) => {
        console.warn('Socket Connect Error', error);
        socket.close();
        joinedRooms.clear();
    });

    socket.on(constants.socketReserved.CONNECT, () => {});
    socket.on(constants.socketCustom.MESSAGE, _handleSocketMessage);
}

function joinClientRooms(payload) {
    for (const clientId of payload.clientIds) {
        if (!joinedRooms.has(clientId)) {
            socket.emit(constants.socketCustom.JOIN_ROOMS, clientId);
            joinedRooms.add(clientId);
        }
    }
}

function leaveRooms(payload) {
    for (const clientId of payload.clientIds) {
        if (joinedRooms.has(clientId)) {
            socket.emit(constants.socketCustom.LEAVE_ROOMS, clientId);
            joinedRooms.delete(clientId);
        }
    }
}

export default {
    connectToServer,
    joinClientRooms,
    leaveRooms
};
