import React from 'react';

import { Icon } from '~/ui';

const Checkbox = React.forwardRef(function Checkbox(
    {
        id = '',
        name = '',
        value = '',
        selectedValues = [],
        disabled = false,
        children,
        className = '',
        handleChange
    },
    ref
) {
    const isChecked = selectedValues.includes(value);

    function _handleChange() {
        if (disabled) {
            return;
        }
        const updatedValues = [...selectedValues];
        const index = updatedValues.indexOf(value);

        if (index !== -1) {
            updatedValues.splice(index, 1);
        } else {
            updatedValues.push(value);
        }
        handleChange({
            isChecked: updatedValues.includes(value),
            value,
            selectedValues: updatedValues
        });
    }

    function _getClassName() {
        let boxClassName = 'checkbox';
        boxClassName =
            (isChecked && `${boxClassName} checkbox_checked`) || boxClassName;
        boxClassName =
            (disabled && `${boxClassName} checkbox_disabled`) || boxClassName;
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }

    return (
        <label htmlFor={id} className={_getClassName()}>
            <input
                type="checkbox"
                id={id}
                name={name}
                value={value}
                ref={ref}
                checked={isChecked}
                disabled={disabled}
                onChange={_handleChange}
                hidden
            />
            <div className="checkbox-box">
                <Icon icon="checkmark" size="xs" color="white" />
            </div>
            {children}
        </label>
    );
});

export default Checkbox;
