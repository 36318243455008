import dateUtils from '~/utils/date-utils';

/**
 * Driver Utilities
 *
 * @category Utils
 * @module utils/driverUtils
 *
 * @example
 * import driverUtils from '~/utils/driver-utils';
 */

/**
 * Check if the driver is available on selected date
 *
 * @param {Object} driver with shift times
 * @param {String} date in ISO format
 * @returns {boolean} is driver available on selected date
 */
function isDriverAvailableOnSelectedDate(driver, date) {
    const dateISO = dateUtils.convertToISODateOnly(date);
    const weekday = dateUtils.getIntWeekDay(date);
    if (driver.props.shiftExceptions?.[dateISO]) {
        return Boolean(driver.props.shiftExceptions[dateISO].length);
    }
    return Boolean(driver.props.shiftTimes[weekday]?.length);
}

/**
 * Format US/Canada phone number
 *
 * @param {string} phoneString phone string
 * @returns {string} formatted phone number (example: (555) 123-4567)
 * @todo find a more international solution
 */
function formatPhoneNumber(phoneString) {
    //  Filter only numbers from the input
    const cleaned = `${phoneString}`.replace(/\D/g, '');

    //  Check if the input is of correct
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        //  Remove the matched extension code
        //  Change this to format for any country code.
        const intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join(
            ''
        );
    }
    return phoneString;
}

export default {
    isDriverAvailableOnSelectedDate,
    formatPhoneNumber
};
