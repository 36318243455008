import _ from 'lodash';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';
import { setReceivedTasks } from '~/reducers/workflowSlice';
import { setTaskMetrics } from '~/reducers/taskMetricsSlice';
import tasksApi from '~/api/tasks';
import taskUtils from '~/utils/task-utils';
import constants from '~/utils/constants';

/**
 * Redux slice for various task-related settings
 * @category Reducers
 * @module tasksSlice
 */

/** @typedef {import('@reduxjs/toolkit').PayloadAction} PayloadAction */

/**
 * retrieves all tasks with desired options and sets into slice
 * @method getAllTasks
 * @async
 * @param {Object} options - the current state
 * @param {String} options.routeDate - the target date in ISO format
 * @param {Number[]} [options.status] - an array of task status codes
 * @param {Number} [options.limit] - the map mode setting value to set
 * @returns {Object} the updated state
 * @example <caption>Usage</caption>
 * // import statement
 * import { getAllTasks } from '~/reducers/tasksSlice';
 * import { useDispatch } from 'react-redux';
 *
 * // retrieve all tasks on Pi Day 2022 (14 Mar 2022)
 * const dispatch = useDispatch();
 * const selectedDate = '2022-03-14T00:00:00.000-07:00';
 * dispatch(getAllTasks({ routeDate: selectedDate }));
 *
 * // retrieve only `dispatched` tasks
 * dispatch(getAllTasks({ routeDate: selectedDate, status: [1] }));
 *
 * // retrieve only `unassigned` and `planned` tasks, with max of 150 results
 * dispatch(getAllTasks({ routeDate: selectedDate, status: [0, 5], limit: 150 }));
 *
 * @example <caption>Unwrapping result actions</caption>
 * // import statement
 * import { getAllTasks } from '~/reducers/tasksSlice';
 * import { useDispatch } from 'react-redux';
 *
 * // retrieve only `unassigned` and `planned` tasks, with max of 150 results
 * const dispatch = useDispatch();
 * const selectedDate = '2022-03-14T00:00:00.000-07:00';
 * const onClick = async () => {
 *     try {
 *         const result = await dispatch(getAllTasks({ routeDate: selectedDate, status: [0, 5], limit: 150 })).unwrap();
 *         // handle result here
 *     } catch (rejectedValueOrSerializedError) {
 *         // handle error here
 *     }
 * }
 *
 * @see {@link https://redux-toolkit.js.org/api/createAsyncThunk}
 */
export const getAllTasks = createAsyncThunk(
    'tasks/getAllTasks',
    async (
        { routeDate, status, limit },
        { dispatch, getState, fulfillWithValue, rejectWithValue }
    ) => {
        try {
            if (!routeDate) {
                const { selectedDate } = getState();
                routeDate = selectedDate;
            }

            // get task metrics and total task count
            const taskMetricsResponse = await tasksApi.getMetrics({
                date: routeDate
            });
            const taskMetrics = taskMetricsResponse.data.data;
            const totalTaskNum = taskMetrics.total;
            const apiStatus = taskUtils.getApiStatusFromWebStatus(status);

            // get tasks
            const tasksResponse = await tasksApi.get({
                date: routeDate,
                extent: 'deliveryLocation,pickupLocation',
                status: apiStatus,
                limit: _.isInteger(limit) ? limit : totalTaskNum
            });

            // set received tasks in workflow page
            dispatch(
                setReceivedTasks({
                    routeDate,
                    receivedTasks: totalTaskNum
                })
            );

            // remove depots from tasks and task metrics
            const [depotTasks, tasks] = _.partition(
                tasksResponse.data.data,
                taskUtils.checkIsDepot
            );
            const updatedTaskMetrics = taskUtils.removeDepotsFromTaskMetrics(
                depotTasks,
                taskMetrics
            );
            dispatch(setTaskMetrics(updatedTaskMetrics));

            // filter and format tasks for web
            const updatedTasks = taskUtils.formatApiTasksToWebTasks(tasks);
            const filteredTasks = taskUtils.filterTasksByTaskStatus(
                updatedTasks,
                status
            );
            const tasksById = _.keyBy(filteredTasks, 'id');

            return fulfillWithValue(tasksById);
        } catch (error) {
            dispatch(
                setReceivedTasks({
                    routeDate,
                    receivedTasks: 0
                })
            );
            return rejectWithValue({});
        }
    }
);

export const tasksSlice = createSlice({
    name: 'tasks',
    initialState: {},
    reducers: {
        /**
         * add new task to the tasks slice
         * @method addNewTask
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {Object} action.payload - the task object to add
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // Add new tasks from API response
         * (async () => {
         *     try {
         *         const newTasksResponses = await Promise.all(
         *             selectedTaskIds.map((taskId) => tasksApi.splitTask(taskId))
         *         );
         *         for (const taskResponse of newTasksResponses) {
         *             const deliveryTask = taskResponse.data.data.delivery;
         *             dispatch(addNewTask(deliveryTask));
         *             const pickupTask = taskResponse.data.data.pickup;
         *             dispatch(addNewTask(pickupTask));
         *         }
         *         // handle result here
         *     } catch (e) {
         *         console.error(e);
         *         // handle error here
         *     }
         * })();
         */
        addNewTask: (state, action) => {
            const task = action.payload;
            const taskStatus = taskUtils.getTaskStatus(task);
            state = { [task.id]: { taskStatus, ...task }, ...state };
            return state;
        },
        /**
         * removes task from the tasks slice
         * @method addNewTask
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {String[]} action.payload - the task IDs to remove from the slice
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { removeTasksById } from '~/reducers/tasksSlice';
         *
         * // remove tasks
         * const selectedTaskIds = ['foo', 'bar', 'baz'];
         * dispatch(removeTasksById(selectedTaskIds));
         */
        removeTasksById: (state, action) => {
            const taskIds = action.payload;
            taskIds.forEach((id) => delete state[id]);
        },
        /**
         * update a task property
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {Object} action.payload - the reducer's payload
         * @param {String} action.payload.taskId - the task ID to update
         * @param {String} action.payload.property - the task property to update
         * @param {*} action.payload.value - the task property value to set
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { updateTaskProperty } from '~/reducers/tasksSlice';
         *
         * // Add update tasks property from API response
         * async onClick = ({ taskId, property, value }) => {
         *     try {
         *         await tasksApi.update(taskId, {
         *             [property]: value
         *         });
         *         dispatch(updateTaskProperty({ taskId, property, value }));
         *         // handle result here
         *     } catch (e) {
         *         console.error(e);
         *         // handle error here
         *     }
         * }
         */
        updateTaskProperty: (state, action) => {
            const { taskId, property, value } = action.payload;
            const newState = {
                ...state,
                [taskId]: { ...state[taskId], [property]: { ...value } }
            };
            return newState;
        },
        /**
         * update a task object
         * @param {Object} state - the current state
         * @param {PayloadAction} action - the reducer's action object
         * @param {Object} action.payload - the reducer's payload
         * @param {String} action.payload.taskId - the task ID to update
         * @param {*} action.payload.value - the task object
         * @returns {Object} the updated state
         * @example <caption>Usage</caption>
         * // import statement
         * import { updateTaskById } from '~/reducers/tasksSlice';
         *
         * // Add update the task identified by task ID from API response
         * async onClick = ({ taskId, property, value }) => {
         *     try {
         *         await tasksApi.update(taskId, {
         *             [property]: value
         *         });
         *         const getResponse = await tasksApi.getTask(taskId, {
         *             extent: property
         *         });
         *         const updatedTask = getResponse.data.data;
         *         const taskStatus = taskUtils.getTaskStatus(updatedTask);
         *         dispatch(
         *             updateTaskById({
         *                 taskId,
         *                 value: { ...updatedTask, taskStatus }
         *             })
         *         );
         *         // handle result here
         *     } catch (e) {
         *         console.error(e);
         *         // handle error here
         *     }
         * }
         */
        updateTaskById: (state, action) => {
            const { taskId, value } = action.payload;
            return {
                ...state,
                [taskId]: { ...value }
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return {};
        });
        builder.addCase(getAllTasks.fulfilled, (state, action) => {
            state = action.payload;
            return state;
        });
        builder.addCase(getAllTasks.rejected, (state, action) => {
            state = action.payload;
            return state;
        });
    }
});

export const {
    addNewTask,
    removeTasksById,
    updateTaskProperty,
    updateTaskById
} = tasksSlice.actions;

/**
 * selects a task matching by task ID
 * @method selectTaskById
 * @param {Object} state - the current state
 * @param {String} id - the task ID
 * @returns {Object} the desired task
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectTaskById } from '~/reducers/tasksSlice';
 * import { useSelector } from 'react-redux';
 *
 * // returns array of tasks matching selected task IDs
 * const selectedTaskId = 'foo';
 * const selectedTasks = useSelector((state) => selectTaskById(state, selectedTaskId));
 */
export const selectTaskById = (state, id) => state.tasks[id];

/**
 * selects tasks filtered by task ID
 * @method selectTasksById
 * @param {Object} state - the current state
 * @param {String[]} ids - an array of task IDs
 * @returns {Object[]} an array of tasks matching selected task IDs
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectTasksById } from '~/reducers/tasksSlice';
 * import { useSelector } from 'react-redux';
 *
 * // returns array of tasks matching selected task IDs
 * const selectedTaskIds = ['foo', 'bar', 'baz'];
 * const selectedTasks = useSelector((state) => selectTasksById(state, selectedTaskIds));
 */
export const selectTasksById = (state, ids) => ids.map((id) => state.tasks[id]);

/**
 * selects the current tasks filtered by task status
 * @method selectTasksByStatus
 * @param {Object} state - the current state
 * @param {(Number|Number[])} taskStatus - a task status code or an array of task status codes
 * @returns {Object[]} an array of tasks matching desired taskStatus
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectTasksByStatus } from '~/reducers/tasksSlice';
 * import { useSelector } from 'react-redux';
 *
 * // returns array of `dispatched (taskStatus: 1)` tasks
 * const selectedUnassignedTasks = useSelector((state) => selectTasksByStatus(state, 1));
 *
 * // returns array of `unassigned (taskStatus: 0)` and `planned (taskStatus: 5)` tasks
 * const selectedOnDemandTasks = useSelector((state) => selectTasksByStatus(state, [0, 5]));
 */
export const selectTasksByStatus = (state, taskStatus) => {
    // set single status code to array
    const desiredTaskStatus = _.isInteger(taskStatus)
        ? [taskStatus]
        : taskStatus;

    // filter tasks
    return Object.values(state.tasks).filter((task) =>
        desiredTaskStatus.includes(task.taskStatus)
    );
};

/**
 * Selects tasks that are elligible for on-demand dispatch
 * This method provides a convenient binding to {@link selectTasksByStatus}
 * with the relevant task statuses for on-demand dispatch. Using
 * this binding avoids having to re-bind to the selectior in a
 * functional component every time it renders.
 * @method selectOnDemandDispatchTasks
 * @param {Object} state - the current state
 * @returns {Object[]} an array of tasks elligible for on-demand dispatch
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectOnDemandDispatchTasks } from '~/reducers/tasksSlice';
 * import { useSelector } from 'react-redux';
 *
 * // returns array of `unassigned (taskStatus: 0)` and `planned (taskStatus: 5)` tasks
 * const selectedOnDemandTasks = useSelector(selectOnDemandDispatchTasks);
 */
export const selectOnDemandDispatchTasks = (state) =>
    selectTasksByStatus(state, [
        constants.taskStatus.UNASSIGNED,
        constants.taskStatus.PLANNED
    ]);

/**
 * Selects unassigned tasks
 * @method selectUnassignedPlanTasks
 * @param {Object} state - the current state
 * @returns {Object[]} an array of unassigned tasks
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectUnassignedPlanTasks } from '~/reducers/tasksSlice';
 * import { useSelector } from 'react-redux';
 *
 * // returns array of `unassigned (taskStatus: 0)` tasks
 * const selectUnassignedTasks = useSelector(selectUnassignedPlanTasks);
 */
export const selectUnassignedPlanTasks = (state) =>
    selectTasksByStatus(state, [constants.taskStatus.UNASSIGNED]);

/**
 * selects all the current tasks in the slice
 * @method selectTasks
 * @param {Object} state - the current state
 * @returns {Object[]} an array of tasks
 * @example <caption>Usage</caption>
 * // import statement
 * import { selectTasks } from '~/reducers/tasksSlice';
 * import { useSelector } from 'react-redux';
 *
 * // returns all current tasks
 * const tasks = useSelector(selectTasks);
 */
export const selectTasks = (state) => state.tasks;

export default tasksSlice.reducer;
