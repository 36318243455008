import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';

export const activeClientsSlice = createSlice({
    name: 'activeClients',
    initialState: {},
    reducers: {
        addActiveClient: (state, action) => {
            state[action.payload] = true;
            return state;
        },
        removeActiveClient: (state, action) => {
            delete state[action.payload];
            return state;
        },
        resetActiveClients: () => {
            return {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return {};
        });
    }
});

export const { addActiveClient, removeActiveClient, resetActiveClients } =
    activeClientsSlice.actions;
export const selectActiveClients = (state) => state.activeClients;
export const selectActiveClientsArray = (state) => {
    if (!state || !state.activeClients) {
        return [];
    }
    return Object.keys(state.activeClients);
};
export default activeClientsSlice.reducer;
