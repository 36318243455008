import React, { useState } from 'react';
import { IconButton } from '~/ui';
import './TaskDetailDrawer.scss';

function TaskDetailDrawerItem({ title, children }) {
    const [isOpen, setIsOpen] = useState(true);
    let taskDetailDrawerItemClass = 'taskdetaildrawer-item';
    let iconButtonClass = 'drawer-button _t-rotate-180';

    if (isOpen) {
        taskDetailDrawerItemClass += ' taskdetaildrawer-item_open';
        iconButtonClass = 'drawer-button';
    }

    function showDetails() {
        setIsOpen(!isOpen);
    }

    return (
        <div className={taskDetailDrawerItemClass}>
            <div className="_d-flex _jc-space-between _ai-center">
                <span className="heading-2">{title}</span>
                <IconButton
                    className={iconButtonClass}
                    icon="chevronDown"
                    size="xs"
                    color="galaxy-800"
                    onClick={showDetails}
                />
            </div>
            <div className="taskdetaildrawer-item-body">{children}</div>
        </div>
    );
}

export default TaskDetailDrawerItem;
