import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    addSelectedMapStop,
    removeSelectedMapStop,
    selectSelectedMapStops
} from '~/reducers/selectedMapStopsSlice';
import { idUtils } from '~/utils/id-utils';
import constants from '~/utils/constants';

/**
 * A custom hook that provides an emitted event handler suitable for use with
 * a stop marker emitting events described in constants.mapChildEvents. This
 * emitted event handler is meant to be used for on demand dispatch.
 * @returns {Function} the emitted event handler
 */
export const useOnDemandDispatchMarkerEventHandler = () => {
    const selectedMapStops = useSelector(selectSelectedMapStops);
    const canSelectTask =
        selectedMapStops.length <
        constants.onDemandDispatch.CONTROL_DISPATCH_LIMIT;
    const dispatch = useDispatch();

    const _toggleStopsSelected = useCallback(
        (stopId, isSelected, isTwoPart) => {
            const toggleForIds = [stopId];
            if (isTwoPart) {
                toggleForIds.push(idUtils.getComplementaryStopId(stopId));
            }
            for (const id of toggleForIds) {
                if (isSelected) {
                    dispatch(removeSelectedMapStop(id));
                } else if (canSelectTask) {
                    dispatch(addSelectedMapStop(id));
                }
            }
        },
        [canSelectTask]
    );

    const _handleStopMouseUp = useCallback(
        (payload) => {
            _toggleStopsSelected(
                payload.id,
                payload.isSelected,
                payload.isTwoPart
            );
        },
        [_toggleStopsSelected]
    );
    const emittedEventHandler = useCallback(
        ({ event = '', payload = {} }) => {
            const { mapChildEvents } = constants;

            switch (event) {
                case mapChildEvents.STOP_MOUSEUP:
                    _handleStopMouseUp(payload);
                    break;
                default:
                    break;
            }
        },
        [_handleStopMouseUp]
    );

    return emittedEventHandler;
};
