import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { resetToInitialState } from '~/reducers/common-actions';

function _setLatestEditHistoryForClient(state, action) {
    const newState = state ? _.cloneDeep(state) : {};
    const newData = {
        [action.payload.clientId]: {
            destination: {
                routeId: action.payload.data.destination.route.id,
                routeInfo: action.payload.data.destination.route.routeInfo
            },
            origin: {
                routeId: action.payload.data.origin.route.id,
                routeInfo: action.payload.data.origin.route.routeInfo
            }
        }
    };
    return Object.assign(newState, newData);
}
export const latestEditHistoryByClientSlice = createSlice({
    name: 'latestEditHistoryByClient',
    initialState: null,
    reducers: {
        setLatestEditHistoryForClient: _setLatestEditHistoryForClient,
        resetLatestEditHistoryByClient: () => {
            return null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return null;
        });
    }
});

export const { setLatestEditHistoryForClient, resetLatestEditHistoryByClient } =
    latestEditHistoryByClientSlice.actions;
export const selectLatestEditHistoryByClient = (state) =>
    state.latestEditHistoryByClient;
export default latestEditHistoryByClientSlice.reducer;
