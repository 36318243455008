import React from 'react';
import { useTranslation } from 'react-i18next';
import constants from '~/utils/constants';
import { Icon } from '~/ui';
import './InventoryItemStatus.scss';

function InventoryItemStatus({ status }) {
    const { t } = useTranslation('taskManagement');
    let statusClassName = 'inventoryitemstatus';
    let statusName = '';
    let showCheckmark = false;

    switch (status) {
        case constants.inventoryItemStatus.UNCONFIRMED:
            statusClassName += ' inventoryitemstatus--unconfirmed';
            statusName = t('drawer.inventoryItemStatus.unconfirmed');
            break;
        case constants.inventoryItemStatus.CONFIRMED:
            statusClassName += ' inventoryitemstatus--confirmed';
            statusName = t('drawer.inventoryItemStatus.confirmed');
            showCheckmark = true;
            break;
        case constants.inventoryItemStatus.CANCELLED:
            statusClassName += ' inventoryitemstatus--cancelled';
            statusName = t('drawer.inventoryItemStatus.cancelled');
            break;
        case constants.inventoryItemStatus.PARTIAL:
            statusClassName += ' inventoryitemstatus--partial';
            statusName = t('drawer.inventoryItemStatus.partial');
            showCheckmark = true;
            break;
        default:
            break;
    }
    return (
        <div className={statusClassName}>
            <div>{statusName}</div>
            {showCheckmark && <Icon icon="checkmark" color="comet" size="s" />}
        </div>
    );
}

export default InventoryItemStatus;
