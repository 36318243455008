import React, { useState, useMemo } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TaskHistoryStatus from '~/components/TaskManagementPage/TaskDetailDrawer/TaskHistory/TaskHistoryStatus/TaskHistoryStatus';
import constants from '~/utils/constants';
import { addToast } from '~/reducers/toastsSlice';
import tasksApi from '~/api/tasks';
import taskUtils from '~/utils/task-utils';

function TaskHistory({ task }) {
    const { t } = useTranslation('taskManagement');
    const dispatch = useDispatch();
    const [taskDispatchedAt, setDispatchTimestamp] = useState(null);
    const [taskCompletedAt, setCompleteTimestamp] = useState(null);

    const taskHistoryStatusStates = {
        default: 'default',
        focus: 'focus',
        completed: 'completed'
    };
    const taskHistoryStatuses = computeTaskHistoryStatusState();
    const isCancelled = task.taskStatus === constants.taskStatus.CANCELLED;
    const isCompleted = task.taskStatus === constants.taskStatus.COMPLETED;
    const taskAssignedAt = task.assignedToRouteAt;

    async function getAssignmentsForTask() {
        try {
            const result = await tasksApi.getTaskAssignments(task.id);
            const taskAssignments = result.data.data;
            const taskDispatchedAtTimestamp =
                !_.isNil(taskAssignments) && !_.isEmpty(taskAssignments)
                    ? taskAssignments[0].createdAt
                    : null;

            setDispatchTimestamp(taskDispatchedAtTimestamp);

            const latestCompletedTask = _.maxBy(
                _.filter(taskAssignments, 'completedAt'),
                'completedAt'
            );

            const taskCompletedAtTimestamp =
                isCompleted && !_.isNil(latestCompletedTask)
                    ? latestCompletedTask.completedAt
                    : null;

            setCompleteTimestamp(taskCompletedAtTimestamp);
        } catch (error) {
            console.error(error);
            dispatch(
                addToast({
                    message: t('error:taskHistoryError'),
                    variant: 'error'
                })
            );
            setDispatchTimestamp(null);
        }
    }

    useMemo(() => getAssignmentsForTask(), [task.id]);

    function computeTaskHistoryStatusState() {
        switch (task.taskStatus) {
            case constants.taskStatus.PLANNED:
                return {
                    created: taskHistoryStatusStates.completed,
                    assigned: taskHistoryStatusStates.focus,
                    dispatched: taskHistoryStatusStates.default,
                    completed: taskHistoryStatusStates.default
                };
            case constants.taskStatus.IN_PROGRESS:
            case constants.taskStatus.DISPATCHED:
                return {
                    created: taskHistoryStatusStates.completed,
                    assigned: taskHistoryStatusStates.completed,
                    dispatched: taskHistoryStatusStates.focus,
                    completed: taskHistoryStatusStates.default
                };
            case constants.taskStatus.COMPLETED:
                return {
                    created: taskHistoryStatusStates.completed,
                    assigned: taskHistoryStatusStates.completed,
                    dispatched: taskHistoryStatusStates.completed,
                    completed: taskHistoryStatusStates.focus
                };
            case constants.taskStatus.CANCELLED:
                return {
                    created: taskHistoryStatusStates.completed,
                    assigned: taskHistoryStatusStates.completed,
                    dispatched: taskHistoryStatusStates.completed,
                    cancelled: taskHistoryStatusStates.focus
                };
            default:
                return {
                    created: taskHistoryStatusStates.focus,
                    assigned: taskHistoryStatusStates.default,
                    dispatched: taskHistoryStatusStates.default,
                    completed: taskHistoryStatusStates.default
                };
        }
    }

    return (
        <div className="_mt-6">
            <TaskHistoryStatus
                title={t('taskHistory.taskCreated')}
                date={taskUtils.formatTimestamp(task.createdAt)}
                icon="add"
                state={taskHistoryStatuses.created}
            />
            <TaskHistoryStatus
                title={t('taskHistory.taskAssigned')}
                date={taskUtils.formatTimestamp(taskAssignedAt)}
                icon="routeLine"
                state={taskHistoryStatuses.assigned}
            />
            <TaskHistoryStatus
                title={t('taskHistory.taskDispatched')}
                date={taskUtils.formatTimestamp(taskDispatchedAt)}
                icon="vehicleEmpty"
                state={taskHistoryStatuses.dispatched}
            />
            {!isCancelled ? (
                <TaskHistoryStatus
                    title={t('taskHistory.taskCompleted')}
                    date={
                        isCompleted
                            ? taskUtils.formatTimestamp(taskCompletedAt)
                            : ''
                    }
                    icon="checkmark"
                    state={taskHistoryStatuses.completed}
                    hideLine
                />
            ) : (
                <TaskHistoryStatus
                    title={t('taskHistory.taskCancelled')}
                    date={
                        isCancelled
                            ? taskUtils.formatTimestamp(task.updatedAt)
                            : ''
                    }
                    icon="line"
                    state={taskHistoryStatuses.cancelled}
                    hideLine
                />
            )}
        </div>
    );
}

export default TaskHistory;
