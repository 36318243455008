import React, { useState, useEffect } from 'react';
import PT from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import {
    getAssignmentCodeLabel,
    getDelayCodeLabel
} from '~/utils/assignment-utils';

import dateUtilsConverters from '~/utils/date-utils-converters';
import { RouteCardSummary } from '~/ui';

import './routecard-statusbar.scss';

function RouteCardStatusBar({
    className,
    numStops,
    currentStopIndex,
    schedule = [],
    timeRemaining,
    driverOffline = true,
    isScheduleCompleted = false
}) {
    const [currentStopIsDepot, setCurrentStopIsDepot] = useState(false);
    const [currentStopNumber, setCurrentStopNumber] = useState(0);
    const [currentStopType, setCurrentStopType] = useState();
    const [currentStopFinish, setCurrentStopFinish] = useState();
    const [estimatedFinish, setEstimatedFinish] = useState();
    const [routeStatusGradient, setRouteStatusGradient] = useState();
    const [routeStatusPosition, setRouteStatusPosition] = useState();
    const { t } = useTranslation('translation');

    useEffect(() => {
        setCurrentStopType(
            schedule &&
                schedule[currentStopIndex] &&
                schedule[currentStopIndex].type
        );
        setCurrentStopIsDepot(
            schedule &&
                schedule[currentStopIndex] &&
                schedule[currentStopIndex].isDepot
        );
    }, [schedule, currentStopIndex]);

    useEffect(() => {
        setCurrentStopNumber(
            (isScheduleCompleted && numStops) || currentStopIndex
        );
    }, [isScheduleCompleted, numStops, currentStopIndex]);

    useEffect(() => {
        const currentSchedule = schedule[currentStopNumber];
        if (!currentSchedule) return;

        const { completedAt } = currentSchedule;
        setCurrentStopFinish(DateTime.fromISO(completedAt).toFormat('t'));
    }, [currentStopNumber]);

    useEffect(() => {
        if (
            !schedule ||
            schedule.length === 0 ||
            (currentStopIsDepot && !isScheduleCompleted)
        ) {
            setRouteStatusGradient();
            setRouteStatusPosition();
            return;
        }

        const stops = schedule.filter((item) => {
            return !item.isDepot;
        });

        const stopsLength = stops.length;
        const currentStop = stops.findIndex((item) => !item.isCompleted);

        const statusBar = stops.reduce((compiled, item, idx) => {
            const { gradient, position, statusColor } = compiled;
            const { status: assignmentCode, delay: delayCode } = item;
            const start = (idx / stopsLength) * 100;
            const end = ((idx + 1) / stopsLength) * 100;
            const isStopCompleted = currentStop < 0 || idx < currentStop;

            const delayStatus = getDelayCodeLabel(delayCode);
            const assignmentStatus = getAssignmentCodeLabel(assignmentCode);

            const color =
                (isStopCompleted &&
                    `--color-status-${delayStatus}-completed`) ||
                `--color-status-${delayStatus}`;
            const band = `var(${color}) ${start}%, var(${color}) ${end}%`;

            return {
                gradient:
                    gradient && gradient.length > 0
                        ? `${gradient}, ${band}`
                        : band,
                position:
                    (currentStop < 0 && 100) ||
                    (idx === currentStop && start) ||
                    position,
                statusColor:
                    (driverOffline && '--color-driver-offline') ||
                    (currentStop < 0 && `--color-assignment-COMPLETED`) ||
                    (idx === currentStop &&
                        `--color-assignment-${assignmentStatus}`) ||
                    statusColor
            };
        }, {});

        setRouteStatusGradient({
            background: `linear-gradient(90deg, ${statusBar.gradient})`
        });
        setRouteStatusPosition({
            left: `${statusBar.position}%`,
            backgroundColor: `var(${statusBar.statusColor})`
        });
    }, [schedule, currentStopIsDepot, isScheduleCompleted]);

    useEffect(() => {
        if (!timeRemaining) {
            setEstimatedFinish();
            return;
        }

        const duration =
            dateUtilsConverters.convertMillisecondsToHoursAndMinutes(
                timeRemaining
            );
        const eta = DateTime.local().plus(duration).toFormat('t');
        setEstimatedFinish(eta);
    }, [timeRemaining]);

    function _getClassName() {
        let boxClassName = 'routecard-statusbar';
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }

    const statusMetrics = [
        {
            metric: 'stops',
            value: currentStopNumber,
            maxValue: numStops,
            icon: 'stops',
            iconColor: 'galaxy-500'
        },
        {
            metric: 'time-remaining',
            value: isScheduleCompleted
                ? t('completedTime', { time: currentStopFinish })
                : t('finishTime', { time: estimatedFinish })
        }
    ];

    return (
        <div className={_getClassName()}>
            <RouteCardSummary
                className="_jc-space-between"
                metrics={statusMetrics}
            />
            <div className="gradient _p-relative" style={routeStatusGradient}>
                <div
                    className={`gradient_pointer _d-flex _ai-center _jc-center _p-absolute pointer_${currentStopType}`}
                    style={routeStatusPosition}
                    data-offline={driverOffline || null}
                />
            </div>
        </div>
    );
}

RouteCardStatusBar.propTypes = {
    /** additional css classes to attach to this component */
    className: PT.string
};

export default RouteCardStatusBar;
