import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';

export const selectedDrawerCardDataSlice = createSlice({
    name: 'selectedDrawerCardData',
    initialState: null,
    reducers: {
        setSelectedDrawerCardData: (state, action) => {
            return action.payload;
        },
        resetSelectedDrawerCardData: () => {
            return null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return null;
        });
    }
});

export const { setSelectedDrawerCardData, resetSelectedDrawerCardData } =
    selectedDrawerCardDataSlice.actions;

export const selectSelectedDrawerCardData = (state) =>
    state.selectedDrawerCardData;

export default selectedDrawerCardDataSlice.reducer;
