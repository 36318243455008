import React from 'react';
import { DrawerButtonGroup, IconButton, Icon } from '~/ui';

import './drawer-notice.scss';

function DrawerNotice({
    noticeTitle,
    noticeBody,
    icon,
    iconColor = 'galaxy-800',
    iconSize = 's',
    onClick = () => {}
}) {
    function _handleOnClick() {
        return onClick && onClick();
    }

    function _getIcon() {
        if (!icon || !icon.length) return null;
        return <Icon icon={icon} size={iconSize} color={iconColor} />;
    }

    return (
        <div className="drawer-notice">
            <DrawerButtonGroup className="_jc-space-between">
                <div className="drawer-notice_title _d-flex _ai-center">
                    {_getIcon()}
                    <span>{noticeTitle}</span>
                </div>
                <IconButton
                    className="drawer-notice_close"
                    icon="cancel"
                    iconColor="galaxy-800"
                    onClick={_handleOnClick}
                />
            </DrawerButtonGroup>
            <div className="drawer-notice_text _text-3-alt">{noticeBody}</div>
        </div>
    );
}

export default DrawerNotice;
