import PT from 'prop-types';
import React, { useRef } from 'react';
import ReactDOM from 'react-dom';

import { IconButton, useClickOutside } from '~/ui';

function Modal({
    children,
    hasCustomHeader = false,
    hasAutoHide = true,
    hasCloseButton = true,
    title,
    onHide,
    variant = 'small',
    className,
    overlayClassName
}) {
    const domElement = document.getElementById('modal-root');
    const ref = useRef();

    function autoHide() {
        return hasAutoHide && onHide();
    }

    useClickOutside(ref, autoHide);

    // Modal will be rendered  under modal-root div
    return ReactDOM.createPortal(
        <div
            className={`overlay${
                overlayClassName ? ` ${overlayClassName}` : ''
            }`}
        >
            <div className={`modal modal_${variant} ${className}`} ref={ref}>
                <div className="_d-flex _fd-column modal-content-wrapper">
                    {hasCustomHeader ? (
                        <>{children}</>
                    ) : (
                        <>
                            <div className="modal-header">
                                <div>{title}</div>
                                {hasCloseButton && (
                                    <IconButton
                                        className="icon-wrapper"
                                        icon="cancel"
                                        iconColor="galaxy-800"
                                        onClick={onHide}
                                    />
                                )}
                            </div>
                            {children}
                        </>
                    )}
                </div>
            </div>
        </div>,
        domElement
    );
}

Modal.propTypes = {
    /* Modal content */
    children: PT.node,
    /* Hide on click outside of modal */
    hasAutoHide: PT.bool,
    /* Has a close button */
    hasCloseButton: PT.bool,
    /* Modal title */
    title: PT.node,
    /* Hide modal function */
    onHide: PT.func,
    /* Style variant */
    variant: PT.oneOf(['small', 'large', 'dialogue', 'date']),
    /* Class name */
    className: PT.string
};

export default Modal;
