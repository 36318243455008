import React from 'react';
import classNames from 'classnames';

import './manage-stop-control.scss';

function ManageStopControl({ children, className }) {
    function _getClassName() {
        const defaultClassName = `managestopcontrol _d-flex _fd-column`;
        return classNames(defaultClassName, className);
    }
    return <div className={_getClassName()}>{children}</div>;
}

export { ManageStopControl };
