import React from 'react';
import { RouteCardText } from '~/ui';
import localizationUtils from '~/utils/localization-utils';

function RouteCardAddress({ address, icon }) {
    return (
        <RouteCardText className="routecard-address" icon={icon}>
            {localizationUtils.formatAddress(address)}
        </RouteCardText>
    );
}

export default RouteCardAddress;
