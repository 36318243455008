import constants from '~/utils/constants';

/**
 * ID Utilities
 *
 * @category Utils
 * @module utils/idUtils
 *
 * @example
 * import { idUtils } from '~/utils/id-utils';
 */

/**
 * Returns a combined id in the form of "id1_id2_id3_..."
 * @param  {...string} ids array of ids to combine
 * @returns {string} combined id in the form of "id1_id2_id3_..."
 */
function getCombinedId(...ids) {
    return ids.join('_');
}

/**
 * Splits the combined id around the '_' separator
 * @param {string} combinedId id to split
 * @returns {string[]} the split id
 */
function splitCombinedId(combinedId) {
    if (typeof combinedId !== 'string') return [];

    return combinedId.split('_');
}

/**
 * Analyzes the reassignment operation of the provided tasks to the
 * target route. This analysis is based on the encoding of the combined
 * task ids which contain their current route id and client id. The analysis
 * partitions the task ids by task status and provides additional metrics
 * such as whether all selected tasks are planned, unplanned, from the same
 * route or from the same client.
 * @param {string[]} combinedIds ids of tasks to be moved
 * @param {string} targetClientRouteId client route id of target route
 * @returns {*} object containing analysis results
 */
function analyzeCombinedIds(combinedIds, targetClientRouteId) {
    const unplanned = constants.entityStates.UNPLANNED;
    let targetClientId;
    if (targetClientRouteId) {
        [targetClientId] = splitCombinedId(targetClientRouteId);
    }
    const analysis = {
        areAllPlanned: true,
        areAllUnplanned: true,
        areAllFromOneClientRoute: true,
        areAllFromTargetClient: true,
        plannedTaskIds: [],
        unplannedTaskIds: [],
        numTasksToRemoveByClientRouteId: {}
    };
    for (const combinedId of combinedIds) {
        const [clientId, routeId, taskId] = splitCombinedId(combinedId);
        const clientRouteId = getCombinedId(clientId, routeId);
        if (routeId === unplanned) {
            analysis.areAllPlanned = false;
            analysis.unplannedTaskIds.push(taskId);
        } else {
            analysis.areAllUnplanned = false;
            analysis.plannedTaskIds.push(taskId);
        }
        if (!analysis.numTasksToRemoveByClientRouteId[clientRouteId]) {
            analysis.numTasksToRemoveByClientRouteId[clientRouteId] = 1;
        } else {
            analysis.numTasksToRemoveByClientRouteId[clientRouteId]++;
        }
        if (clientId !== targetClientId) {
            analysis.areAllFromTargetClient = false;
        }
    }
    if (Object.keys(analysis.numTasksToRemoveByClientRouteId).length > 1) {
        analysis.areAllFromOneClientRoute = false;
    }
    return analysis;
}

/**
 * For two-part task stop ids in the form of clientId_routeId_taskType_taskId,
 * return the complementary id for the stop of the other type.
 * @param {string} stopId stop id for which to get the complement
 * @returns {string} the complementary id
 */
function getComplementaryStopId(stopId) {
    const { DELIVERY, PICKUP } = constants.taskTypes;
    if (stopId.includes(DELIVERY)) {
        return stopId.replace(DELIVERY, PICKUP);
    }
    if (stopId.includes(PICKUP)) {
        return stopId.replace(PICKUP, DELIVERY);
    }
}

export const idUtils = {
    getCombinedId,
    splitCombinedId,
    analyzeCombinedIds,
    getComplementaryStopId
};
