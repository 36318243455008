import React from 'react';
import './routecard-text.scss';

import { IconButton } from '~/ui';

function RouteCardText({
    className,
    text,
    children,
    icon,
    iconColor = 'galaxy-500'
}) {
    function _getClassName() {
        let boxClassName = 'routecard-text';
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }

    function _getIcon() {
        if (!icon || !icon.length) return null;
        return <IconButton icon={icon} iconColor={iconColor} />;
    }

    return (
        <div className={_getClassName()}>
            <div className="routecard-text_group _d-flex _ai-center">
                {_getIcon()}
                <span>{children}</span>
            </div>
            {text}
        </div>
    );
}

export default RouteCardText;
