import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';
import constants from '~/utils/constants';

export const defaultRoutesDrawerSort = {
    isSorted: false,
    isSortActive: false,
    sortMetric: null,
    sortDirection: constants.sortOrder.DESC
};

export const routesDrawerSortSlice = createSlice({
    name: 'routesDrawerSort',
    initialState: defaultRoutesDrawerSort,
    reducers: {
        setIsSortActive: (state, action) => {
            return { ...state, isSortActive: action.payload };
        },
        setSortMetric: (state, action) => {
            return {
                ...state,
                sortMetric: action.payload,
                isSorted: Boolean(action.payload)
            };
        },
        setSortDirection: (state, action) => {
            return { ...state, sortDirection: action.payload };
        },
        resetRoutesDrawerSort: () => {
            return defaultRoutesDrawerSort;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return defaultRoutesDrawerSort;
        });
    }
});

export const {
    setIsSortActive,
    setSortMetric,
    setSortDirection,
    resetRoutesDrawerSort
} = routesDrawerSortSlice.actions;

export const selectRoutesDrawerSort = (state) => state.routesDrawerSort;

export const selectIsSorted = (state) => state.routesDrawerFilter.isFiltered;

export const selectIsSortActive = (state) =>
    state.routesDrawerSort.isSortActive;

export const selectSortMetric = (state) => state.routesDrawerSort.sortMetric;

export const selectSortDirection = (state) =>
    state.routesDrawerSort.sortDirection;

export default routesDrawerSortSlice.reducer;
