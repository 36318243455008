import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import {
    selectShowStopLabel,
    selectShowStopNumber,
    selectShowStopPopup
} from '~/reducers/mapSettingsSlice';

import { Icon } from '~/ui/';
import constants from '~/utils/constants';
import { selectSelectedMapStops } from '~/reducers/selectedMapStopsSlice';

import './live-stop-marker.scss';

function LiveStopMarker({
    sequenceNumber,
    stopData,
    status = constants.liveStopMarkerStatus.default,
    mapRouteMode,
    colorCSS = {},
    onDemandDispatchMarkerEventHandler,
    lat,
    lng,
    emittedEventHandler
}) {
    if (!stopData) {
        return null;
    }

    const { isAtRisk, isCompleted, isLate, hasInventoryException } = stopData;
    const [isDisabled, setIsDisabled] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const selectedMapStops = useSelector(selectSelectedMapStops);
    const { backgroundColor, color } = colorCSS;
    const showStopLabel = useSelector(selectShowStopLabel(mapRouteMode));
    const showStopNumber = useSelector(selectShowStopNumber(mapRouteMode));
    const showStopPopup = useSelector(selectShowStopPopup(mapRouteMode));
    const [dispatchedPopup, setShowDispatchedPopup] = useState(null);

    const { t } = useTranslation('translation');
    const isStopCompletedStyle = {
        borderColor: backgroundColor
    };

    useEffect(() => {
        const isStopSelected = selectedMapStops.includes(stopData.id);
        setIsSelected(isStopSelected);
    }, [selectedMapStops]);

    useEffect(() => {
        const { isCanceled, isInProgress } = stopData;
        const isInteractive = !(isCanceled || isCompleted || isInProgress);

        setIsDisabled(
            typeof onDemandDispatchMarkerEventHandler !== 'function' ||
                !isInteractive
        );
    }, [onDemandDispatchMarkerEventHandler]);

    useEffect(() => {
        if (showStopPopup === false) {
            _handleMouseLeave();
        }
    }, [showStopPopup]);

    function _removeDispatchedPopup() {
        setShowDispatchedPopup(null);
    }

    function _handleMouseEnter() {
        emittedEventHandler({
            event: constants.mapChildEvents.STOP_MOUSEENTER,
            payload: {
                location: {
                    lat,
                    lng
                },
                stopMarkerData: stopData
            }
        });
    }

    function _handleMouseLeave() {
        _removeDispatchedPopup();
    }

    function getExceptionIconClass() {
        const boxClassName = 'livestopmarker-icon';

        const conditionalClasses = {
            'icon-exception':
                (isAtRisk || isLate || hasInventoryException) &&
                status === constants.liveStopMarkerStatus.priority,
            'icon-priority':
                !hasInventoryException &&
                status === constants.liveStopMarkerStatus.priority,
            'icon-default':
                !(status === constants.liveStopMarkerStatus.priority) &&
                !(status === constants.liveStopMarkerStatus.canceled)
        };
        return classNames(boxClassName, conditionalClasses);
    }

    function getExceptionIcon() {
        if (hasInventoryException) {
            return (
                <Icon
                    className={getExceptionIconClass()}
                    icon="warningFill3"
                    color="saturn"
                    size="s"
                    stroke="comet"
                />
            );
        }

        if (isAtRisk || isLate) {
            return (
                <Icon
                    className={getExceptionIconClass()}
                    icon="clockFill3"
                    color={isAtRisk ? 'venus' : 'mars'}
                    size="s"
                    stroke="comet"
                />
            );
        }
    }

    function _getDefaultMarkerStyle() {
        if (isCompleted) {
            return isStopCompletedStyle;
        }
        return colorCSS;
    }

    function getExceptionDelayClass() {
        if (isCompleted) {
            return 'livestopmarker_completed';
        }

        return '';
    }

    function getHighPriorityStopBackgroundColor() {
        if (isCompleted) {
            return 'comet';
        }
        return backgroundColor;
    }

    function getMapMarkerLabel() {
        const { isDepot, name } = stopData;
        return (
            showStopLabel &&
            name && (
                <span className="livestopmarker-label">
                    {isDepot ? t('depot') : name}
                </span>
            )
        );
    }

    function _handleOnClick() {
        onDemandDispatchMarkerEventHandler({
            event: constants.mapChildEvents.STOP_MOUSEUP,
            payload: {
                isSelected,
                id: stopData.id,
                selectedMapStops,
                taskId: stopData.id,
                stopLevelData: stopData.toJSON()
            }
        });
    }

    function _getClassName() {
        const defaultClassName = `livestopmarker`;
        const conditionalClasses = {
            livestopmarker_selected: isSelected
        };
        return classNames(defaultClassName, conditionalClasses);
    }

    function getComponent() {
        switch (status) {
            case constants.liveStopMarkerStatus.canceled:
                return (
                    <button type="button" className="livestopmarker">
                        <Icon
                            icon="stopCanceled"
                            className="livestopmarker-canceled"
                            onMouseEnter={_handleMouseEnter}
                            size="l"
                        />
                        {getMapMarkerLabel()}
                    </button>
                );
            case constants.liveStopMarkerStatus.priority:
                return (
                    <button
                        type="button"
                        className="livestopmarker livestopmarker-priority"
                        onMouseEnter={_handleMouseEnter}
                        disabled={isDisabled}
                        onClick={_handleOnClick}
                    >
                        <div className="drivermarker_icon-group _p-relative">
                            <span className="_p-relative">
                                <Icon
                                    color={getHighPriorityStopBackgroundColor()}
                                    icon="stopPriority"
                                    size="xl"
                                    stroke={isCompleted ? backgroundColor : ''}
                                />
                                {showStopNumber && (
                                    <span
                                        className="livestopmarker-priority_text"
                                        style={
                                            isCompleted
                                                ? {
                                                      color: 'var(--color-galaxy-500)'
                                                  }
                                                : { color }
                                        }
                                    >
                                        {sequenceNumber}
                                    </span>
                                )}
                            </span>
                            {getExceptionIcon()}
                        </div>
                        {getMapMarkerLabel()}
                        {showStopPopup && dispatchedPopup}
                    </button>
                );
            default:
                return (
                    <button
                        type="button"
                        className={_getClassName()}
                        onMouseEnter={_handleMouseEnter}
                        disabled={isDisabled}
                        onClick={_handleOnClick}
                    >
                        <div className="livestopmarker_icon-group _p-relative">
                            <span
                                className={`livestopmarker-circle ${getExceptionDelayClass()}`}
                                style={_getDefaultMarkerStyle()}
                            >
                                {showStopNumber && sequenceNumber}
                            </span>
                            {getExceptionIcon()}
                        </div>
                        {getMapMarkerLabel()}
                        {showStopPopup && dispatchedPopup}
                    </button>
                );
        }
    }

    return getComponent();
}

export default LiveStopMarker;
