import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useIsFetching } from 'react-query';

import { removeTasksById, getAllTasks } from '~/reducers/tasksSlice';
import { resetSelectedMapStops } from '~/reducers/selectedMapStopsSlice';
import { updateTaskMetrics } from '~/reducers/taskMetricsSlice';
import { selectDate } from '~/reducers/selectedDateSlice';
import { addToast } from '~/reducers/toastsSlice';

import {
    Alert,
    Spinner,
    ManageStopControlModal,
    ManageStopControlMenu,
    StopModalMenuButton
} from '~/ui';

import { useTaskPairing, useTaskPairingOptimal } from '~/hooks';

function PairTasksControl({
    modalTitle,
    onClickTitle = () => {},
    selectedTaskIds
}) {
    const [isReady, setIsReady] = useState(true);
    const isFetching = Boolean(useIsFetching());
    const selectedDate = useSelector(selectDate);
    const dispatch = useDispatch();
    const { t } = useTranslation(['translation', 'taskManagement', 'error']);
    const { depotPairing, manualPairing, canManualPair } = useTaskPairing();

    function _handleSuccessOptimalPair() {
        dispatch(
            addToast({
                message: t('taskManagement:pairingUnassignedTasks', {
                    total: selectedTaskIds.length
                }),
                variant: 'info'
            })
        );
        dispatch(resetSelectedMapStops());
    }

    function _handleErrorOptimalPair(error) {
        console.error(error);
        setIsReady(false);
        dispatch(
            addToast({
                message: t('error:optimalPairRunningError'),
                variant: 'error'
            })
        );
    }

    const optimalPairingOptions = {
        onSuccess: _handleSuccessOptimalPair,
        onError: _handleErrorOptimalPair
    };

    const {
        isError: optimalPairIsError,
        error: optimalPairError,
        refetch: optimalPairing
    } = useTaskPairingOptimal(selectedTaskIds, optimalPairingOptions);

    useEffect(() => {
        setIsReady(!optimalPairIsError);
    }, [optimalPairIsError]);

    function _handleClickOptimalPair() {
        optimalPairing();
    }

    function _handleClickManualPair() {
        manualPairing({ selectedTaskIds, updateTaskStates });
    }

    function _handleClickDepotPair() {
        depotPairing({ selectedTaskIds, updateTaskStatesForDepotPairing });
    }

    function updateTaskStates() {
        dispatch(removeTasksById(selectedTaskIds));
        dispatch(resetSelectedMapStops());
        dispatch(updateTaskMetrics(selectedDate));
    }

    function updateTaskStatesForDepotPairing() {
        dispatch(getAllTasks({ routeDate: selectedDate }));
        dispatch(resetSelectedMapStops());
    }

    function _getError(errorData) {
        return (
            <Alert
                className="pair-tasks-control_error"
                variant="danger-dark"
                icon="warningFill2"
                iconSize="s"
                iconColor="mars"
            >
                {errorData?.response?.data?.message || errorData.message}
            </Alert>
        );
    }

    return (
        <ManageStopControlModal
            className="pair-tasks-control"
            modalTitle={modalTitle}
            onClickTitle={onClickTitle}
        >
            {isFetching && (
                <div className="pair-tasks-control_loading _d-flex _jc-center _ai-center">
                    <Spinner
                        sx={{ width: '2rem', height: '2rem' }}
                        color="ocean"
                    />
                </div>
            )}
            {!isFetching && isReady && (
                <>
                    <div className="pair-tasks-control_menu-group">
                        <ManageStopControlMenu className="pair-tasks-control_menu">
                            <StopModalMenuButton
                                titleIcon="machineLearning"
                                title={t(
                                    'UnassignedTasksControl.button.optimalPair'
                                )}
                                onClickHandler={_handleClickOptimalPair}
                            />
                        </ManageStopControlMenu>
                        <div className="pair-tasks-control_text _text-3-alt">
                            {t('UnassignedTasksControl.title.pair_tasks_info')}
                        </div>
                    </div>
                    <ManageStopControlMenu className="pair-tasks-control_menu">
                        {canManualPair(selectedTaskIds) && (
                            <StopModalMenuButton
                                titleIcon="link"
                                title={t(
                                    'UnassignedTasksControl.button.manualPair'
                                )}
                                onClickHandler={_handleClickManualPair}
                            />
                        )}
                        <StopModalMenuButton
                            titleIcon="depotPair"
                            title={t('UnassignedTasksControl.button.depotPair')}
                            onClickHandler={_handleClickDepotPair}
                        />
                    </ManageStopControlMenu>
                </>
            )}
            {!isFetching && optimalPairIsError && _getError(optimalPairError)}
        </ManageStopControlModal>
    );
}

export default PairTasksControl;
