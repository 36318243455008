import axios from 'axios';
import constants from '~/utils/constants';
import dateUtils from '~/utils/date-utils';

export const webPlanAPI = {
    path: '/web/planSolution',
    get(clientId, params) {
        if (!clientId) {
            return Promise.reject('clientId not provided');
        }
        if (!params.date || !params.type) {
            return Promise.reject(
                'missing date or type querystring parameters'
            );
        }

        return axios.get(this.path, {
            params,
            headers: {
                [constants.requestHeaders.WISE_CLIENT_ID]: clientId
            }
        });
    },
    getRoutesLevelData(date, clientIds = []) {
        if (!date) {
            return Promise.reject('date not provided');
        }
        const isProperFormat = dateUtils.checkIsYearMonthDayFormat(date);
        if (!isProperFormat) {
            return Promise.reject('date must be in yyyy-mm-dd format');
        }
        const isValidDate = dateUtils.checkIsDateStringValidDate(date);
        if (!isValidDate) {
            return Promise.reject('must provide a valid date');
        }
        if (!Array.isArray(clientIds)) {
            return Promise.reject('clientIds must be an array');
        }
        return axios.get(this.path, {
            params: {
                detail: 'routes',
                date,
                clients: clientIds
            },
            headers: {
                [constants.requestHeaders.WISE_CLIENT_ID]: ''
            }
        });
    },
    getStopsLevelData(date, clientRouteIds = []) {
        if (!date) {
            return Promise.reject('date not provided');
        }
        const isProperFormat = dateUtils.checkIsYearMonthDayFormat(date);
        if (!isProperFormat) {
            return Promise.reject('date must be in yyyy-mm-dd format');
        }
        const isValidDate = dateUtils.checkIsDateStringValidDate(date);
        if (!isValidDate) {
            return Promise.reject('must provide a valid date');
        }
        const isClientRouteIdsValid =
            clientRouteIds &&
            Array.isArray(clientRouteIds) &&
            clientRouteIds.length;
        if (!isClientRouteIdsValid) {
            return Promise.reject('clientRouteIds must be an array');
        }
        return axios.get(this.path, {
            params: {
                detail: 'stops',
                date,
                client_route_ids: clientRouteIds
            },
            headers: {
                [constants.requestHeaders.WISE_CLIENT_ID]: ''
            }
        });
    },
    checkPlanSolutionResponseDataHasValues(responseData) {
        return Array.isArray(responseData) && responseData.length;
    }
};
