import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { SuggestApi, SupportedSuggestMetric } from '~/api/SuggestApi';

import { selectDriversByClient } from '~/reducers/driversByClientSlice';

import { taskDataFactory } from '~/utils/data-factory';
import constants from '~/utils/constants';

import { selectLiveDrivers } from '~/reducers/liveDriversSlice';
import { idUtils } from '~/utils/id-utils';

const bulkSuggest = async (selectedTaskIds, drivers, apiOptions) => {
    const { data: bulkSuggestResponse } = await SuggestApi.bulkSuggest(
        selectedTaskIds,
        apiOptions
    );

    const { suggestedDrivers, tasks } = bulkSuggestResponse?.data;

    const consolidatedSuggestions = suggestedDrivers.reduce(
        (acc, suggestionData) => {
            const { driver: driverId } = suggestionData;

            if (!acc[driverId]) {
                const driverData = drivers.find(
                    (driver) => driver.id === driverId
                );
                acc[driverId] = taskDataFactory.makeOnDemandDispatchSuggestion(
                    driverData,
                    tasks
                );
            }

            acc[driverId].suggestions.push(suggestionData);

            return acc;
        },
        {}
    );

    // the suggest api currently returns drivers of all status types
    // we are manually filtering the data to only include `dispatched` drivers
    const filteredSuggestions = Object.values(consolidatedSuggestions).filter(
        (item) => {
            return item.driver.isDispatchedDriver;
        }
    );

    return filteredSuggestions;
};

export const defaultBulkSuggestOptions = {
    orderBy: SupportedSuggestMetric.TOTAL_ROUTE_TIME,
    includeMetrics: true,
    prioritizeActiveDrivers: true
};

export const useBulkSuggest = (
    selectedTaskIds,
    apiOptions = {},
    reactQueryOptions = {}
) => {
    // get cached drivers from redux
    const liveDrivers = useSelector(selectLiveDrivers);
    const driversByClientId = useSelector(selectDriversByClient);
    const [...drivers] = Object.values(driversByClientId)[0];

    const driversData = drivers.map((driver) => {
        const { client: clientId, id: driverId } = driver;
        const clientDriverId = idUtils.getCombinedId(clientId, driverId);
        const liveDriver = liveDrivers[clientDriverId];
        const isLiveDriver = Boolean(liveDriver);
        const isDispatchedDriver =
            isLiveDriver && !liveDriver.stats.isDriverComplete;
        const isCompletedDriver =
            isLiveDriver && liveDriver.stats.isDriverComplete;
        return {
            ...driver,
            clientDriverId,
            isLiveDriver,
            isDispatchedDriver,
            isCompletedDriver
        };
    });

    // get API response
    return useQuery(
        [constants.reactQueryKeys.TASKS_BULK_SUGGEST, selectedTaskIds],
        () => bulkSuggest(selectedTaskIds, driversData, apiOptions),
        reactQueryOptions
    );
};
