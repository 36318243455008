import React from 'react';
import { Icon } from '~/ui';
import './TaskHistoryStatus.scss';

function TaskHistoryStatus({ title, date, icon, state, hideLine = false }) {
    const properties = computeState();
    const className = '_d-flex _ai-center _jc-center taskhistorystatus-circle';

    function computeState() {
        switch (state) {
            case 'focus':
                return {
                    backgroundColor: 'galaxy-500',
                    iconColor: 'comet',
                    lineColor: 'galaxy-400',
                    icon
                };
            case 'completed':
                return {
                    backgroundColor: 'comet',
                    iconColor: 'galaxy-500',
                    lineColor: 'galaxy-500',
                    icon: 'circle'
                };
            default:
                return {
                    backgroundColor: 'comet',
                    lineColor: 'galaxy-400',
                    icon: 'circleEmpty'
                };
        }
    }
    return (
        <div className="_d-flex _cg-4">
            <div className="_fd-column _ai-center">
                <div
                    className={`${className} taskhistorystatus-circle--${properties.backgroundColor}`}
                >
                    <Icon
                        icon={properties.icon}
                        size="s"
                        color={properties.iconColor}
                    />
                </div>
                {!hideLine && (
                    <div
                        className={`taskhistorystatus-line taskhistorystatus-line--${properties.lineColor}`}
                    />
                )}
            </div>
            <div className="_d-flex _fd-column">
                <div className="heading-3">{title}</div>
                <div className="sub-heading-1">{date}</div>
            </div>
        </div>
    );
}

export default TaskHistoryStatus;
