import React from 'react';
import PT from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Icon } from '~/ui';
import './routecard-metric.scss';

function RouteCardMetric({
    className,
    value,
    maxValue,
    icon,
    iconSize = 'xs',
    iconColor,
    icons = []
}) {
    const { t } = useTranslation('translation');

    function _getClassName() {
        let boxClassName = 'routecard-metric _d-flex _ai-center';
        boxClassName =
            (className && `${boxClassName} ${className}`) || boxClassName;
        return boxClassName;
    }

    function _getIcons() {
        if (icons && icons.length > 0) {
            return icons.map((item, idx) => {
                const {
                    icon: itemIcon,
                    iconSize: itemIconSize,
                    iconColor: itemIconColor
                } = item;
                const key = `${itemIcon}-${itemIconSize}-${itemIconColor}-${idx}`;
                return (
                    <Icon
                        key={key}
                        icon={itemIcon}
                        size={itemIconSize}
                        color={itemIconColor}
                    />
                );
            });
        }

        if (icon) {
            return <Icon icon={icon} size={iconSize} color={iconColor} />;
        }
    }

    return (
        <div className={_getClassName()}>
            {_getIcons()}
            {value && (
                <span className="routecard-metric_value">
                    {maxValue
                        ? t('valueOfMaxValue', { value, maxValue })
                        : value}
                </span>
            )}
        </div>
    );
}

RouteCardMetric.propTypes = {
    /** additional css classes to attach to this component */
    className: PT.string
};

export default RouteCardMetric;
