// TODO: Remove these after the file has been fully converted to typescript
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import axios, { AxiosResponse } from 'axios';

import dateUtils from '~/utils/date-utils';
import constants from '~/utils/constants';
import { BaseConfig } from './types/Base';
import { TaskStatus } from './types';

interface GetParams {
    /**
     * Used for pagination
     * The current page in the pagination, where each page has at most `limit` items
     */
    page?: number;

    /**
     * Used for pagination
     * The maximum number of items to return on a single page
     */
    limit?: number;

    /**
     * The date of the tasks we want to fetch
     */
    date?: string | number | Date;

    /**
     * A key, comma-separated list of keys, or array of keys designating related entities that
     * should be loaded with the task
     *
     * @example
     * // return each `Task` with the related pickupLocations and deliveryLocations
     * extent: 'pickupLocation,deliveryLocation'
     */
    extent?: string | string[];

    status?: TaskStatus[];
}

interface GetConfig extends BaseConfig {
    params?: Omit<GetParams, 'date'> & {
        date?: string;
    };
}

interface GetResponseData {
    data?: {
        canceled?: number;
        completed?: number;
        dispatched?: number;
        planned?: number;
        total?: number;
        unassigned?: number;
    };
    meta?: {
        page?: number;
        limit?: number;
        startAt?: number;
        sortdir?: string;
        total?: number;
    };
}

/**
 * Implementations of API methods under the `/task` or `/tasks` paths
 *
 * @category API
 */
const tasksApi = {
    taskPath: '/task',
    tasksPath: '/tasks',
    get(
        params: GetParams = {
            page: 0,
            limit: 1,
            date: undefined,
            extent: undefined,
            status: undefined
        }
    ): Promise<AxiosResponse<GetResponseData>> {
        const config: GetConfig = {
            params: {
                ...params,
                date: params.date
                    ? dateUtils.convertToISODateOnly(params.date)
                    : undefined
            },
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        };

        return axios.get(this.tasksPath, config);
    },
    getTask(
        id: string,
        params: any = { extent: undefined }
    ): Promise<AxiosResponse<any>> {
        return axios.get(`${this.taskPath}/${id}`, {
            params,
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    },
    getTaskAssignments(id: string): Promise<AxiosResponse<any>> {
        return axios.get(`${this.taskPath}/${id}/assignments`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    },
    getMetrics(params: any = {}): Promise<AxiosResponse<any>> {
        if (params.date) {
            params.date = [dateUtils.convertToISODateOnly(params.date)];
        }

        if (params.start_date) {
            params.start_date = dateUtils.convertToISODateOnly(
                params.start_date
            );
        }

        if (params.end_date) {
            params.end_date = dateUtils.convertToISODateOnly(params.end_date);
        }

        return axios.get(`${this.tasksPath}/metrics`, {
            params,
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    },
    startScheduler({ date, rerun }: any): Promise<AxiosResponse<any>> {
        if (!date) {
            return Promise.reject('missing date');
        }
        date = dateUtils.convertToISODateOnly(date);

        return axios.post(
            `${this.tasksPath}?action=lr_schedule`,
            { date, rerun },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    },
    stopScheduler(): Promise<AxiosResponse<any>> {
        return axios.post(
            `${this.tasksPath}?action=lr_stop`,
            {},
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    },
    joinTasks({ id, joinedTask }: any): Promise<AxiosResponse<any>> {
        return axios.post(
            `${this.taskPath}/${id}?action=join`,
            { joinedTask },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    },
    splitTask(id: string): Promise<AxiosResponse<any>> {
        return axios.post(
            `${this.taskPath}/${id}?action=split`,
            {},
            { headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' } }
        );
    },
    generatePairings({
        taskIds,
        unassignedTaskIds
    }: any): Promise<AxiosResponse<any>> {
        if (!taskIds.length) return Promise.reject('missing task ids');

        return axios.post(
            `${this.tasksPath}/pairings?action=generate`,
            { taskIds, unassignedTaskIds },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    },
    generateDepotPairs({ taskIds }: any): Promise<AxiosResponse<any>> {
        if (!taskIds.length) return Promise.reject('missing task ids');
        return axios.post(
            `${this.tasksPath}/pairings?action=depot_pair`,
            { taskIds },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    },
    update(id: string, payload: any): Promise<AxiosResponse<any>> {
        return axios.patch(
            `${this.taskPath}/${id}`,
            { ...payload },
            {
                headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
            }
        );
    },
    deleteTask(id: string): Promise<AxiosResponse<any>> {
        return axios.delete(`${this.taskPath}/${id}`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    },
    deleteTasks(taskIds: string[]): Promise<AxiosResponse<any>> {
        return axios.delete(`${this.tasksPath}`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' },
            data: { taskIds }
        });
    },

    /**
     * Locks all tasks identified by the provided `taskIds`
     *
     * @param {string[]} taskIds
     * @returns {Promise<AxiosResponse<any>>}
     */
    taskLockTasks(taskIds: string[]): Promise<AxiosResponse<any>> {
        return axios.patch(`${this.tasksPath}?action=lock`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' },
            taskIds
        });
    },

    /**
     * Order locks all tasks identified by the provided `taskIds`
     *
     * @param {string[]} taskIds
     * @returns {Promise<AxiosResponse<any>>}
     */
    orderLockTasks(taskIds: string[]): Promise<AxiosResponse<any>> {
        return axios.patch(`${this.tasksPath}?action=lock_order`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' },
            taskIds
        });
    },

    /**
     * Unlocks all tasks identified by the provided `taskIds`
     *
     * @param {string[]} taskIds
     * @returns {Promise<AxiosResponse<any>>}
     */
    unlockTasks(taskIds: string[]): Promise<AxiosResponse<any>> {
        return axios.patch(`${this.tasksPath}?action=unlock`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' },
            taskIds
        });
    },
    unassignTask(taskId: string[]): Promise<AxiosResponse<any>> {
        return axios.patch(`${this.taskPath}/${taskId}?action=unassign`, {
            headers: { [constants.requestHeaders.WISE_CLIENT_ID]: '' }
        });
    }
};

export default tasksApi;
