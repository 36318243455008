import axios from 'axios';
import _ from 'lodash';
import {
    AxiosApiResponse,
    IApiResponse,
    IPostBulkSuggestResponse,
    SuggestMetric
} from '~/api/types';

/**
 * Supported suggest metrics used for sorting suggestions
 * @category API
 */
export enum SupportedSuggestMetric {
    /**
     * Total route time of candidate driver
     */
    TOTAL_ROUTE_TIME = 'total_route_time',
    /**
     * Total number of delays of candidate driver
     */
    NUMBER_OF_DELAYS = 'number_of_delays',
    /**
     * ETA of candidate driver
     */
    ETA = 'eta',
    /**
     * Change in route time after adding tasks to candidate driver
     */
    INDUCED_ROUTE_TIME = 'induced_route_time',
    /**
     * Change in number of delays after adding tasks to candidate driver
     */
    INDUCED_NUMBER_OF_DELAYS = 'induced_number_of_delays'
}

/**
 * Interface detailing available options for suggest
 * @category API
 */
interface SuggestOptions {
    /**
     * Suggest metric to sort results by
     */
    orderBy: SupportedSuggestMetric;
    /**
     * Indicates whether candidate route metrics should be included in response
     */
    includeMetrics: boolean;
    /**
     * Radius beyond which to exclude drivers
     */
    radius: number;
    /**
     * Indicates whether active drivers should be prioritized
     */
    prioritizeActiveDrivers: boolean;
}

/**
 * Helper type. Result of options validation
 * @category API
 */
type SuggestOptionsValidationResults = {
    /**
     * Indicates that validation was successful
     */
    success: boolean;
    /**
     * List of errors, populated when success = false
     */
    errors: string[];
};

/**
 * Implementation of API methods from the /task/bulk_suggest endpoint.
 *
 * @category API
 *
 * @example
 * import { SuggestApi, SupportedSuggestMetrics } from '~/api/suggest';
 *
 * const taskIds = ["caf6c513-d034-4801-be10-c0c699734c38", "ce9ba08d-5e8a-41bc-8623-2b81995bb82e"];
 *
 * // Suggest response with route metrics of candidate driver
 * const withMetrics = SuggestApi.bulkSuggest(taskIds, {includeMetrics: true});
 *
 * // Suggest response with candidate drivers sorted by induced delays
 * const orderedByDelays = suggestApi.bulkSuggest(taskIds, {
 *  orderBy: SupportedSuggestMetrics.INDUCED_NUMBER_OF_DELAYS
 * });
 */
export class SuggestApi {
    /**
     * the API endpoint path
     */
    private static readonly PATH = '/task/bulk_suggest';

    /**
     * Validates the provided suggest options. All options are optional,
     * so keys are skipped for validation when the value is undefined
     *
     * @param {SuggestOptions} options suggest options to validate
     * @returns {SuggestOptionsValidationResults}
     */
    private static validateSuggestOptions({
        orderBy,
        includeMetrics,
        radius,
        prioritizeActiveDrivers
    }: SuggestOptions): SuggestOptionsValidationResults {
        const errors: string[] = [];

        if (
            orderBy &&
            !Object.values(SupportedSuggestMetric).includes(orderBy)
        ) {
            errors.push('orderBy must be on of the supported suggest metrics');
        }

        if (!_.isNil(includeMetrics) && !_.isBoolean(includeMetrics)) {
            errors.push('includeMetrics must be a boolean');
        }

        if (!_.isNil(radius) && (!_.isInteger(radius) || radius < 0)) {
            errors.push('radius must be a positive integer');
        }

        if (
            !_.isNil(prioritizeActiveDrivers) &&
            !_.isBoolean(prioritizeActiveDrivers)
        ) {
            errors.push('prioritizeActiveDrivers must be a boolean');
        }

        return {
            success: errors.length === 0,
            errors
        };
    }

    /**
     * Performs a POST /task/bulk_suggest call
     * @param {string[]} tasks array of task ids to run suggest on
     * @param {SuggestOptions} [options] suggest call options
     * @returns {Promise<AxiosApiResponse<IPostBulkSuggestResponse>>} API response for suggest
     */
    public static bulkSuggest(
        tasks: string[],
        options?: SuggestOptions
    ): Promise<AxiosApiResponse<IPostBulkSuggestResponse>> {
        if (!tasks || !Array.isArray(tasks) || !tasks.length) {
            return Promise.reject(
                'taskIds cannot be undefined and must be an array of length at least 1'
            );
        }
        const { success, errors } = options
            ? SuggestApi.validateSuggestOptions(options)
            : { success: true, errors: [] };

        if (!success) {
            return Promise.reject(errors.join(', '));
        }
        return axios.post<IApiResponse<IPostBulkSuggestResponse>>(
            SuggestApi.PATH,
            {
                tasks
            },
            {
                params: options
            }
        );
    }

    /**
     * Gets the key under which the orderBy metric is found in the suggest
     * response's explainMetrics.
     * @param {SupportedSuggestMetric} orderBy
     * @returns {(string | null)} the corresponding key in
     * explain metrics or null if metric is not included in explain metrics
     */
    public static getExplainMetricInResponseForOrderBy(
        orderBy: SupportedSuggestMetric
    ): keyof SuggestMetric | null {
        switch (orderBy) {
            case SupportedSuggestMetric.INDUCED_NUMBER_OF_DELAYS:
                return 'inducedNumberOfDelays';
            case SupportedSuggestMetric.INDUCED_ROUTE_TIME:
                return 'inducedRouteTime';
            case SupportedSuggestMetric.NUMBER_OF_DELAYS:
                return 'newNumberOfDelays';
            case SupportedSuggestMetric.TOTAL_ROUTE_TIME:
                return 'newTotalRouteTime';
            default:
                return null;
        }
    }
}
