import React from 'react';
import { Button, Icon } from '~/ui';

import './zoom-control.scss';

function ZoomControl({ map, showControls }) {
    const smallZoomStep = 1;

    function zoomIn() {
        if (map.zoom < map.maxZoom) {
            map.setZoom(map.zoom + smallZoomStep);
        }
    }

    function zoomOut() {
        if (map.zoom > map.minZoom) {
            map.setZoom(map.zoom - smallZoomStep);
        }
    }

    return (
        <div className="zoom-column" hidden={!showControls}>
            <div className="zoom-column-btn-wrapper">
                <Button
                    type="naked"
                    className="zoom-column-in"
                    onClick={zoomIn}
                >
                    <Icon icon="addLight" size="m" color="galaxy-800" />
                </Button>
            </div>
            <div className="zoom-column-divider" />
            <div className="zoom-column-btn-wrapper">
                <Button
                    type="naked"
                    className="zoom-column-out"
                    onClick={zoomOut}
                >
                    <Icon icon="minusLight" size="m" color="galaxy-800" />
                </Button>
            </div>
        </div>
    );
}

export default ZoomControl;
