import PT from 'prop-types';
import React from 'react';
import InlineSvg from 'react-inlinesvg';
import classNames from 'classnames';

import { FlexLayout } from '~/ui';
import * as icons from '~/ui/assets/icons';

import './icon.scss';

const Icon = React.forwardRef(function Icon(
    {
        color,
        fillOpacity = 1,
        stroke,
        disabled,
        icon,
        size = 'm',
        className = '',
        sx,
        onClick,
        ...extra
    },
    ref
) {
    const iconSrc = icons[icon];
    if (!iconSrc) {
        return null;
    }

    function _getClassName() {
        const defaultClassName = `icon icon_${icon} icon_size-${size} _jc-center _ai-center`;
        const conditionalClasses = {
            icon_disabled: disabled
        };
        return classNames(defaultClassName, conditionalClasses, className);
    }

    return (
        <FlexLayout
            className={_getClassName()}
            disabled={disabled}
            ref={ref}
            sx={{
                ...sx,
                svg: {
                    fill: color,
                    fillOpacity,
                    stroke
                }
            }}
            onClick={onClick}
            {...extra}
        >
            <InlineSvg src={iconSrc} />
        </FlexLayout>
    );
});

export const iconKeyPropTypes = PT.oneOf(Object.keys(icons));

Icon.propTypes = {
    color: PT.string,
    stroke: PT.string,
    disabled: PT.bool,
    icon: iconKeyPropTypes.isRequired,
    size: PT.oneOf(['xxxs', 'xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl']),
    className: PT.string,
    sx: PT.oneOfType([PT.object]),
    onClick: PT.func
};

export default Icon;
