import { DateTime } from 'luxon';
import dateUtilsConverters from './date-utils-converters';
import dateUtilsLocalized from './date-utils-localized';

/**
 * Date Utilities
 *
 * @category Utils
 * @module utils/dateUtils
 *
 * @example
 * import dateUtils from '~/utils/date-utils';
 *
 * @requires module:utils/dateUtilsConverters
 * @borrows module:utils/dateUtilsConverters~convertISODateToJsDate as convertISODateToJsDate
 * @borrows module:utils/dateUtilsConverters~convertISODateToNumericDate as convertISODateToNumericDate
 * @borrows module:utils/dateUtilsConverters~convertJsDateToISODate as convertJsDateToISODate
 * @borrows module:utils/dateUtilsConverters~convertToISODateOnly as convertToISODateOnly
 * @borrows module:utils/dateUtilsConverters~convert24HourDateTimeToISO as convert24HourDateTimeToISO
 * @borrows module:utils/dateUtilsConverters~get24HourTime as get24HourTime
 * @borrows module:utils/dateUtilsConverters~convertMillisecondsToHoursAndMinutes as convertMillisecondsToHoursAndMinutes
 *
 * @requires module:utils/dateUtilsLocalized
 * @borrows module:utils/dateUtilsLocalized~formatMonthYear as formatMonthYear
 * @borrows module:utils/dateUtilsLocalized~formatMonthDay as formatMonthDay
 * @borrows module:utils/dateUtilsLocalized~formatLetterDayOfWeek as formatLetterDayOfWeek
 * @borrows module:utils/dateUtilsLocalized~formatAbbreviatedDate as formatAbbreviatedDate
 * @borrows module:utils/dateUtilsLocalized~getIntWeekDay as getIntWeekDay
 * @borrows module:utils/dateUtilsLocalized~getDurationString as getDurationString
 * @borrows module:utils/dateUtilsLocalized~addDurationToTime as addDurationToTime
 * @borrows module:utils/dateUtilsLocalized~getShiftTime as getShiftTime
 * @borrows module:utils/dateUtilsLocalized~getLocalizedTime as getLocalizedTime
 * @borrows module:utils/dateUtilsLocalized~getTimeWindow as getTimeWindow
 */

/**
 * Check the provided string is in ISO date format, YYYY-MM-DD
 *
 * @param {String} inputString the string to test the date format
 * @returns {Boolean} the result of the test
 */
function checkIsYearMonthDayFormat(inputString: string): boolean {
    return /^[0-9]{4}(-[0-9]{2}){2}$/.test(inputString);
}

/**
 * Check the provided string will parse into a JS Date
 *
 * @param {String} inputString the string to test the date format
 * @returns {Boolean} the result of the test
 *
 * @see [JS Date Object]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date}
 */
function checkIsDateStringValidDate(inputString: string): boolean {
    const date = new Date(inputString);
    return !Number.isNaN(date.valueOf());
}

/**
 * Check two DateTimes are the same day
 *
 * @param {DateTime} firstDate the first date
 * @param {DateTime} secondDate the second date to compare against
 * @returns {Boolean}
 */
function isSameDay(firstDate: DateTime, secondDate: DateTime): boolean {
    return firstDate.hasSame(secondDate, 'day');
}

/**
 * Checks if the provided date is today.
 *
 * @param {String} date should be in ISO 8601 format
 * @returns {Boolean} the result of the test
 * @see [isSameDay]{@link module:utils/dateUtils~isSameDay}
 */
function isToday(date: string): boolean {
    return isSameDay(DateTime.fromJSDate(new Date()), DateTime.fromISO(date));
}

/**
 * Returns the dates of the first and last date of the month containing the provided date
 *
 * @param date - The date whose month should be used for returning the start and end dates
 * @returns - The start and end date of the month of the provided date
 */
function getMonthRange(date: Date | DateTime): [DateTime, DateTime] {
    const datetime = (() => {
        if (DateTime.isDateTime(date)) {
            return date;
        }
        return DateTime.fromJSDate(date);
    })();

    return [datetime.startOf('month'), datetime.endOf('month')];
}

/**
 * Returns the dates of the first and last date of the week containing the provided date
 *
 * @param date - The date whose week should be used for returning the start and end dates
 * @returns - The start and end date of the month of the provided date
 */
function getWeekRange(date: Date | DateTime): [DateTime, DateTime] {
    const datetime = (() => {
        if (DateTime.isDateTime(date)) {
            return date;
        }
        return DateTime.fromJSDate(date);
    })().plus({ days: 1 });

    return [
        datetime.startOf('week').minus({ days: 1 }),
        datetime.endOf('week').minus({ days: 1 })
    ];
}

export default {
    ...dateUtilsConverters,
    ...dateUtilsLocalized,
    checkIsYearMonthDayFormat,
    checkIsDateStringValidDate,
    isSameDay,
    isToday,
    getMonthRange,
    getWeekRange
};
