import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';

export const routePlansByDateSlice = createSlice({
    name: 'routePlansByDate',
    initialState: null,
    reducers: {
        setRoutePlansByDate: (state, action) => {
            state = action.payload;
            return state;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return null;
        });
    }
});

export const { setRoutePlansByDate } = routePlansByDateSlice.actions;

export const selectRoutePlansByDate = (state) => state.routePlansByDate;

export default routePlansByDateSlice.reducer;
