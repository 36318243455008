import PT from 'prop-types';
import React, { useState } from 'react';

import { Box, FlexLayout, Icon, Text } from '~/ui';

const variantStyles = {
    primary: {
        border: (theme) => `solid 0.1rem ${theme.colors['galaxy-200']}`,
        borderRadius: '0.8rem',
        color: 'galaxy-800',
        backgroundColor: 'comet',
        textVariant: '14-normal',
        dropdown: {
            borderRadius: '0.4rem',
            boxShadow: '0 0.2rem 0.6rem 0 var(--color-box-shadow)'
        }
    }
};

const SingleSelect = React.forwardRef(function SingleSelect(
    {
        list,
        selected = null,
        prefix,
        variant = 'primary',
        onItemChange,
        sx,
        ...extra
    },
    ref
) {
    const [isListOpen, setIsListOpen] = useState(false);
    const [selectedItemKey, setSelectedItemKey] = useState(selected);

    if (!list || list.length === 0) {
        return null;
    }

    const selectedStyle = variantStyles[variant];

    function toggleList() {
        setIsListOpen(!isListOpen);
    }

    function currentTitle() {
        const item = list.find((i) => i.key === selectedItemKey);
        const text = item ? item.value : '';
        return `${prefix || ''}${text}`;
    }

    function changeSelectedItem(key) {
        setSelectedItemKey(key);
        toggleList();
        return onItemChange && onItemChange(key);
    }

    return (
        <Box ref={ref} sx={{ position: 'relative', ...sx }} {...extra}>
            <FlexLayout
                alignItems="center"
                padding="0.6rem 1.2rem"
                justifyContent="space-between"
                sx={{
                    border: selectedStyle.border,
                    borderRadius: selectedStyle.borderRadius,
                    backgroundColor: selectedStyle.backgroundColor
                }}
                onClick={toggleList}
            >
                <Text
                    disabled
                    color={selectedStyle.color}
                    variant={selectedStyle.textVariant}
                    paddingRight="1.5rem"
                >
                    {currentTitle()}
                </Text>
                <Icon icon="chevronDown" />
            </FlexLayout>
            {isListOpen && (
                <FlexLayout
                    flexDirection="column"
                    sx={{
                        backgroundColor: 'white',
                        borderRadius: selectedStyle.dropdown.borderRadius,
                        boxShadow: selectedStyle.dropdown.boxShadow,
                        py: '0.8rem',
                        position: 'absolute',
                        top: '4.4rem',
                        width: `calc(100% - 1.5rem)`,
                        zIndex: (theme) => theme.zIndex.mapControls
                    }}
                >
                    {list.map((item) => (
                        <FlexLayout
                            justifyContent="space-between"
                            key={item.key}
                            padding="1rem 1.0rem 1rem 1.6rem"
                            onClick={() => changeSelectedItem(item.key)}
                        >
                            <Text
                                color={selectedStyle.color}
                                variant={selectedStyle.textVariant}
                            >
                                {item.value}
                            </Text>
                            {item.key === selectedItemKey && (
                                <Icon icon="checkmark" />
                            )}
                        </FlexLayout>
                    ))}
                </FlexLayout>
            )}
        </Box>
    );
});

SingleSelect.propTypes = {
    /** List of select items in { key, value} format */
    list: PT.arrayOf(PT.shape({ key: PT.string, value: PT.string })),
    /** String value of selected key */
    selected: PT.string,
    /** Select title prefix */
    prefix: PT.string,
    /** Select variant style */
    variant: PT.oneOf(['primary']),
    /** Callback triggered after selecting item */
    onItemChange: PT.func
};

export default SingleSelect;
