import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, TooltipButton, useModal } from '~/ui';
import PhotoModalContent from '~/components/TaskManagementPage/TaskDetailDrawer/ProofOfDelivery/PhotoModalContent';
import SignatureModalContent from '~/components/TaskManagementPage/TaskDetailDrawer/ProofOfDelivery/SignatureModalContent';
import ZoomPhotoModalContent from '~/components/TaskManagementPage/TaskDetailDrawer/ProofOfDelivery/ZoomPhotoModalContent';

import './ProofOfDelivery.scss';

function ProofOfDelivery({ inventoryItems }) {
    const { t } = useTranslation('taskManagement');
    const [isSigned, setSigned] = useState(false);
    const [photoCount, setPhotoCount] = useState(0);
    const [selectedItemName, setSelectedItemName] = useState('');
    const [selectedPhotoUrl, setSelectedPhotoUrl] = useState('');

    const { showModal: showSignatureModal, modal: SignatureModal } = useModal();
    const {
        showModal: showPhotoModal,
        hideModal: hidePhotoModal,
        modal: PhotoModal
    } = useModal();
    const {
        showModal: showZoomPhotoModal,
        hideModal: hideZoomPhotoModal,
        modal: ZoomPhotoModal
    } = useModal();

    useEffect(() => {
        function getPhotoUrlCount(items) {
            return items.reduce(
                (total, item) => total + item.photo_urls.length,
                0
            );
        }

        if (!inventoryItems.length) {
            return;
        }
        setSigned(Boolean(inventoryItems[0].signature_url));
        setPhotoCount(getPhotoUrlCount(inventoryItems));
    }, [inventoryItems]);

    function onPhotoClick(name, photoUrl) {
        setSelectedItemName(name);
        setSelectedPhotoUrl(photoUrl);
        hidePhotoModal();
        showZoomPhotoModal();
    }

    function onZoomPhotoModalHide() {
        setSelectedItemName('');
        setSelectedPhotoUrl('');
        hideZoomPhotoModal();
        showPhotoModal();
    }

    function ProofItem({ title, hasProof, icon, count, onClick }) {
        const classNames = `proofofdelivery-item _fd-column _w-100 _text-2 ${
            hasProof && 'proofofdelivery-item_received'
        }`;
        return (
            <TooltipButton
                className={classNames}
                onClick={onClick}
                disabled={!hasProof}
            >
                <span>{title}</span>
                {hasProof ? (
                    <div className="_d-flex _ai-center _mt-2">
                        <Icon icon={icon} color="meteor" />
                        <span className="_ml-4 _text-2-alt">{count}</span>
                    </div>
                ) : (
                    <span className="_text-3-alt _mt-2 proofofdelivery_notreceived">
                        {t('proof.notReceived')}
                    </span>
                )}
            </TooltipButton>
        );
    }

    return (
        <div className="_d-flex _jc-space-between _cg-2 _w-100">
            <ProofItem
                title={t('proof.signature')}
                hasProof={isSigned}
                icon="signature"
                count={1}
                onClick={showSignatureModal}
            />
            {inventoryItems.length > 0 && (
                <ProofItem
                    title={t('proof.image', { count: photoCount })}
                    hasProof={photoCount > 0}
                    icon="image"
                    count={photoCount}
                    onClick={showPhotoModal}
                />
            )}
            <SignatureModal title={t('proof.signature')}>
                <SignatureModalContent
                    signatureUrl={inventoryItems[0]?.signature_url}
                />
            </SignatureModal>
            <PhotoModal title={t('proof.imageCount', { count: photoCount })}>
                <PhotoModalContent
                    inventoryItems={inventoryItems}
                    onPhotoClick={onPhotoClick}
                />
            </PhotoModal>
            <ZoomPhotoModal variant="large" hasAutoHide={false} hasCustomHeader>
                <ZoomPhotoModalContent
                    name={selectedItemName}
                    photoUrl={selectedPhotoUrl}
                    onHide={onZoomPhotoModalHide}
                />
            </ZoomPhotoModal>
        </div>
    );
}

export default ProofOfDelivery;
