import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, TextInput, TooltipButton } from '~/ui';
import { useEscKey } from '~/hooks';

import './TableToolbar.scss';

function TableToolbar({
    searchText,
    setSearchText,
    setSearchTextDebounced,
    searchBarPlaceholder,
    stickyTopOffset,
    disableStickyHeader,
    customComponent,
    filter
}) {
    const { t } = useTranslation(['driverBooking', 'common']);
    const searchInputRef = useRef(null);
    const [searchFocused, setSearchFocused] = useState(false);
    const [searchMode, setSearchMode] = useState(false);

    useEscKey(() => setSearchMode(false));

    useEffect(() => {
        if (searchMode) {
            searchInputRef.current.focus();
        } else {
            setSearchText('');
        }
    }, [searchMode]);

    function getTableToolbarStyle(offset) {
        if (typeof offset !== 'undefined') {
            return { top: `${offset}rem` };
        }
        return { top: 'var(--dimension-bar-offset-height)' };
    }

    const renderSearchBar = () => (
        <div
            className={`_d-flex _ai-center _w-100 tabletoolbar-searchbar ${
                searchFocused ? 'tabletoolbar-searchbar_focused' : ''
            }`}
        >
            <TextInput
                className="tabletoolbar-searchinput"
                ref={searchInputRef}
                width="fullWidth"
                placeholder={searchBarPlaceholder || ''}
                variant="search"
                value={searchText}
                onChange={(value) => setSearchTextDebounced(value)}
                onFocus={() => setSearchFocused(true)}
                onBlur={() => setSearchFocused(false)}
            />
            {searchText && (
                <TooltipButton
                    className="_d-flex _jc-center _ai-center tabletoolbar-button_clear"
                    onClick={() => setSearchText('')}
                >
                    {t('clearLabel')}
                </TooltipButton>
            )}
            {filter && (
                <div className="tabletoolbar-searchbar-filter">{filter}</div>
            )}
            <TooltipButton
                className="_d-flex _jc-center _ai-center tabletoolbar-button_close"
                onClick={() => setSearchMode(false)}
            >
                <Icon icon="cancel" color="comet" />
            </TooltipButton>
        </div>
    );

    return (
        <div
            className={`_d-flex _ai-center tabletoolbar ${
                disableStickyHeader ? '' : 'tabletoolbar_sticky'
            }`}
            style={getTableToolbarStyle(stickyTopOffset)}
        >
            {searchMode ? (
                renderSearchBar()
            ) : (
                <div className="_d-flex _ai-center _jc-space-between _w-100">
                    <div className="_d-flex _ai-center">
                        <TooltipButton
                            className="_d-flex _ai-center _jc-center _mr-1 tabletoolbar-button_search"
                            onClick={() => setSearchMode(true)}
                        >
                            <Icon icon="search" color="galaxy-500" size="s" />
                            <span className="_text-1 _ml-4">
                                {t('common:search')}
                            </span>
                        </TooltipButton>
                        {filter && (
                            <div className="tabletoolbar-filter">{filter}</div>
                        )}
                    </div>
                    {customComponent}
                </div>
            )}
        </div>
    );
}

export default TableToolbar;
