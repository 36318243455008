import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { FlexLayout, Text, Icon } from '~/ui';
import theme from '~/ui/theme';

function MapSettingMenuItem({
    iconName,
    iconColor,
    text,
    checkedState,
    onChangeState,
    disabled,
    children
}) {
    const [checked, setChecked] = useState(checkedState);
    let color = 'galaxy-800';

    useEffect(() => {
        setChecked(checkedState);
    }, [checkedState]);

    function onChange(check) {
        setChecked(check);
        return onChangeState && onChangeState(check);
    }

    if (disabled) {
        color = 'galaxy-500';
    }

    return (
        <FlexLayout
            className="map-setting-menu-item"
            alignItems="center"
            key={text}
            padding="0 1.6rem"
            justifyContent="space-between"
            sx={{
                width: '24rem',
                '&:hover': {
                    backgroundColor: 'galaxy-50'
                }
            }}
        >
            <FlexLayout alignItems="center">
                <Icon icon={iconName} margin="1rem 0" color={iconColor} />
                {children}
                {!children && (
                    <Text color={color} variant="14-normal" marginLeft="1.1rem">
                        {text}
                    </Text>
                )}
            </FlexLayout>
            <Switch
                checked={checked}
                disabled={disabled}
                onChange={onChange}
                uncheckedIcon={false}
                checkedIcon={false}
                handleDiameter={20}
                offColor={theme.colors['galaxy-200']}
                onColor={theme.colors.ocean}
                height={24}
                width={40}
            />
        </FlexLayout>
    );
}

export default MapSettingMenuItem;
