export default class PlanClient {
    constructor(clientLevelData) {
        this._clientLevelData = clientLevelData;
    }

    get clientId() {
        return this._clientLevelData.clientId;
    }

    get numRoutes() {
        return this._clientLevelData.stats.numRoutes;
    }

    get numTasks() {
        return this._clientLevelData.stats.numTasks;
    }

    get numUnplannedTasks() {
        return this._clientLevelData.stats.numUnplannedTasks;
    }

    set numUnplannedTasks(numUnplannedTasks) {
        this._clientLevelData.stats.numUnplannedTasks = numUnplannedTasks;
    }

    get numUsedDrivers() {
        return this._clientLevelData.stats.numUsedDrivers;
    }

    get numTotalDrivers() {
        return this._clientLevelData.stats.numTotalDrivers;
    }

    get numUnits() {
        return this._clientLevelData.stats.numUnits;
    }

    get duration() {
        return this._clientLevelData.stats.duration;
    }

    toJSON() {
        return this._clientLevelData;
    }
}
