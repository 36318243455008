import { store } from '~/store';
import { editRouteHistoryAPI } from '~/api/edit-route-history';
import { setLatestEditHistoryForClient } from '~/reducers/latestEditHistoryByClientSlice';

async function addMetricsToStore(clientId, schedulerTaskId) {
    if (!clientId) {
        return Promise.reject('missing clientId');
    }
    if (!schedulerTaskId) {
        return Promise.reject('missing schedulerTaskId');
    }
    try {
        const latestEditHistory =
            await editRouteHistoryAPI.getLatestEditHistory(
                clientId,
                schedulerTaskId
            );
        store.dispatch(
            setLatestEditHistoryForClient({
                clientId,
                data: latestEditHistory.data.data[0].supplementaryData
            })
        );
    } catch (e) {
        console.warn(e);
    }
}

export const planEditMetricsService = {
    addMetricsToStore
};
