import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'theme-ui';
import { PersistGate } from 'redux-persist/integration/react';
// base
import 'normalize.css';
import '~/ui/assets/stylesheets/base/custom-defaults.scss';
import '~/ui/assets/stylesheets/base/color.scss';
import '~/ui/assets/stylesheets/base/sizes.scss';
import '~/ui/assets/stylesheets/base/dimensions.scss';
import '~/ui/assets/stylesheets/base/z-index.scss';
// components
import '~/ui/assets/stylesheets/components/units.scss';
import '~/ui/assets/stylesheets/components/tray.scss';
import '~/ui/assets/stylesheets/components/field.scss';
import '~/ui/assets/stylesheets/components/tabs.scss';
import '~/ui/assets/stylesheets/components/modal.scss';
import '~/ui/assets/stylesheets/components/label.scss';
import '~/ui/assets/stylesheets/components/heading.scss';
import '~/ui/assets/stylesheets/components/sub-heading.scss';
// controls
import '~/ui/assets/stylesheets/controls/button.scss';
import '~/ui/assets/stylesheets/controls/checkbox.scss';
import '~/ui/assets/stylesheets/controls/search.scss';
import '~/ui/assets/stylesheets/controls/radio-list-item.scss';
import '~/ui/assets/stylesheets/controls/toggle.scss';
import '~/ui/assets/stylesheets/controls/icon-wrapper.scss';
// skins
import '~/ui/assets/stylesheets/skins/skins.scss';
// utilities
import '~/ui/assets/stylesheets/utilities/typography.scss';
import '~/ui/assets/stylesheets/utilities/width.scss';
import '~/ui/assets/stylesheets/utilities/position.scss';
import '~/ui/assets/stylesheets/utilities/transform.scss';
import '~/ui/assets/stylesheets/utilities/justify-self.scss';
import '~/ui/assets/stylesheets/utilities/margin.scss';
import '~/ui/assets/stylesheets/utilities/font-size.scss';
import '~/ui/assets/stylesheets/utilities/pointer-events.scss';
import '~/ui/assets/stylesheets/utilities/display.scss';
import '~/ui/assets/stylesheets/utilities/align-items.scss';
import '~/ui/assets/stylesheets/utilities/text-transform.scss';
import '~/ui/assets/stylesheets/utilities/text-overflow.css';
import '~/ui/assets/stylesheets/utilities/white-space.scss';
import '~/ui/assets/stylesheets/utilities/flex-direction.scss';
import '~/ui/assets/stylesheets/utilities/justify-content.scss';
import '~/ui/assets/stylesheets/utilities/flex-wrap.scss';
import '~/ui/assets/stylesheets/utilities/column-gap.scss';

import '~/i18n';

import { store, persistor } from '~/store';
import App from '~/components/App';
import { theme } from '~/ui';
import { ToastContextProvider } from '~/contexts/ToastContext';
import generalUtils from '~/utils/general-utils';
import constants from '~/utils/constants';

// initialize `react-query` client with updated default options
const queryClient = new QueryClient(constants.reactQueryDefaultClientOptions);

// output current react version for dev and testing environments
if (generalUtils.isFlaggedFeatureDisplayed()) {
    console.info(`React.version: ${React.version}`);
}

ReactDOM.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <PersistGate loading={null} persistor={persistor}>
                <ThemeProvider theme={theme}>
                    <ToastContextProvider>
                        {/* TODO: add a fallback component to replace null */}
                        <Suspense fallback={null}>
                            <App />
                        </Suspense>
                    </ToastContextProvider>
                </ThemeProvider>
            </PersistGate>
        </QueryClientProvider>
    </Provider>,
    document.getElementById('root')
);
