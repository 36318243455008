import { createSlice } from '@reduxjs/toolkit';
import { resetToInitialState } from '~/reducers/common-actions';

export const usersByClientSlice = createSlice({
    name: 'usersByClient',
    initialState: {},
    reducers: {
        setUsersByClient: (state, action) => {
            state = action.payload;
            return state;
        },
        setUsersByClientFromApiData: (state, action) => {
            return action.payload.reduce((result, user) => {
                user.access.forEach(({ client: clientId }) => {
                    if (!result[clientId]) {
                        result[clientId] = [];
                    }
                    result[clientId].push(user);
                });
                return result;
            }, {});
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetToInitialState, () => {
            return {};
        });
    }
});

export const { setUsersByClient, setUsersByClientFromApiData } =
    usersByClientSlice.actions;

export const selectUsersByClient = (state) => state.usersByClient;
export const selectSaleUserByClient = (state) => {
    const clientIds = Object.keys(state.usersByClient);
    const result = {};
    // We take the first user for a client that has a role of 'sales'.
    // In case none exist, `undefined` is stored.
    clientIds.forEach((id) => {
        result[id] = state.usersByClient[id].find((user) =>
            user.access.some(
                ({ client, roles }) => client === id && roles.includes('sales')
            )
        );
    });
    return result;
};

export default usersByClientSlice.reducer;

/*
type UserRole = 'user' | 'administrator' | 'sales';

interface UsersByClientSlice {
    [clientId: string]: {
        access: {
            client: string;
            roles: UserRole[];
        }[];
        client: string;
        email: string;
        firstname: string;
        lastname: string;
        id: string;
        roles: UserRole[];
        username: string; // actually is a phone number
    }[]
}
 */
