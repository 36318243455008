import PT from 'prop-types';
import React from 'react';
import { Text as ThemeText } from 'theme-ui';

import { theme, styles } from '~/ui';

const Text = React.forwardRef(function Text(
    {
        as = 'span',
        children,
        color,
        maxWidth = '',
        sx,
        textTransform = 'none',
        variant, // set expected default text variant
        ...extra
    },
    ref
) {
    const clickableStyle =
        extra.disabled || extra.onClick || extra.onMouseEnter
            ? styles.interactions.clickable
            : {};
    const ellipsisOverflowStyle = maxWidth && {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth
    };

    return (
        <ThemeText
            as={as}
            color={color}
            ref={ref}
            sx={{
                ...clickableStyle,
                ...ellipsisOverflowStyle,
                textTransform,
                ...sx
            }}
            variant={variant}
            {...extra}
        >
            {children}
        </ThemeText>
    );
});

Text.propTypes = {
    /** Define element, default is span */
    as: PT.instanceOf(PT.any),
    /** Any react node */
    children: PT.node,
    /** Set color of text */
    color: PT.string,
    /** Set max width of text element, trimmed with ellipsis */
    maxWidth: PT.string,
    /** Supports sx props */
    sx: PT.oneOfType([PT.object]),
    /** Set the text-transform property */
    textTransform: PT.oneOf(['uppercase', 'capitalize', 'lowercase', 'none']),
    /** Text style variant (font size and weight) */
    variant: PT.oneOf(Object.keys(theme.text))
};

export default Text;
