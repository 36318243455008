import React from 'react';

import { FlexLayout, Icon, Text } from '~/ui';

import './stop-modal-menu-button.scss';

const defaultTitleColor = 'meteor';

function StopModalMenuButton({
    titleIcon,
    title,
    stopText,
    shouldRender,
    onClickHandler,
    disabled = false
}) {
    return (
        <FlexLayout
            className="stopmodalmenubutton _ai-center"
            disabled={disabled}
            onClick={onClickHandler}
            justifyContent="space-between"
        >
            <FlexLayout alignItems="center" justifyContent="center">
                <Icon icon={titleIcon} size="s" color={defaultTitleColor} />
                <Text
                    color={defaultTitleColor}
                    variant="12-normal"
                    marginLeft="1.3rem"
                    marginTop="0.1rem "
                >
                    {title}
                </Text>
            </FlexLayout>
            <span className="stop-index_label _d-inline-flex _ai-center _text-3">
                {stopText}
                {shouldRender && (
                    <Icon
                        icon="chevronDown"
                        size="s"
                        color="galaxy-500"
                        sx={{ transform: 'rotate(-90deg)' }}
                    />
                )}
            </span>
        </FlexLayout>
    );
}

export default StopModalMenuButton;
